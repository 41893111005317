@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.rooms {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  padding: 48px 24px 38px;

  background-color: $black;

  @include tablet {
    padding: 48px 24px 300px;
  }

  @include desktop {
    padding: 74px 354px 500px 157px;
  }

  &__title {
    margin-bottom: 22px;

    font-size: 0.75rem !important;
    line-height: 2.1875rem;
    font-family: $font-gt-walsheimregular;

    color: $white;

    @include desktop {
      margin-bottom: 44px;

      font-size: 1.5rem !important;
      line-height: 4.375rem;
    }
  }

  &__link {
    position: relative;

    margin-bottom: 24px;

    font-size: 2.5rem;
    line-height: 1;
    font-family: $font-gt-walsheimregular;
    text-decoration: none;

    color: $white;

    @include tablet {
      font-size: 3.59375rem;
    }

    @include desktop {
      margin-bottom: 60px;

      font-size: 4.6875rem;
    }

    &:hover {
      color: $accent-light;
      z-index: 1;
    }

    // &:hover ~ .rooms__images-container .rooms__image {
    //   opacity: 1;
    // }

    &--1 {
      &:hover ~ .rooms__images-container .rooms__image--1 {
        opacity: 1;
      }
    }
    &--2 {
      &:hover ~ .rooms__images-container .rooms__image--2 {
        opacity: 1;
      }
    }
    &--3 {
      &:hover ~ .rooms__images-container .rooms__image--3 {
        opacity: 1;
      }
    }
    &--4 {
      &:hover ~ .rooms__images-container .rooms__image--4 {
        opacity: 1;
      }
    }
    &--5 {
      &:hover ~ .rooms__images-container .rooms__image--5 {
        opacity: 1;
      }
    }
    &--6 {
      &:hover ~ .rooms__images-container .rooms__image--6 {
        opacity: 1;
      }
    }
  }

  &__images-container {
    position: relative;

    width: 272px;
    min-height: 272px;

    @include tablet {
      position: absolute;
      top: -150px;

      min-height: 350px;
      max-height: 350px;

      width: 350px;
    }

    @include desktop {
      position: absolute;
      top: -210px;

      min-height: 500px;
      max-height: 500px;

      width: 500px;
    }
  }

  &__image {
    position: absolute;

    width: 100%;
    height: 100%;

    object-fit: cover;
    opacity: 0;
  }
}
