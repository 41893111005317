@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.annex-got-questions {
  display: flex;
  flex-direction: column;
  position: relative;
  // padding: 0 24px;
  margin-bottom: 80px;
  border-top: 1px solid $black;

  &__questions-container {
    display: flex;
    justify-content: center;

    width: 100%;

    @include tablet {
      justify-content: flex-start;
    }
  }

  &__questions-button {
    align-items: center;
  }

  &__questions-title {
    margin-top: 18.5px;
    margin-bottom: 30px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    letter-spacing: 0.07px;
    line-height: 20px;
    align-items: center;
  }

  &--experience {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 24px;
    margin-bottom: 80px;
    // border-top: 1px solid $black;
    // border-top: none;
    // border-bottom: none;

    @include tablet {
      flex-direction: row;
      align-items: baseline;

      padding-top: 18.5px;
    }

    @include desktop {
      padding: 0 36px;
      margin-bottom: 32px;
    }
  }

  @include tablet {
    flex-direction: row;
    align-items: baseline;

    padding-top: 18.5px;
    margin-left: 53px;
    margin-right: 53px;

    // .rollover-button {
    //   width: 266px;
    // }
  }

  @include desktop {
    padding-top: 0;
    margin-left: 81px;
    margin-right: 83px;
    margin-bottom: 0;
    border-top: none;
    border-bottom: 1px solid $black;

    &__questions-title {
      margin-top: 0;
      margin-bottom: 40px;
      font-size: 30px;
      letter-spacing: 0.15px;
      line-height: 45px;
    }
  }
}

.rollover-button {
  width: 100%;

  @include tablet {
    width: 266px;
  }
}
