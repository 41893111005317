@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.annex-form-group-container {
  &--neighbourhood {
    padding: 0 24px;
    margin-top: 120px;
    max-width: 671px;
    display: block !important;
    // margin-top: 260px;
    // height: 74px;

    @include tablet {
      margin-top: 190px;
    }

    @include desktop {
      margin-top: 260px;
      margin-left: 48px;
      display: block !important;
      width: 50%;
      max-width: 671px;
    }
  }
}


.neighbourhood-container {
  padding: 0 0 32px;
  margin: auto;

  &__gotQuestion {
    // display: none;
    padding: 0 24px;
  }

  @include desktop {
    &__gotQuestion {
      display: inherit;
      padding: 0;
    }
  }
}
