@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.room-amenities-modal-container {
  position: fixed;
  z-index: 21;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;

  width: 100%;
  // height: 100%;
  padding: 75px 24px;

  background-color: rgba(255, 255, 255, 0.88);

  @include desktop {
    padding: 93px 36px;
  }
}
.room-amenities-modal-close {
  position: absolute;
  top: 27.38px;
  right: 24px;
  cursor: pointer;
}
.room-amenities-modal-inner-container {
  position: relative;
  display: flex;
  justify-content: center;
  // height: 650px;
  // height: 100vh;
  width: 100%;

  background-image: url("../../assets/images/rooms-page/room-amenities-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include tablet {
    display: flex;
    justify-content: center;
  }

  @include desktop {
    display: flex;
    justify-content: center;

    // height: 843px;
  }
}
.room-amenities-annex-icon {
  position: fixed;
  // top: 170px;
  top: 245px;

  width: 290px;

  color: rgba(255, 255, 255, 0.12);

  @include tablet {
    // top: 225px;
    // top: 120px;
    top: 195px;
    left: 9%;
  }

  @include desktop {
    // top: 371px;
    // top: 240px;
    top: 315px;
    // left: 175px;

    left: 12.792398%;
    width: 375px;
    height: 350px;
  }
}
.room-amenities-modal {
  position: relative;
  width: 100%;
  // height: 100vh;
  padding: 20px 15px;

  @include tablet {
    max-width: 1576px;
    padding: 20px 36px;
  }

  @include desktop {
    padding: 68px 75px;
  }

  &__title-container {
    width: 100%;
    padding-left: 11px;
    margin-bottom: 22.5px;

    @include tablet {
      // width: 400px;
      margin-bottom: 25.5px;
    }

    @include desktop {
      // width: 611px;
      padding-left: 9px;
      margin-bottom: 50px;
    }
  }

  &__title-top,
  &__title-bottom {
    font-family: $font-gt-walsheimregular;
    // font-size: 3.11125rem !important;
    // line-height: 3.46875rem;
    font-size: 2.5rem !important;
    line-height: 3rem;
    letter-spacing: 0.01125rem;
    color: $white;

    @include tablet {
      font-size: 3.8rem !important;
      line-height: 4.5rem;
      letter-spacing: 0.0184375rem;
    }

    @include desktop {
      font-size: 4.6875rem !important;
      line-height: 5.3125rem;
      letter-spacing: 0.025625rem;
    }
  }

  &__title-top {
    color: $white;
    text-align: left;
  }

  &__title-bottom {
    text-align: right;
  }
}
.amenities-container {
  display: flex;
  flex-direction: column;

  width: 100%;

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @include desktop {
    padding: 0 9px;
  }
}
.amenity {
  display: flex;
  align-items: center;

  width: 100%;
  // height: 74px;
  height: 149px;
  // padding: 0 27px;
  padding: 0px 15px;
  margin-bottom: 10px;

  border: 1px solid $white;

  @include tablet {
    width: calc(50% - 9px);
    height: 167px;
  }

  @include desktop {
    width: calc(50% - 25px);
    height: 162px;
    padding: 0 40px;
    margin-bottom: 20px;
  }

  &__text {
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01px;
    color: $white;

    @include tablet {
      font-size: 20px;
      line-height: 25px;
      letter-spacing: -0.01px;
    }

    @include desktop {
      font-size: 25px;
      line-height: 30px;
      letter-spacing: 0.11px;
    }
  }

  &__icon {
    min-width: 32.5px;
    margin-right: 17px;

    @include tablet {
      min-width: 52px;
    }

    @include desktop {
      min-width: 52px;
      margin-right: 30px;
    }
  }
}
.e-checkin-size {
  // width: 13px;
  // height: 25px;
  width: 16.25px;
  height: 31.25px;

  @include tablet {
    width: 26px;
    height: 50px;
  }

  @include desktop {
    width: 32px;
    height: 62px;
  }
}
.wifi-size {
  // width: 22px;
  // height: 18px;
  width: 27.5px;
  height: 22.5px;

  @include tablet {
    width: 44px;
    height: 44px;
  }

  @include desktop {
    width: 47px;
    height: 47px;
  }
}
.ipad-size {
  // width: 17px;
  // height: 25px;
  width: 21.25px;
  height: 31.25px;

  @include tablet {
    width: 34px;
    height: 50px;
  }

  @include desktop {
    width: 36px;
    height: 53px;
  }
}
.binge-size {
  // width: 25px;
  // height: 25px;
  width: 31.25px;
  height: 31.25px;

  @include tablet {
    width: 46px;
    height: 46px;
  }

  @include desktop {
    width: 49px;
    height: 49px;
  }
}
.workspace-size {
  // width: 25px;
  // height: 16px;
  width: 31.25px;
  height: 20px;

  @include tablet {
    width: 49px;
    height: 31px;
  }

  @include desktop {
    width: 52px;
    height: 34px;
  }
}
.bath-size {
  // width: 16px;
  // height: 25px;
  width: 20px;
  height: 31.25px;

  @include tablet {
    width: 29px;
    height: 46px;
  }

  @include desktop {
    width: 32px;
    height: 49px;
  }
}
.bedding-size {
  // width: 22px;
  // height: 25px;
  width: 27.5px;
  height: 31.25px;

  @include tablet {
    width: 39px;
    height: 44.7272727px;
  }

  @include desktop {
    width: 42px;
    height: 47.7272727px;
  }
}
.ac-heat-size {
  // width: 26px;
  // height: 17px;
  width: 32.5px;
  height: 21.25px;

  @include tablet {
    width: 44px;
    height: 27px;
  }

  @include desktop {
    width: 47px;
    height: 30px;
  }
}
.self-serve-closets-size {
  width: 35px;
  height: 24.2741935px;

  @include tablet {
    width: 57px;
    height: 38px;
  }

  @include desktop {
    width: 62px;
    height: 43px;
  }
}
.records-size {
  width: 35px;
  height: 26.9672131;

  @include tablet {
    width: 56px;
    height: 42px;
  }

  @include desktop {
    width: 61px;
    height: 47px;
  }
}
.bff-size {
  width: 30px;
  height: 26.0869565px;

  @include tablet {
    width: 43px;
    height: 37px;
  }

  @include desktop {
    width: 46px;
    height: 40px;
  }
}
