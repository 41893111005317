@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.blog-banner {
  position: relative;
  margin-top: 82px;
  margin-bottom: 65px;

  &__title {
    position: absolute;
    top: -70px;
    left: 0;
    right: 0;

    width: 100%;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;

    text-align: center;
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 38px;
    letter-spacing: 0.07px;
    line-height: 43px;
  }

  &__image-container {
    height: 553px;
    width: 100%;
    overflow: hidden;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__author-name-date {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 24px;
    margin-right: 24px;
  }

  &__author-name,
  &__date {
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 18px;
    letter-spacing: 0.07px;
    line-height: 20px;
  }

  @include tablet {
    margin-top: 150px;
    margin-left: 53px;
    margin-right: 53px;

    &__title {  
      top: -88px;
      max-width: 300px;
      font-size: 52.5px;
      line-height: 55px;
    }

    &__author-name-date {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @include desktop {
    margin-top: 234px;
    margin-left: 81px;
    margin-right: 83px;

    &__title {  
      top: -191px;
      max-width: 631px;
      font-size: 100px;
      line-height: 120px;
    }
    &__image{
      object-fit: contain;
    transform: scale(1);
    }
    &__image-container {
      height: 1000px;
    }
  }
}
