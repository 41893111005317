@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.annex-auth-two-column-layout {
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    // max-width: 1345px;
    margin: 0 auto;
  }

  &__left-column {
    position: relative;
  }

  &__right-column {
    margin-top: 80px;
  }

  &__right-column-container {
  }

  &__title {
    padding-top: 28px;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 20px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }

  .annex-form {
    padding-left: 22px;
    padding-right: 22px;
    // padding-bottom: 75px;
    padding-bottom: 20px;

    fieldset {
      color: $black;
      font-family: $font-gt-walsheimregular;
      font-size: 20px;
      line-height: 70px;
      letter-spacing: 0.07px;
    }

    .button {
      margin-top: 4px;
    }
  }

  .annex-form-group-container {
    // margin-bottom: 16px;
    margin-bottom: 24px;
  }

  button {
    &:focus {
      outline: 1px dotted #212121;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  @include desktop {
    &__container {
      flex-direction: row;
      padding: 0 36px;
    }

    &__left-column {
      margin-right: 50px;
      width: 100%;
      max-width: 563px;
    }

    &__right-column {
      margin-top: 0px;
      margin-left: 50px;
      width: 100%;
      max-width: 684px;
    }

    &__title {
      padding: 0;
      font-size: 40px;
      line-height: 70px;
      letter-spacing: 0.15px;
    }
  }
}
