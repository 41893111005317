@use '../../../partials/mixins' as *;
@use '../../../partials/variables' as *;
@use '../../../partials/typography' as *;

.disabled-btn-black {
    background-color: rgb(0, 0, 0, 0.25);
    // border: 1px solid rgb(0, 0, 0, 0.25);
  
    &:hover {
        background-color: rgb(0, 0, 0, 0.25);
        color: $white;
        border: 1px solid transparent;  
    }
}