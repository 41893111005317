@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.hero-carousel-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 0 24px;
  margin-bottom: 93.5px;

  @include tablet {
    margin-bottom: 100px;
  }

  @include desktop {
    // padding: 0 36px;
    // margin-bottom: 166px;
    margin-bottom: 220px;
  }

  &--experience {
    @include desktop {
      // padding: 0 36px;
      // margin-bottom: 166px;
      margin-bottom: 150px;
    }
  }
}
.hero-carousel-item-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 24px;

  @include desktop {
    padding: 0 36px;
  }
}
.image-mask {
  position: absolute;
  background-color: rgb(255, 255, 255, 0.15);
  // background: linear-gradient(transparent, rgb(255, 255, 255, 0.15));
  // width: calc(100% - 24px);
  top: 0;
  right: 24px;
  bottom: 0;
  left: 24px;

  @include desktop {
    right: 36px;
    left: 36px;
  }
}
.image-gradient-mask {
  position: absolute;
  // background-color: rgb(255, 255, 255, 0.15);
  background: linear-gradient(transparent, rgb(255, 255, 255, 0.15));
  // width: calc(100% - 24px);
  top: 0;
  right: 24px;
  bottom: 0;
  left: 24px;

  @include desktop {
    right: 36px;
    left: 36px;
  }
}

.hero-carousel {
  position: relative;
  width: 100%;

  @include desktop {
  }

  &__image {
    // position: relative;
    width: 100%;
    min-height: 613px;
    // height: 179.24vw;
    object-fit: cover;

    @include desktop {
      height: 843px;
    }
  }
}
.carousel-inner {
  overflow: visible;
  overflow-x: clip;
}
.carousel-item {
  width: 100%;

  @include desktop {
    position: relative;
  }
}
.hero-captions {
  position: absolute;
  bottom: -100px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 24px;

  @include desktop {
    bottom: -155px;
    justify-content: space-between;
    width: calc(100% - 70px);
    padding: 0 36px 0 0;
  }

  &__text,
  &__description {
    margin: 0;
    position: absolute;
    right: 24px;
    bottom: 50px;
    font-size: 0.875rem;
    letter-spacing: 0.034375rem;
    color: $black;
    font-family: $font-gt-walsheimregular;

    @include desktop {
      position: relative;
      bottom: 0px;
      right: 0px;
      height: 2rem;
    }
  }

  &__text {
    @include desktop {
      width: 12%;
    }
  }

  &__description {
    @include desktop {
      width: 60%;
    }
  }

  &__title {
    position: absolute;
    font-family: $font-gt-walsheimregular;
    margin: 0;
    bottom: 71px;
    z-index: 1;
    font-size: 4.75rem !important;
    line-height: 4.7222222rem;
    width: 85%;

    @include tablet {
      bottom: -9px;
      height: 10rem;
      font-size: 5.625rem !important;
      line-height: 5rem;
    }

    @include desktop {
      position: relative;
      bottom: -38px;

      // width: fit-content;
      // width: 63%;
      height: 17.5rem;
      margin-right: 30px;

      font-size: 8.75rem !important;
      // line-height: 4.375rem;
      line-height: 8.75rem;
      letter-spacing: 0.0325rem;
      // white-space: nowrap;
      // text-align: center;
    }

    &--experience {
      @include tablet {
        bottom: 0px;
        margin-right: 0px;
        width: 100%;
        height: 140px;
      }
    }
  }

  &--experience {
    @include desktop {
      bottom: -75px;
      padding-left: 36px;
      margin-right: 0px;
      width: 100%;
    }
  }
}
.hero-captions-link-container {
  display: flex;
  position: absolute;
  right: 24px;
  bottom: 30px;

  @include desktop {
    justify-content: flex-end;
    position: relative;
    right: 36px;
    bottom: 0px;

    width: 27%;
  }

  &__book-now {
    white-space: nowrap;

    @include desktop {
      margin-left: 4px;
    }
  }
}
.carousel-control-next,
.carousel-control-prev {
  width: 25px !important;
}
.carousel-control-prev {
  left: calc(100% - 55px) !important;
}
.button-next {
  position: absolute;
  bottom: -24px;
  right: 24px;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  @include desktop {
    right: 36px;
  }
}

.button-next-icon {
  position: relative;
  width: 100%;
}

.button-prev {
  position: absolute;
  bottom: -24px;
  right: 24px;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include desktop {
    right: 36px;
  }
}

.button-prev-icon {
  width: 100%;
}
.live-local-hero-title,
.experience-hero-title {
  @include desktop {
    position: absolute;
    font-family: $font-gt-walsheimregular;
    margin: 0;
    // top: calc(30.811404vw - 75px);
    right: -140px;
    white-space: nowrap;
    letter-spacing: 0.0325rem;
    transform: rotate(-90deg);
  }
}
.live-local-hero-title {
  color: $black;
}
.experience-hero-title {
  color: $white;
}
