@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;


.landing-container {
    position: relative;
    padding: 0 0;
    
    @include desktop {
        padding: 0 36px;
    }   
}

.landing-hero {
    position: relative;
    width: 100%;
    background: url('../../assets/images/landing-pages/main-bg-image.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    top: 0;
    height: 100%;
    min-height: 100vh;
    padding-top: 126px;

    @include tablet {
        padding-top: 186px;
    }

    @include desktop {
        padding: 135px 168px 0px;
    }

    &__mask {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0, 0.17);
    }

    &__icon {
        position: absolute;
        z-index: 1;
        max-width: 306px;

        @include tablet {
            max-width: 510px;
        }

        @include desktop {           
            max-width: 600px;
        }
    }

    &__title {
        color: $white;
        text-align: center;
        z-index: 1;
        font-family: $font-gt-walsheimregular;
        font-size: 3.5rem !important;
        // line-height: 3.984375rem;
        letter-spacing: 0.01625rem;
        max-width: 370px;
        padding-top: 40px;
        height: fit-content;

        @include tablet {
            font-size: 5.75rem !important;
            letter-spacing: 0.02758rem;
            max-width: 650px;
            padding-top: 0px;
        }
    
        @include desktop {
            font-size: 8.75rem !important;
            // line-height: 7.96875rem;
            letter-spacing: 0.0325rem;
            max-width: 750px;
            padding-top: 121px;
        }
        }
}
.experience-live-local-divider {
    position: absolute;
    top: 388.22px;
    left: calc(50% - 0.5px);
    height: 16px;
    border-left: 1px solid $white;

    @include tablet {
        top: 582.69px;
        
    }

    @include desktop {
        top: 372.5px;
    }
}
.experience {
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;

    @include tablet {
    }

    @include desktop {
        left: 36px;
        width: calc(100% - 72px);        
    }  

    &__button {
        position: absolute;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-family: $font-gt-walsheimregular;
        color: $white;
        left: calc(50% - (107px));
        // top: calc(50% + 101.5px);
        top: 374.72px;
        height: 43px;
        width: 107px;
        border: 1px solid $white;
        border-right: none;

        @include tablet {
            // top: 569.19px;
            width: 6.5rem;
            height: 6.5rem;
            border-radius: 100%;
            border: 1px solid rgb(255, 255, 255, 0.23);
            background-color: transparent;
            // top: calc(50% - 124px/2);
            top: 240px;
            // left: calc(50% - (470.5px));
            left: calc(50% - (360px));
            
        }
        @include desktop {
            width: 7.75rem;
            height: 7.75rem;
            border-radius: 100%;
            border: 1px solid rgb(255, 255, 255, 0.23);
            background-color: transparent;
            // top: calc(50% - 124px/2);
            top: 359px;
            // left: calc(50% - (470.5px));
            left: calc(50% - (525px));
        }

        &:hover {
            background-color: rgb(0, 0, 0, 0.33);
            color: $white;
            text-decoration: none;

            @include desktop {
                border: none;
            }
        }
        &:hover + .experience__image {
            transition: all 1s ease;
            // z-index: 2;
            opacity: 1;
            visibility: visible;
            // animation: animate 20s linear forwards;
            
        }
        &:hover + .experience__image .experience-title__text-1::before{
            height: 100%;    
        }
        &:hover + .experience__image .experience-title__text-2::before{
            height: 100%;    
        }
        
    }
    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        visibility: hidden;
        width: 100%;
        background: url('../../assets/images/landing-pages/experience-bg-image.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        transition: all 1s ease;
        opacity: 0;
        min-height: 100vh;
        padding-top: 126px;
        height: 100%;

        @include tablet {
            padding-top: 186px;
        }

        @include desktop {
            padding-top: 135px;           
        }

    }
}
.experience-image-mask {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255, 0.08);
}
.experience-title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__text-1 {
        z-index: 3;
        position: relative;
        color: transparent;
        background-clip:content-box;
        -webkit-background-clip: content-box;
        font-family: $font-gt-walsheimregular;
        // font-size: 4.375rem;
        font-size: 3.5rem !important;
        // line-height: 3.984375rem;
        letter-spacing: 0.01625rem;
        padding-top: 40px;

        @include tablet {
            // font-size: 7.5rem;
            font-size: 5.75rem !important;
            letter-spacing: 0.02758rem;
            max-width: 650px;
            padding-top: 0px;
        }

        @include desktop {
            // line-height: 7.96875rem;
            font-size: 8.75rem !important;
            text-align: right;
            letter-spacing: 0.0325rem;
            padding-top: 121px;
        }
    }
    &__text-1::before {
        content: 'Live,';
        background: $white;
        position: absolute;
        // top: 0;
        bottom: 20;
        left: 0;
        right: 0;
        height: 0;
        transition: all 1s ease;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
    }
    &__text-2 {
        z-index: 3;
        position: relative;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        font-family: $font-gt-walsheimregular;
        font-size: 3.5rem !important;
        // font-size: 4.375rem;
        // line-height: 3.984375rem;
        letter-spacing: 0.01625rem;

        @include tablet {
            // font-size: 7.5rem;
            font-size: 5.75rem !important;
            letter-spacing: 0.02758rem;
            // max-width: 650px;
            // padding-top: 0px;
        }

        @include desktop {
            // line-height: 7.96875rem;
            font-size: 8.75rem !important;
            text-align: right;
            letter-spacing: 0.0325rem;
        }
    }
    &__text-2::before {
        content: 'don\'t sleep';
        background: $white;
        position: absolute;
        // top: 0;
        bottom: 20;
        left: 0;
        right: 0;
        height: 0;
        transition: all 1s ease;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
    }
    
    
}
.live-local {
    position: absolute;
    top: 0;
    min-height: 100vh;
    height: 100%;
    width: 100%;

    @include tablet {

    }

    @include desktop {
        left: 36px;
        width: calc(100% - 72px);        
    }    

    &__button {
        position: absolute;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-family: $font-gt-walsheimregular;
        color: $white;
        right: calc(50% - (107px));
        // top: calc(50% + 101.5px);
        top: 374.72px;
        height: 43px;
        width: 107px;
        border: 1px solid $white;
        border-left: none;

        @include tablet {
            // top: 569.19px;
            width: 6.5rem;
            height: 6.5rem;
            border-radius: 100%;
            border: 1px solid rgb(255, 255, 255, 0.23);
            background-color: transparent;
            // top: calc(50% - 124px/2);
            top: 240px;
            // left: calc(50% - (470.5px));
            right: calc(50% - (360px));

        }
        @include desktop {
            width: 7.75rem;
            height: 7.75rem;
            border-radius: 100%;
            border: 1px solid rgb(255, 255, 255, 0.23);
            background-color: transparent;
            // top: calc(50% - 124px/2);
            top: 359px;
            right: calc(50% - (525px));
        }

        &:hover {
            background-color: rgb(0, 0, 0, 0.33);
            color: $white;
            text-decoration: none;

            @include desktop {
                border: none;
            }
        }
        &:hover + .live-local__image {
            transition: all 1s ease;
            // z-index: 2;  
            opacity: 1; 
            visibility: visible;         
        }
        &:hover + .live-local__image .live-local-title--1 {
            animation: right-to-left 1s ease;
        }
        &:hover + .live-local__image .live-local-title--2 {
            animation: left-to-right 1s ease;
        }
    }
    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        visibility: hidden;
        width: 100%;
        background: url('../../assets/images/landing-pages/local-bg-image.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        transition: all 1s ease;
        opacity: 0;
        min-height: 100vh;
        padding-top: 126px;
        height: 100%;

        @include tablet {
            padding-top: 186px;
        }

        @include desktop {
            padding-top: 135px;
        }

    }
}
.live-local-image-mask {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.2);
}
.icon-enhance {
    z-index: 2;
}
.live-local-title {
    color: $white;
    z-index: 3;

    @include desktop {
        // line-height: 7.96875rem;
        letter-spacing: 0.0325rem;
        width: 600px;
    }

    &--1 {
        text-align: left;
        animation: right-to-left-reverse 1s ease;
        font-family: $font-gt-walsheimregular;
        font-size: 3.5rem !important;
        margin: 0;
        // font-size: 4.375rem;
        // line-height: 3.984375rem;
        letter-spacing: 0.01625rem;
        padding-top: 40px;

        @include tablet {
            // font-size: 7.5rem;
            font-size: 5.75rem !important;
            letter-spacing: 0.02758rem;
            // max-width: 650px;
            padding-top: 0px;
        }

        @include desktop {
            // line-height: 7.96875rem;
            font-size: 8.75rem !important;
            letter-spacing: 0.0325rem;
            padding-top: 121px;
        }
    }
    &--2 {
        text-align: right;
        animation: left-to-right-reverse 1s ease;
        font-family: $font-gt-walsheimregular;
        font-size: 3.5rem !important;
        margin: 0;
        // font-size: 4.375rem;
        // line-height: 3.984375rem;
        letter-spacing: 0.01625rem;

        @include tablet {
            // font-size: 7.5rem;
            font-size: 5.75rem !important;
            letter-spacing: 0.02758rem;
            // max-width: 650px;
            // padding-top: 0px;
        }

        @include desktop {
            // line-height: 7.96875rem;
            font-size: 8.75rem !important;
            letter-spacing: 0.0325rem;
        }
    }
}
@keyframes left-to-right {
    0% {
        transform: translateX(-30%);
    }
    to {
        transform: translateX(0%);
    }
}
@keyframes left-to-right-reverse {
    0% {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-15%);
    }
}
@keyframes right-to-left {
    0% {
        transform: translateX(30%);
    }
    to {
        transform: translateX(0%);
    }
}
@keyframes right-to-left-reverse {
    0% {
        transform: translateX(0%);
    }
    to {
        transform: translateX(15%);
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}   