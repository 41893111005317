@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.events-carousel-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include desktop {
    margin-bottom: 37px;
  }
}

.events-carousel {
  position: relative;
  width: 100%;
}

.carousel-inner {
  overflow: visible;
  overflow-x: clip;
}

.events-carousel-item {
  width: 100%;

  @include desktop {
    display: flex;
    // position: relative;
  }
}

.events-button-next,
.events-button-prev {
  position: absolute;
  top: -32px;
  right: 0;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;

  @include desktop {
    // top: -50px;
    top: -67.5px;
  }
}

.events-button-next-icon {
  position: relative;
  width: 100%;
}

.events-button-prev-icon {
  width: 100%;
}

.events-button-prev,
.events-button-next {
  margin-right: 24px;

  @include desktop {
    // margin-right: 36px;
    margin-right: 0;
  }
}
