@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.other-considerations-modal-container {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;

  width: 100%;
  padding: 75px 24px;

  background-color: rgba(255, 255, 255, 0.88);

  @include desktop {
    padding: 93px 36px;
  }
}
.other-considerations-modal-close {
  position: absolute;
  top: 27.38px;
  right: 24px;
  cursor: pointer;
}
.other-considerations-modal-inner-container {
  position: relative;
  display: flex;
  justify-content: center;

  // height: 650px;
  width: 100%;

  background-image: url("../../assets/images/rooms-page/other-considerations-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include tablet {
    display: flex;
    justify-content: center;
  }

  @include desktop {
    display: flex;
    justify-content: center;
    // height: 843px;
  }
}
.other-considerations-annex-icon {
  position: fixed;
  top: 245px;

  width: 290px;

  color: rgba(255, 255, 255, 0.12);

  @include tablet {
    // top: 225px;
    top: 195px;
    left: 9%;
  }

  @include desktop {
    // top: 371px;
    top: 315px;
    // left: 175px;

    left: 12.792398%;
    width: 375px;
    height: 350px;
  }
}
.other-considerations-modal {
  position: relative;
  width: 100%;
  // height: 100vh;
  padding: 20px 15px;

  @include tablet {
    max-width: 1576px;
    padding: 20px 36px;
  }

  @include desktop {
    padding: 68px 75px;
  }

  &__title-container {
    width: 100%;
    padding-left: 11px;
    margin-bottom: 22.5px;

    @include tablet {
      // width: 525px;
      margin-bottom: 25.5px;
    }

    @include desktop {
      // width: 720px;
      padding-left: 9px;
      margin-bottom: 50px;
    }
  }

  &__title-top,
  &__title-bottom {
    font-family: $font-gt-walsheimregular;
    // font-size: 3.11125rem !important;
    // line-height: 3.46875rem;
    font-size: 2.5rem !important;
    line-height: 3rem;
    letter-spacing: 0.01125rem;
    color: $white;

    @include tablet {
      // font-size: 4.5rem !important;
      font-size: 3.8rem !important;
      // line-height: 5.21875rem;
      line-height: 4.5rem;
      letter-spacing: 0.0184375rem;
    }

    @include desktop {
      // font-size: 6.875rem !important;
      font-size: 4.6875rem !important;
      // line-height: 6.96875rem;
      line-height: 5.3125rem;
      letter-spacing: 0.025625rem;
    }
  }

  &__title-top {
    color: $white;
    text-align: left;
  }

  &__title-bottom {
    text-align: right;
  }
}
.considerations-container {
  display: flex;
  flex-direction: column;

  width: 100%;

  @include desktop {
    padding: 0 9px;
  }
}
.consideration {
  display: flex;
  align-items: center;

  width: 100%;
  // height: 74px;
  height: 303px;
  // padding: 0 27px;
  padding: 0 15px;
  margin-bottom: 10px;

  border: 1px solid $white;

  @include tablet {
    height: 142px;
  }

  @include desktop {
    // height: 123px;
    height: 160px;
    padding: 0 40px;
    margin-bottom: 20px;
  }

  &__text {
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01px;
    color: $white;

    @include tablet {
      font-size: 20px;
      line-height: 25px;
      letter-spacing: -0.01px;
    }

    @include desktop {
      font-size: 25px;
      line-height: 30px;
      letter-spacing: 0.11px;
    }
  }

  &__icon {
    min-width: 30px;
    margin-right: 17px;

    @include tablet {
      min-width: 48px;
    }

    @include desktop {
      min-width: 91px;
      margin-right: 30px;
    }
  }
}
.gym-size {
  // width: 23px;
  // height: 13px;
  width: 28.75px;
  height: 16.25px;

  @include tablet {
    width: 46px;
    height: 26px;
  }

  @include desktop {
    width: 74px;
    height: 41px;
  }
}
.no-elevator-size {
  // width: 24px;
  // height: 21px;
  width: 30px;
  height: 26.25px;

  @include tablet {
    width: 46px;
    height: 40.25px;
  }

  @include desktop {
    width: 74px;
    height: 64.75px;
  }
}
.parking-size {
  // width: 23px;
  // height: 23px;
  width: 28.75px;
  height: 28.75px;

  @include tablet {
    width: 46px;
    height: 46px;
  }

  @include desktop {
    width: 73px;
    height: 73px;
  }
}
.pet-friendly-size {
  width: 25px;
  height: 23.8888889px;
  @include tablet {
    width: 30px;
    height: 28.6666667px;
  }

  @include desktop {
    width: 50px;
    height: 47.7777778px;
  }
}
.room-service-size {
  width: 28px;
  height: 16.6029412px;

  @include tablet {
    width: 40px;
    height: 21.7647059px;
  }

  @include desktop {
    width: 65px;
    height: 35.3676471px;
  }
}
.check-in-out-size {
  width: 28.75px;
  height: 27.7916667px;

  @include tablet {
    width: 46px;
    height: 44.46666667px;
  }

  @include desktop {
    width: 73px;
    height: 70.56666667px;
  }
}
.cancel-size {
  width: 28.75px;
  height: 25.7758621px;

  @include tablet {
    width: 46px;
    height: 41.2413793px;
  }

  @include desktop {
    width: 73px;
    height: 65.4482759px;
  }
}
