@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.blog-item {
  display: block;
  text-decoration: none;
  line-height: 0;
  
  &__image-container {
    width: 100%;
    line-height: 0;
    overflow: hidden;
  }

  &__image {
    height: 653px;
    width: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: transform 0.5s ease;


    &--tablet-desktop-only {
      display: none;
    }

    @include tablet {
      &--mobile-only {
        display: none;
      }

      &--tablet-desktop-only {
        display: block;
      }
    }

    @include desktop {}
  }

  &__title {
    margin-top: 46px;
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 0.15px;

    @include tablet {}

    @include desktop {}
  }

  &__author-date {
    margin-top: 16px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.07px;
  }
  
  &:hover {
    .blog-item__image {
      transform: scale(1.05);
    }
  }
}
