@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.booking-two-column-layout {
  padding-left: 24px; //changed from 26px
  padding-right: 24px;  //changed from 26px

  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  @include desktop {
    padding-left: 36px;
    padding-right: 36px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__left-column {

    @include tablet {
      width: 50%;
    }

    @include desktop {
      width: 50%;
    }
  }
  
  &__right-column {
    display: none;

    @include tablet {
      display: block;
      width: 50%;
    }

    @include desktop {
      display: block;
      width: 50%;
    }
  }

  &__title {
    color: $black;
    font-size: 20px;
    line-height: 35px;
    font-family: $font-gt-walsheimregular;
    letter-spacing: 0.07px;

    @include desktop() {
      font-size: 40px;
      line-height: 70px;
      letter-spacing: 0.15px;
    }
  }

  &__alt-title {
    color: $black;
    font-size: 20px;
    line-height: 35px;
    font-family: $font-gt-walsheimregular;
    letter-spacing: 0.07px;

    @include desktop() {
      font-size: 40px;
      line-height: 70px;
      letter-spacing: 0.15px;
    }
  }
  
  &__image {
    width: 100%;
    object-fit: cover;
    
    @include tablet {
      height: 734px;
    }

    @include desktop {
      height: 834px;
    }
    
  
    
  }
}