@import '../../../partials/mixins';
@import '../../../partials/variables';

.info-image-group {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;
  // margin-bottom: 83px;

  @include desktop {
    padding: 0 36px;
    // margin-bottom: 147px;
  }

  &--image-card-position-top {}

  &--image-card-position-bottom {}

  &__left-column,
  &__left-column-image-container,
  &__left-column-image,
  &__right-column,
  &__right-column-image-container,
  &__right-column-image { width: 100%; }

  &__left-column {
    display: none;
  }
    
  &__left-column-image-container {
    overflow: hidden;
    // height: 858px;
    height: 843px;
  }

  &__left-column-image,
  &__right-column-image {
    object-fit: cover;
    height: 100%;
  }

  &__right-column-image-container {
    display: none;
    overflow: hidden;
    height: 427px;
  }

  &__bottom-title {
    position: absolute;
    bottom: -35px;
    
    display: none;
    margin-top: 0;
    margin-bottom: 0;
  
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 140px;
    line-height: 70px;
    letter-spacing: 0.52px;
    text-align: center;
  }
  
  @include tablet {}

  @include desktop {
    flex-direction: row; 

    &--image-card-position-top {
      .info-image-group__right-column {
        flex-direction: column; 
      }
    }

    &--image-card-position-bottom {
      .info-image-group__right-column {
        flex-direction: column-reverse; 
      }
    }

    &__left-column {
      display: initial;
    }

    &__right-column {
      display: flex;
      flex-direction: column;
    }

    &__right-column-image-container {
      display: initial;
    }

    &__bottom-title {
      display: block;
    }
  }
}
