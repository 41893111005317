@use "../../../partials/mixins" as *;
@use "../../../partials/variables" as *;
@use "../../../partials/typography" as *;

.booking-checkout-layout {
  display: flex;
  flex-direction: column-reverse;

  @include tablet {
    flex-direction: row;
  }

  @include desktop {
    flex-direction: row;
  }

  .booking-two-column-layout__right-column {
    display: block;
    margin-bottom: 26px;

    @include desktop {
      margin-bottom: 0px;
    }
  }
}

.booking-checkout {
  margin-bottom: 95px;

  .booking-two-column-layout__title {
    margin-bottom: 15px;

    @include tablet {
      font-size: 30px;
      letter-spacing: 0.11px;
      line-height: 40px;
    }

    @include desktop {
      margin-top: 20px;
      margin-bottom: 45px;

      font-size: 40px;
    }
  }

  &__alt-title {
    margin-bottom: 8px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    letter-spacing: 0.07px;
    line-height: 35px;

    @include tablet {
      padding-right: 22px;
      margin-bottom: 17.5px;
      font-size: 30px;
      letter-spacing: 0.11px;
      line-height: 40px;
    }

    @include desktop {
      padding-right: 0px;
      margin-bottom: 27px;
      font-size: 40px;
      letter-spacing: 0.15px;
      line-height: 70px;
    }
  }
}

.booking-checkout-list {
  display: flex;
  justify-content: space-between;
  padding-top: 9.5px;
  padding-right: 30px;
  padding-bottom: 9.5px;
  border-top: 1px solid $black;

  &__label {
    color: $black;
    font-size: 14px;
    font-family: $font-gt-walsheimregular;
    letter-spacing: 0.05px;
    line-height: 35px;
  }

  &__button {
    color: $accent-dark;
    font-family: $font-gt-walsheimregular;
    font-size: 14px;
    letter-spacing: 0.05px;
    line-height: 16px;
    background-color: transparent;
    cursor: pointer;
  }

  @include tablet {
    padding-top: 14.5px;
    padding-bottom: 14.5px;
    padding-right: 24px;

    &__label {
      font-size: 17px;
      letter-spacing: 0.06px;
      line-height: 52.5px;
    }

    &__button {
      font-size: 17px;
      letter-spacing: 0.06px;
      line-height: 19px;
    }
  }

  @include desktop {
    padding-top: 19.5px;
    padding-bottom: 19.5px;
    padding-right: 36px;

    &__label {
      font-size: 20px;
      letter-spacing: 0.07px;
      line-height: 70px;
    }

    &__button {
      font-size: 20px;
      letter-spacing: 0.07px;
      line-height: 22px;
    }
  }
}

.edit-accordion {
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease;

  &--active {
    // max-height: 1000px;
    max-height: 1100px;
    transition: all 1s ease-in-out 1s;
    // overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.booking-checkout-content {
  padding-top: 8.5px;
  padding-bottom: 13px;
  border-top: 1px solid $black;

  &--pr-0 {
    padding-right: 0 !important;
  }

  @include tablet {
    padding-top: 15px;
    padding-right: 24px;
    padding-bottom: 26.5px;
  }

  @include desktop {
    padding-top: 30px;
    padding-right: 36px;
    padding-bottom: 40px;
  }

  .booking-checkout__title {
    margin-bottom: 28px;
  }

  &__user-form {
  }

  &__guest-form {
    margin-top: 30px;
  }
}

.booking-checkout-info {
  margin-top: 25px;
  margin-bottom: 10px;

  &__label {
    margin-bottom: 5px;

    color: $black;
    font-size: 20px;
    font-family: $font-gt-walsheimbold;
    letter-spacing: 0.07px;
    line-height: 22px;
  }

  &__text {
    margin-bottom: 37px;

    color: $black;
    font-size: 16px;
    font-family: $font-gt-walsheimregular;
    letter-spacing: 0.07px;
    line-height: 22px;
  }
}

.border-bottom {
  border-bottom: 1px solid $black;
}

.border-top-none {
  border-top: none;
}

//trying this out

.annex-form-payment {
  &__label {
    margin-bottom: 4px;
    font-family: $font-gt-walsheimregular;
    font-size: 14px;
    letter-spacing: -0.01px;
    line-height: 16px;

    &--error {
      color: $form-red;
    }
  }

  &__input {
    caret-color: $form-green;
    background-color: transparent;
  }

  &__input:-webkit-autofill,
  &__input:-webkit-autofill:hover,
  &__input:-webkit-autofill:focus,
  &__input:-webkit-autofill:active {
    -webkit-text-fill-color: $black;
    transition: background-color 5000s ease-in-out 0s;
  }
  &__input:-webkit-autofill::first-line {
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 18px;
  }

  &__select {
    background-color: transparent;
    color: $black;
    // caret-color: $form-green;
  }

  &__two-column {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @include desktop {
      flex-direction: row;

      .annex-form-group-container-payment {
        padding-right: 5px;

        &:last-child {
          padding-left: 5px;
          padding-right: 0;
        }
      }
    }
  }

  &__error-message {
    margin-top: 10px;
    margin-left: 30px;

    color: $form-red;
    font-family: $font-gt-walsheimregular;
    font-size: 14px;
    letter-spacing: -0.01px;
    line-height: 16px;
  }

  &__success-message {
    margin-top: 10px;
    margin-left: 30px;

    color: $form-green;
    font-family: $font-gt-walsheimregular;
    font-size: 14px;
    letter-spacing: -0.01px;
    line-height: 16px;
  }

  &__checkbox {
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    letter-spacing: -0.01px;
    line-height: 23px;
  }

  &__checkbox-input {
    margin-right: 10px;
  }

  &__paragraph {
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    letter-spacing: -0.01px;
    line-height: 23px;

    a {
      color: $black;
    }
  }

  &__button-container {
    &--text-right {
      text-align: right;
    }
  }
}

.annex-form-group-container-payment {
  width: 100%;
  margin-bottom: 24px;
}

.annex-form-group-payment {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  height: 74px;
  padding: 17px 28px;

  // border: 1px solid $black;

  input,
  select,
  textarea {
    outline: none;
  }

  &:focus-within {
    // outline: -webkit-focus-ring-color auto 1px;
    // outline-color: -webkit-focus-ring-color;
    // outline-style: auto;
    // outline-width: 1px;
    border: 2px solid $form-green;
    // outline: 1px solid $form-green;
  }
}

.confirm-booking-button {
  width: 210.81px;
}

.hidden-input {
  display: none;
}

.sign-in-buttons-container {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: flex-start;
}

.sign-in-button {
  margin: 0px 0px 24px 24px;
}

.google-button-container {
  display: flex;
  justify-content: flex-end;
}
