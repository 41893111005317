@use '../../../partials/mixins' as *;
@use '../../../partials/variables' as *;
@use '../../../partials/typography' as *;

.sending-animation {
    display: flex;
    align-items: center;

    &__text {
        position: relative;

        margin-top: 10px;
        margin-left: 30px;

        font-family: $font-gt-walsheimregular;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.01px;
        
        color: lightgray;
        background-clip:content-box;
        -webkit-background-clip: content-box;   
    }

    &__text::before {
        content: 'Sending...';
        background: $form-green;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // height: 0;
        animation: sendingText 3s ease-in-out infinite;
        // transition: all 1s ease;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
    }
}

@keyframes sendingText {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
