@use '../../../partials/mixins' as *;
@use '../../../partials/variables' as *;
@use '../../../partials/typography' as *;

.text-link-icon {
  position: relative;

  display: inline-block;
  margin-top: 22px;

  color: $black;
  font-family: $font-gt-walsheimregular;
  font-size: 20px;
  letter-spacing: 0.07px;
  text-decoration: none;

  transition: all 300ms ease;

  &::after {
    position: absolute;
    left: 0;
    bottom: -5px;
    content: '';

    height: 1px;
    width: 100%;

    background-color: $black;
    transition: all 300ms ease;
  }

  &__icon {
    margin-left: 13.5px;
    margin-bottom: -4px;
    transition: all 50ms ease;
    width:20px;
    height:20px;
  }

  &:hover &__icon  {
    //color: $white;
    margin-left: 20px;
    transition: all 0.5s ease;

    &::after {
      background-color: $white;
    }

    &__icon {
      color: $white !important;
    }
  }
}
