@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.clear-filters-button-container {
    display: flex;
    justify-content: flex-end;

    border-bottom: 1px solid $black;
}
.clear-filters-button {
    width: fit-content;
    padding-bottom: 22px;

    font-size: 16px;
    line-height: 18px;
    font-family: $font-gt-walsheimregular;
    color: $black;

    background-color: transparent;

    cursor: pointer;

}