@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.blog-detail {
  margin-left: 24px;
  margin-right: 24px;

  &__content {
    width: 100%;
    max-width: 873px;
    margin: 0 auto;
    margin-bottom: 60px;

    color: $black !important;
    font-family: $font-gt-walsheimregular;
    font-size: 18px;
    letter-spacing: 0.07px;
    line-height: 24px !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a,
    li,
    p {
      margin-bottom: 32px;
      color: $black !important;
      font-family: $font-gt-walsheimregular;
      font-size: 18px;
      letter-spacing: 0.07px;
      line-height: 24px !important;
    }

    img {
      width: 100%;
    }
  }

  @include tablet {
    margin-left: 53px;
    margin-right: 53px;
  }

  @include desktop {
    margin-left: 81px;
    margin-right: 83px;
  }
}