@use '../../../partials/mixins' as *;
@use '../../../partials/variables' as *;
@use '../../../partials/typography' as *;

.logout {
    padding: 20px 0 75px;
    margin: 0 24px;

    border-top: 1px solid $black;
}
