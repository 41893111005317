@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.two-image {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 24px;
    padding-right: 0;
    padding-bottom: 127px;

    background-color: $black;

    @include desktop {
        padding: 0 36px 290px;
    }

    &__left-image {
        position: relative;
        width: 100%;
        height: 563px;
        object-fit: cover;

        @include desktop {
            width: 50%;
            height: 843px;
        }
    }

    &__right-image {
        position: relative;
        top: 33px;

        width: 24px;
        height: 563px;
        object-fit: cover;

        @include desktop {
            position: relative;
            top: 91px;

            width: 50%;
            height: 843px; 
            object-fit: cover
        }
    }
}