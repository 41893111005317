@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.room-images-section {
    position: relative;

    width: 100%;
    min-height: 1125px; 
    // padding-bottom: 38px;
    padding-bottom: 3.3777777%;

    @include tablet {
        min-height: 2100px;
    }

    @include desktop {
        min-height: 2464px;
        // padding-bottom: 91px;
        padding-bottom: 3.693182%;
    }

    &__1 {
        position: absolute;

        width: 52%;
        // max-width: 645px;
        height: 306px;
        // height: 27.2%;
        // max-height: 809px;

        img {
            
        img {
            object-fit: cover;
        }
        }

        @include tablet {
            height: 557.5px;
        }

        @include desktop {
            // left: 138px;
            left: 9.583333%;

            width: 44.791667%;
            // max-width: 2000px;
            height: 809px;
            // height: 32.832792%;
        }
    }

    &__2 {
        position: absolute;
        right: 0;
        // top: 258px;
        top: 22.9333333%;

        width: 43.384615%;
        // max-width: 414px;
        height: 238px;
        // height: 21.1555555%;
        // max-height: 476px;

        
        img {
            object-fit: cover;
        }

        @include tablet {
            height: 487.425287px;
        }

        @include desktop {
            // right: 95px;
            right: 6.597222%;
            // top: 425px;
            top: 17.248377%;

            width: 28.75%;
            // max-width: 2000px;
            height: 476px;
            // height: 19.318182%;

        }
    }

    &__3 {
        position: absolute;
        // top: 403px;
        top: 35.8222222%;
        // left: 24px;
        left: 6.153846%;

        width: 41.794872%;
        // max-width: 465px;
        height: 200px;
        // height: 17.777777%;
        // max-height: 573px;

        
        img {
            object-fit: cover;
        }

        @include tablet {
            height: 386.5px;
        }

        @include desktop {
            // top: 893px;
            top: 36.241883%;
            // left: 36px;
            left: 2.5%;
            width: 32.291667%;
            // max-width: 2000px;
            height: 573px;
            // height: 23.25487%;
        }
    }

    &__4 {
        position: absolute;
        // top: 623px;
        top: 55.3777777%;
        // right: 24px;
        right: 6.153846%;

        width: 60.512821%;
        // max-width: 720px;
        height: 133px;
        // height: 11.8222222%;
        // max-height: 405px;

        
        img {
            object-fit: cover;
        }

        @include tablet {
            top: 56%;
            height: 269px;
        }

        @include desktop {
            // top: 964px;
            top: 39.123377%;
            // right: 36px;
            right: 2.5%;

            width: 50%;
            // max-width: 2000px;
            height: 405px;
            // height: 16.436688%;           
        }
    }

    &__5 {
        position: absolute;
        // top: 778px;
        top: 69.1555555%;
        // right: 24px;
        left: 0;

        width: 50%;
        // max-width: 645px;
        height: 312px;
        // height: 27.7333333%;
        // max-height: 809px;

        
        img {
            object-fit: cover;
        }

        @include tablet {
            top: 71%;
            height: 560.5px;
        }

        @include desktop {
            // top: 1564px;
            top: 63.474026%;
            // left: 179px;
            left: 12.430556%;

            width: 44.791667%;
            // max-width: 2000px;
            height: 809px;
            // height: 32.832792%;
        }
    }

    &__6 {
        position: absolute;
        // top: 813px;
        top: 72.2666666%;
        // right: 24px;
        right: 6.153846%;

        width: 39.230769%;
        // max-width: 414px;
        height: 175px;
        // height: 15.555555%;
        // max-height: 476px;

        
        img {
            object-fit: cover;
        }

        @include tablet {
            top: 74.111111%;
            height: 325.5px;
        }

        @include desktop {
            // top: 1564px;
            top: 63.474026%;
            // right: 36px;
            right: 2.5%;

            width: 28.75%;
            // max-width: 2000px;
            height: 476px;
            // height: 19.318182%;

        }
    }
}