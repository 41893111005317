@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.booking-menu {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 352px;
  //will this work? Animate max height of booking menu component
  max-height: 0;

  opacity: 0;
  overflow: hidden;
  transition: opacity 0.1s ease;
  transition: all 0.5s ease;

  @include desktop {
    position: absolute;
    flex-direction: row;

    width: fit-content;
    max-width: 904px;
    max-height: 1000px;
    padding: 0;

    overflow: visible;
    opacity: 1;
    transition: none;
  }

  &--mobile-active {
    max-height: 1000px;
    padding-bottom: 24px;

    opacity: 1;
    transition: opacity 0.5s ease;
    transition: all 0.5s ease;
  }
}
.room-booking-menu {
  display: flex;
  flex-direction: column;
  // position: relative;
  // bottom: 80;

  width: 100%;
  // max-width: 352px;
  //will this work? Animate max height of booking menu component
  max-height: 0;

  opacity: 0;
  overflow: hidden;
  transition: opacity 0.1s ease;
  transition: all 0.5s ease;

  @include desktop {
    position: fixed;
    bottom: 0;
    flex-direction: row;
    z-index: 2;
    // width: fit-content;
    // max-width: 904px;
    width: 100%;
    max-height: 1000px;
    padding: 0;

    overflow: visible;
    opacity: 1;
  }

  &--reveal {
    animation: fadeIn 1s ease;
  }

  &--mobile-active {
    max-height: 1000px;
    // padding-bottom: 24px;
    opacity: 1;
    transition: opacity 0.5s ease;
    transition: all 0.5s ease;

    margin-top: 24px;
    // border-top: 1px solid $black;
  }
}
.accordion {
  position: relative;
  display: flex;
  flex-direction: column;

  background-color: transparent;

  @include desktop {
    background-color: rgb(255, 255, 255, 0.9);
  }

  &--location {
    min-width: 100%;

    border: 1px solid $black;

    @include desktop {
      min-width: 12.9375rem;

      border: none;
      border-right: 1px solid $black;
    }
  }
  &--dates {
    min-width: 100%;

    border: 1px solid $black;
    border-top: none;

    @include desktop {
      min-width: 23.25rem;

      border: none;
      border-right: 1px solid $black;
    }
  }
  &--dates-room-booking {
    min-width: 100%;
    // margin-top: 24px;

    border: 1px solid $black;
    // border-top: none;

    @include desktop {
      min-width: 23.25rem;
      margin-top: 0;

      border: none;
      border-right: 1px solid $black;
    }
  }
  &--guests {
    min-width: 100%;

    border: 1px solid $black;
    border-top: none;

    @include desktop {
      min-width: 13.0625rem;
      border: none;
      border-right: 1px solid $black;
    }
  }
  &--available {
    min-width: 100%;

    border: 1px solid $black;
    border-top: none;

    text-decoration: none;
    color: $black;

    @include desktop {
      min-width: 7.25rem;

      border: none;
    }

    &:hover {
      background-color: rgb(0, 0, 0, 1);
      color: $white;

      cursor: pointer;
    }

    &:hover .accordion-header__title {
      color: $white;
    }

    &:hover .right-arrow-style {
      color: $white;
    }
  }
  &--mobile-menu {
    min-width: 100%;

    border: 1px solid $black;
    border-top: none;
  }
  &--check-availability {
    min-width: 100%;
    // width: 100%;

    @include desktop {
      min-width: calc(50% - 289.5px);

      border-right: 1px solid $black;
    }
  }
  &--cost {
    min-width: 100%;

    @include desktop {
      min-width: calc(50% - 289.5px);
    }

    &:hover {
      background-color: rgb(0, 0, 0, 1);
      color: $white;
    }

    &:hover .right-arrow-style {
      color: $white;
    }
  }
}
.accordion-header {
  display: flex;
  flex-direction: column;

  //will this work? Animate max height of smallest div in componenet
  max-height: 0;
  padding: 0 18px 0 16.5px;

  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;

  @include desktop {
    max-height: 1000px;
    padding: 12px 18px 16px 16.5px;

    visibility: visible;
    overflow: visible;
    opacity: 1;
  }

  &--mobile-active {
    max-height: 1000px;
    padding: 12px 18px 16px 16.5px;

    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease;
  }

  &:focus {
    outline: none;
  }

  &__title {
    margin-bottom: 4px;

    font-family: $font-gt-walsheimregular;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: -0.000625rem;
    color: $black;
  }

  &__temp-title {
    margin-bottom: 4px;

    font-family: $font-gt-walsheimregular;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: -0.000625rem;
    color: $black;
  }

  &--border {
  }

  &--check-availability {
    display: flex;
    align-items: center;

    height: 100%;
  }

  &--cost {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 12px 18px 16px 16.5px;

    border: 1px solid $black;
    border-top: none;

    @include desktop {
      padding: 12px 0 16px 16.5px;

      border: none;
    }
  }
}
.accordion-header-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__text {
    margin: 0;

    font-family: $font-gt-walsheimregular;
    font-size: 1.375rem;
    line-height: 1.5625rem;
    letter-spacing: -0.000625rem;

    &--cost {
      margin-right: 96.5px;
    }
  }

  &__temp-text {
    margin: 0;

    font-family: $font-gt-walsheimregular;
    font-size: 1.375rem;
    line-height: 1.5625rem;
    letter-spacing: -0.000625rem;

    color: transparent;

    &--cost {
      margin-right: 96.5px;
    }
  }

  &__icon {
    transition: transform 0.5s ease;
  }

  &--cost {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include desktop {
      justify-content: flex-start;
    }
  }
}
.accordion-header-check-availability {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;

  &__text {
    padding-right: 99px;
    margin: 0;

    font-family: $font-gt-sectra-displayregular;
    font-size: 1.375rem;
    line-height: 1.75rem;
    letter-spacing: -0.000625rem;
  }
}
.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}
.rotate-chevron {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}
.accordion-dropdown {
  display: flex;
  flex-direction: column;
  top: 73px;

  width: 100%;
  max-height: 0;

  background-color: transparent;

  transition: max-height 0.5s ease;
  overflow: hidden;

  @include desktop {
    position: absolute;

    background-color: rgba(255, 255, 255, 0.9);
  }

  &__text {
    padding: 12px 18px 16px 16.5px;

    font-family: $font-gt-walsheimregular;
    font-size: 1.375rem;
    color: $black;

    background-color: transparent;

    &:hover {
      color: $white;

      background-color: rgb(0, 76, 69, 0.9);
    }

    &:focus {
      outline: none;
    }
  }

  &--location {
    width: 100%;

    @include desktop {
      min-width: 12.9375rem;

      border: none;
      border-right: 1px solid $black;
    }
  }
  &--dates {
    @include desktop {
      min-width: 23.25rem;
    }
  }
  &--guests {
    @include desktop {
      min-width: 13.0625rem;

      border: none;
      border-right: 1px solid $black;
    }
  }
  &--available {
    @include desktop {
      min-width: 7.25rem;
    }
  }
}
.accordion-dropdown-book-room {
  // position: absolute;
  bottom: 80px;
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 0;

  background-color: transparent;

  transition: max-height 0.5s ease;
  overflow: hidden;

  @include desktop {
    position: absolute;
    bottom: 73px;

    width: 100%;

    background-color: rgba(255, 255, 255, 0.9);
  }
}
.right-arrow-size {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  width: 20px;
  height: 15px;

  &__roomArrow {
    @include desktop {
      position: absolute;
      left: 220px;
    }
  }
}
.select-dates {
  position: relative;
}
.open-booking-menu {
  cursor: pointer;
}
.label {
  margin-bottom: 4px;

  font-family: $font-gt-walsheimregular;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: -0.000625rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
