@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.blog {
  margin-left: 24px;
  margin-right: 24px;

  &__title {
    margin-top: 20px;
    margin-bottom: 32px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 100px;
    line-height: 120px;
    letter-spacing: 0.36px;
    text-align: center;

    @include tablet {
      margin-top: 40px;
      margin-bottom: 62px;
    }

    @include desktop {
      margin-top: 80px;
      margin-bottom: 125px;
    }
  }

  &__items {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    a {
      display: block;
      height: max-content;
      width: 100%;
      margin-bottom: 33px;
      text-decoration: none;
      transition: all 0.3s ease;
      
      @include tablet {
        width: 50%;
        padding-left: 8.25px;
        padding-right: 8.25px;

        &:nth-child(2n) {
          margin-top: 161.5px;
        }  
      }

      @include desktop {
        padding-left: 16.5px;
        padding-right: 16.5px;

        &:nth-child(2n) {
          margin-top: 323px;
        }
      }
    }

    @include tablet {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include desktop {}
  }

  @include tablet {
    margin-left: 53px;
    margin-right: 53px;
  }

  @include desktop {
    margin-left: 81px;
    margin-right: 83px;
  }
}

.blog__got-questions {
  padding: 0 24px;
  margin-top: 46px;
  margin-bottom: 97px;

  @include tablet {
    padding: 0;
  }

  @include desktop {
    margin-top: 110px;
    margin-bottom: 0;
  }
}