@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.guest-quote {
  padding: 40px 24px 36px;

  background-color: $black;

  @include desktop {
    padding: 0 230px 152px 98px;
  }

  &__text {
    position: relative;
    max-width: 720px;
    // margin-bottom: 20.97px;

    font-family: $font-gt-walsheimregular;
    font-size: 1.974375rem;
    line-height: 1.776875rem;
    letter-spacing: 0.164375rem;
    color: $white;
    text-align: center;

    @include desktop {
      max-width: 1700px;
      // margin-bottom: 53px;

      font-size: 4.5rem;
      line-height: 5rem;
      letter-spacing: 0.416875rem;
    }
  }

  &__name {
    position: relative;
    max-width: 720px;

    font-family: $font-gt-walsheimregular;
    font-size: 1rem;
    line-height: 0.829375rem;
    letter-spacing: 0.083125rem;
    color: $white;
    text-align: right;

    @include desktop {
      max-width: 1700px;

      font-size: 2.5rem;
      line-height: 2.625rem;
      letter-spacing: 0.208125rem;
    }
  }
}
