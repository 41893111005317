@use "../../../partials/mixins" as *;
@use "../../../partials/variables" as *;
@use "../../../partials/typography" as *;

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 44%;
  max-width: 157px;
  height: 45px;
  padding: 0 6%;
  border: 1px solid $black;
  border-radius: 27px;

  @include tablet {
    padding: 0 24px;
    height: 54px;
  }

  &__minus,
  &__plus {
    width: 15px;
    height: 15px;
    color: $black;
    cursor: pointer;
  }

  &__number {
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    color: $black;
  }
}
