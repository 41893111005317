@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 20;
  height: 88px;
  padding: 0 24px;
  width: 100%;

  @include desktop {
    height: 91px;
    padding: 0 36px;
  }

  &--dark {
    background-color: $black;
  }

  &__link {
    text-decoration: none;
  }
}
.book-now-icon {
  width: 32px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hide-disable {
  visibility: hidden;
}
.book-now-button {
  @include desktop {
    height: 40px !important;
    font-family: $font-gt-walsheimregular;
  }
}
.hamburger {
  height: 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &__top {
    border-bottom: 2px solid $black;
    width: 32px;
    transition: all 0.5s ease;

    &--dark {
      border-bottom: 2px solid $white;
    }

    &--rotate {
      transform-origin: top left;
      transform: rotate(45deg);
      width: 27.6984848px;
      transition: width 0.5s ease;
      transition: transform 0.5s ease 0.5s;
    }
  }
  &__middle {
    border-bottom: 2px solid $black;
    width: 32px;
    transform: scaleX(32px);
    transition: all 0.5s ease;

    &--dark {
      border-bottom: 2px solid $white;
    }

    &--remove {
      transform: scaleX(0);
      transition: all 0.4s ease;
    }
  }
  &__bottom {
    border-bottom: 2px solid $black;
    width: 32px;
    transition: all 0.5s ease;

    &--dark {
      border-bottom: 2px solid $white;
    }

    &--rotate {
      transform-origin: bottom left;
      transform: rotate(-45deg);
      width: 27.6984848px;
      transition: width 0.5s ease;
      transition: transform 0.5s ease 0.5s;
    }
  }
}
.icon-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  @include desktop {
    width: 81.5px;
  }

  &__icon {
    width: 31px;
    height: 29px;

    @include desktop {
      margin-right: 14.5px;
    }
  }

  &__close {
    @include desktop {
      margin: 0 34.5px 0 26px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    font-family: $font-gt-walsheimregular;

    color: $black;

    @include desktop {
    }

    &--dark {
      color: $white;
    }
  }
}
.logo-container {
  @include desktop {
    width: 84px;
    height: 15px;
  }
}
.annex-logo {
  width: 89.6px;
}
.join-login {
  display: flex;
  flex-direction: row;

  justify-content: flex-end; //keep this?

  @include desktop {
    // padding: 0 13px;

    width: 170.25px; // keep this?
  }

  &__link {
    height: 34px;
    width: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $black;
    border: 1px solid $black;
    font-family: $font-gt-walsheimregular;

    &:hover {
      background-color: $black;
      color: white;
      border: 1px solid $black;
    }

    &--dark {
      color: $white;
      border: 1px solid $white;

      &:hover {
        background-color: $white;
        color: $black;
        border: 1px solid $white;
      }
    }

    &--divider {
      border-right: none;
    }
  }
}
.my-account {
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    padding: 0 13px;

    font-family: $font-gt-walsheimregular;
    color: $black;
    text-decoration: none;
    white-space: nowrap;

    border: 1px solid $black;
    background-color: $white;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}
