@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.call-to-action-container {
    position: relative;
    padding: 0px 24px 32px;

    background-color: $white;

    @include desktop {
        padding: 0px 36px 0px;
    }
}
.call-to-action {
    background-color: $accent-dark;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 89px 0px 87px;

    @include desktop {
        padding: 124px 0 119px;
    }

    &__title {
        color: $white;
        font-family: $font-gt-walsheimregular;
        width: 80%;
        max-width: 603px;
        text-align: center;
        margin-bottom: 30px;
        line-height: 2.5rem;

        @include desktop {
            max-width: 800px;
            line-height: 4.375rem;
            margin-bottom: 17px;
        }   
    }

    &__text {
        color: $white;
        font-family: $font-gt-walsheimregular;
        text-align: center;
        max-width: 24.875rem;
        margin: 0 36px 55px;
        line-height: 1.375rem;

        @include desktop {
            margin-bottom: 71px;
        }
        
    }
}
// .sms-text {
//     height: 100%;
//     width: 100%;
//     color: $white;
//     text-decoration: none;
// }
.sms-button {
    width: 225.7px;
}