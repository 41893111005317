@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.annex-restaurant-info {
  display: flex;
  flex-direction: column;
  background-color: $white;

  &__image-container {
    // width: 100%;

    // @include desktop {
    //   width: 50%;
    // }
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 0 24px;
    // margin-bottom: 93.5px;

    @include desktop {
      width: 50%;
      // padding: 0 36px;
      // margin-bottom: 166px;
    }
  }

  &__image {
    height: 447px;
    // height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__info {
    padding-top: 33px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop {
      width: 50%;
      padding-left: 36px;
      padding-right: 36px;
    }
  }

  &__info-container {
  }

  &__name {
    margin-top: 0;
    margin-bottom: 9px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 30px;
    line-height: 70px;
    letter-spacing: 0.15px;
  }

  &__paragraph {
    margin-top: 0;
    margin-bottom: 30px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }

  .button-arrow-link {
    margin-top: 14px;
    width: 100%;
  }

  &__hours {
    margin-top: 55px;
    margin-bottom: 20px;

    color: $black;
    font-family: $font-gt-walsheimbold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }

  &__hour-times {
    margin-top: 0;
    margin-bottom: 0;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }

  // .button {
  //   margin-top: 34px;
  // }

  &__message {
    margin-top: 34px;
    margin-bottom: 0;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }

  &__text-us {
    margin-top: 34px;
    margin-bottom: 0;
  }

  @include desktop {
    flex-direction: row;

    &__image {
      height: 100%;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 125px 64px 0;
      // padding: 114px 64px;
    }

    &__name {
      margin-bottom: 20px;
      font-size: 40px;
    }

    &__paragraph {
      margin-bottom: 20px;
      font-size: 20px;
    }

    .button-arrow-link {
      margin-top: 30px;
      width: 100%;
    }

    &__hours {
      margin-top: 66px;
    }

    // .button {
    //   margin-top: 114px;
    // }

    &__message {
      margin-top: 95px;
    }

    &__text-us {
      margin-top: 95px;
    }
  }
}

.button-next-restaurant {
  position: absolute;
  // bottom: -24px;
  top: 456px;
  right: 24px;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  @include desktop {
    top: -32.5px;
    right: 36px;
  }
}

.button-next-icon-restaurant {
  position: relative;
  width: 100%;
}

.button-prev-restaurant {
  position: absolute;
  // bottom: -24px;
  top: 456px;
  right: 24px;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include desktop {
    top: -32.5px;
    right: 36px;
  }
}

.button-prev-icon-restaurant {
  width: 100%;
}

.restaurant-carousel {
  position: relative;
  width: 100%;
}

.restaurant-images-item-wrapper {
  height: 447px;
  padding: 0 24px;

  @include desktop {
    height: 843px;
    padding: 0;
  }
}
