@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.booking-menu-mobile-container {
    width: 100%;
    padding: 21.5px 0 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.room-booking-menu-mobile-container {
    width: 100vw;
    padding: 21.5px 0 0;
    position: fixed;
    // bottom: 0;
    top: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.booking-menu-mobile {
    position: absolute;
    width: 100%;
    top: 21.5px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--active {

    }

    &__button {
        position: absolute;
        z-index: 3;
        top: -21.5px;
        height: 43px;
        width: 142px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-gt-walsheimregular;
        color: $black;
        border: 1px solid $black;
        background-color: transparent;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background-color: $black;
            color: $white;
            
            transition: all 0.3s ease;
        }
    }
}
.menu-container {
    position: relative;
    width: 100%;
    padding: 0 24px;
    overflow: hidden;
    max-height: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255, 0.9);
    transition: all 0.5s ease;

    &--active {
        max-height: 1000px;        
        padding-top: 52.5px;
        overflow: visible;
        transition: all 0.5s ease;
    }
}
.room-booking-accordion-header-mobile-container {
    // position: absolute;
    position: fixed;
    // top: 400px;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;

    width: 100vw;
    padding: 0 24px;

    background-color: rgb(255, 255, 255, 0.9);
}
.room-booking-header-dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 352px;
}
.room-booking-accordion-header-mobile {
    display: flex;
    align-items: center;

    width: 100%;
    height: 80px;
}
.room-booking-accordion-header-selector-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    &__text {
        font-family: $font-gt-sectra-displayregular;
        font-size: 1.375rem;
        line-height: 1.5625rem;
        letter-spacing: -0.000625rem;
        margin: 0;

        &--cost {
            margin-right: 96.5px;
        }
    }

    &__icon {
        transition: transform 0.5s ease;
    }

    &--cost {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}