@use '../../../partials/mixins' as *;
@use '../../../partials/variables' as *;
@use '../../../partials/typography' as *;

.annex-payment-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 35px;
  margin-bottom: 23px;
  border-bottom: 1px solid $black;

  &__info {
    padding-left: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__text {
    margin-top: 0;
    margin-bottom: 0;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.07px;

    &--mr {
      margin-right: 26px;
    }
  }

  &__button {
    color: #1D97FF;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.07px;

    background-color: transparent;
  }
}

