@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.terms-conditions-container {
  text-align: center;
  width: 100%;
  display: grid;
  justify-content: center;
  @include desktop {
    padding: 0 36px;
  }
}

.terms {
  max-width: 1435px;
  padding: 0 30px;

  &__title {
    // padding-top: 62.5px;
    padding: 62.5px 24px 0px;
    margin-bottom: 70px;
    color: $black;
    font-family: $font-gt-sectra-displayregular;
    font-size: 70px;
    letter-spacing: 0.66px;
    line-height: 72.5px;

    @include tablet {
      padding-top: 93.75px;
      // margin-bottom: 75px;

      // font-size: 105px;
      // letter-spacing: 0.99px;
      line-height: 125px;
    }

    @include desktop {
      padding-top: 125px;
      margin-bottom: 78.5px;

      font-size: 140px;
      letter-spacing: 1.32px;
      line-height: 145px;
    }
  }

  &__subTitle {
    padding: 0px 24px;
    color: $black;
    font-size: 16px;
    text-transform: uppercase;
    font-family: $font-gt-walsheimregular;

    @include desktop {
      font-size: 18px;
    }
  }

  .terms_text_container {
    font-family: $font-gt-walsheimregular;
    margin-top: 80px;

    &--text {
      letter-spacing: 0.07px;
      font-size: 16px;
      line-height: 20px !important;
      text-align: left;
      padding: 0px 24px 24px;
      a {
        color: $black;
      }

      @include desktop {
        font-size: 18px;
        line-height: 25px !important;
      }
    }

    .terms_text_container_title {
      color: $black;
      font-family: $font-gt-walsheimregular;
      font-size: 20px;
      letter-spacing: 0.15px;
      padding: 20px 24px 0 24px;
      text-align: left;
      list-style: none;

      @include desktop {
        font-size: 40px;
      }
    }
  }
}
