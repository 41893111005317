//breakpoints
$tablet-breakpoint: 768px;
// $desktop-breakpoint: 1280px;
// $desktop-breakpoint: 1440px;
$desktop-breakpoint: 1366px;

//primary colors
$black: #000000;
$white: #FFFFFF;

//accent colors
$accent-dark: #004C45;
$accent-light: #668883;

//form colors
// $form-red: #B92C27;
$form-red: red;
$form-green: #004C45;

$wildSand: #F4F4F4;

// fonts
$font-gt-sectra-finebook-italic: 'gt_sectra_finebook_italic';
$font-gt-sectra-finebook: 'gt_sectra_finebook';
$font-gt-sectra-displayregular: 'gt_sectra_displayregular';
$font-gt-walsheimregular: 'gt_walsheimregular';
$font-gt-walsheimbold: 'gt_walsheimbold';

//copied from bootstrap files
// Carousel

$carousel-control-color:             $white !default;
$carousel-control-width:             15% !default;
$carousel-control-opacity:           .5 !default;
$carousel-control-hover-opacity:     .9 !default;
$carousel-control-transition:        opacity .15s ease !default;

$carousel-indicator-width:           30px !default;
$carousel-indicator-height:          3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer:          3px !default;
$carousel-indicator-active-bg:       $white !default;
$carousel-indicator-transition:      opacity .6s ease !default;

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $white !default;

$carousel-control-icon-width:        20px !default;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>") !default;

$carousel-transition-duration:       .6s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

// $enable-caret:                                true !default;
// $enable-rounded:                              true !default;
// $enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
// $enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
// $enable-grid-classes:                         true !default;
// $enable-pointer-cursor-for-buttons:           true !default;
// $enable-print-styles:                         true !default;
// $enable-responsive-font-sizes:                false !default;
// $enable-validation-icons:                     true !default;
// $enable-deprecation-messages:                 true !default;