@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.blog-recent-post-carousel {   
    position: relative;
    padding-bottom: 46px;
    
    &__title {
        font-family: $font-gt-walsheimregular;
        line-height: 4.375rem;
        padding: 46px 0 37px 24px;

        @include desktop {
            padding: 44px 0 47px 36px;
        }
    }
    
    .splide {
        &__arrows {
            position: absolute;
            top: 512px;
            right: 25px;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__arrow {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            transform: translateY(0);
            background-color: transparent;
        }
    }

    .annex-discover-card {
        margin-bottom: 0;
        padding-bottom: 113px;
    }

    &__no-items {
        padding-left: 24px;
        padding-bottom: 37px;;

        @include desktop {
            padding-left: 36px;
        }
    }

    &__no-items-text {
        color: $black;
        font-family: $font-gt-walsheimregular;
        font-size: 18px;
        letter-spacing: 0.07px;
        line-height: 24px;
    }

    @include tablet {}

    @include desktop {
        .splide {
            &__arrows {
                top: -54px;
                right: 48px;
            }
        }

        .annex-discover-card {
            padding-bottom: 56.5px;
        }
    }
}
