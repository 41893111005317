@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.room-amenities {
    position: relative;
    
    padding: 102px 0 0;

    background-color: $black;

    @include desktop {
        height: 1395px;
        padding: 181px 0 0 71px;
    }

    // &__image-container {
    //     overflow: hidden;

    // }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease;

        // &:hover {
        //     z-index: 2;
        //     // transform: scale(1.1);
        //     // transition: transform 0.5s ease;
        // }
    }

    &__image-1 {
        position: absolute;
        top: 329px;
        // left: -50px;
        left: -12.820513%;

        // width: 284px;
        width: 72.820513%;
        height: 311px;
        // object-fit: cover;
        overflow: hidden;

        &:hover {
            z-index: 2;
        }

        &:hover .room-amenities__image {
            transform: scale(1.05);
            // transition: transform 0.5s ease;
        }

        @include desktop {
            top: 499px;
            // left: 94px;
            left: 6.527778%;

            width: 32.569444%;
            height: 514px;
        }
    }

    &__image-2 {
        position: absolute;
        top: 476px;
        left: 35.384615%;
        z-index: 1;

        // width: 309px;
        width: 79.230769%; 
        height: 249px;
        // object-fit: cover;
        overflow: hidden;

        &:hover {
            z-index: 2;
        }

        &:hover .room-amenities__image {
            transform: scale(1.05);
            // transition: transform 0.5s ease;
        }

        @include desktop {
            top: 816px;
            left: 32.986111%;

            width: 38.333333%;
            height: 445px;
        }
    }

    &__image-3 {
        position: absolute;
        overflow: hidden;

        &:hover {
            z-index: 2;
        }

        &:hover .room-amenities__image {
            transform: scale(1.05);
            // transition: transform 0.5s ease;
        }

        @include desktop {
            top: 160px;
            // right: 73px;
            right: 5.069444%;

            width: 55.833333%;
            height: 514px;
            // object-fit: cover;
        }
    }
}

.see-amenities {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // width: 100%;
    height: 250px;
    margin-bottom: 364px;
    padding: 58px 24px 62px;

    background-color: $accent-dark;

    @include desktop {
        width: 50.902778%;
        height: 277px;
        margin-bottom: 0;
        // padding: 58px 57.5px 79px;
        padding: 38px 20.5px;
    }

    &__text {
        max-width: 385px;
        margin-bottom: 34px;
        
        font-family: $font-gt-walsheimregular;
        font-size: 1.5625rem;
        line-height: 1.875rem;
        letter-spacing: 0.035rem;
        color: $white;

        @include tablet {
            max-width: 500px;
            font-size: 2.03125rem;
            line-height: 2.28125rem;
            letter-spacing: 0.0409375rem;
        }

        @include desktop {
            width: 70%;
            max-width: 452px;
            margin-bottom: 28px;

            font-size: 2.5rem;
            line-height: 2.6875rem;
            letter-spacing: 0.046875rem;
        }
    }
}
.considerations {
    position: relative;
    // top: -196px;
    // top: 364px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // width: 100%;
    height: 267px;
    padding: 66px 24px 69px;

    background-color: $white;

    @include desktop {
        position: absolute;
        top: 547px;
        // right: 136px;
        right: 9.444444%;

        width: 25%;
        height: 418px;
        padding: 38px 20.5px 240px;
    }

    &__text {
        width: 240px;
        margin-bottom: 36px;
        
        font-family: $font-gt-walsheimregular;
        // line-height: 2.1875rem;
        // letter-spacing: 0.035rem;
        font-size: 1.5625rem;
        line-height: 1.875rem;
        letter-spacing: 0.035rem;
        color: $black;

        @include tablet {
            font-size: 2.03125rem;
            line-height: 2.28125rem;
            letter-spacing: 0.0409375rem;
        }

        @include desktop {
            margin-bottom: 28px;

            font-size: 2.5rem;
            line-height: 2.6875rem;
            letter-spacing: 0.046875rem;
        }
    }
}
.hide-modal {
    display: none;
}