@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.NotFoundPage {
  flex-direction:column;
  display: flex;
  justify-content:center;
  align-items: center;
  padding-top:100px;
  padding-left:30px;
  padding-right:30px;
  line-height:60px;

  button{
    cursor:pointer;
  }

  &__image{
    max-width:400px;
  }
  &__title{
    font-size:2rem;
    font-family: $font-gt-sectra-displayregular;
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: $white;
  }
  &__title2 {
    color:$accent-dark;
    font-size:4rem;
    font-family: $font-gt-sectra-displayregular;
  }

  &__title3{
    color:$black;
    font-size:2rem;
    font-family: $font-gt-sectra-displayregular;
    text-align:center;
  }
  @include desktop {
  }
}