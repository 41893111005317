@font-face {
  font-family: 'gt_sectra_finebook_italic';
  src: url('../assets/fonts/gt-sectra-fine-book-italic-webfont.woff2') format('woff2'),
    url('../assets/fonts/gt-sectra-fine-book-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt_sectra_finebook';
  src: url('../assets/fonts/gt-sectra-fine-book-webfont.woff2') format('woff2'),
    url('../assets/fonts/gt-sectra-fine-book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt_sectra_displayregular';
  src: url('../assets/fonts/gt-sectra-display-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/gt-sectra-display-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt_walsheimregular';
  src: url('../assets/fonts/gt-walsheim-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/gt-walsheim-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt_walsheimbold';
  src: url('../assets/fonts/gt-walsheim-bold-webfont.woff2') format('woff2'),
       url('../assets/fonts/gt-walsheim-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}