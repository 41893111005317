@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.annex-contact-wrapper {
  background-color: $black;
  padding: 48px 24px 38px;
  display: flex;
  flex-direction: column;

  &__wrapper-container {
    width: 100%;
  }

  &__image-container {
    width: 100%;
    padding: 5%;
  }

  &__info {
    padding: 40px 50px;
    background-color: $black;
    width: 100%;
  }

  &__desc-text {
    color: $white;
    font-family: $font-gt-walsheimregular;
    font-size: 30px;
    letter-spacing: 0.15px;
    line-height: 35px;
    margin-bottom: 20px;
    max-width: 650px;
    width: 100%;
  }

  &__contact-heading {
    color: $white;
    font-family: $font-gt-walsheimregular;
    font-size: 30px;
    letter-spacing: 0.15px;
    line-height: 45px;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  &__contact-heading-text {
    margin-bottom: 18px;
    color: $white;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.07px;
    & a{
      color:inherit;
      text-decoration: none;
      cursor: pointer;
    }
  }
  &__got-questionsBtn{
    height: 0;
    position: relative;
    left:0;
    top: -50px;
    padding: 5px 0;
    @include tablet {
      padding: 0;
      display: inline-block;
      position: relative;
      top: 0;
      left: -60px!important;
    }
    @include desktop{
      padding: 0;
      top: 5px;
      left: -80px!important;
    }
  }

  @include tablet {
    min-height: 100vh;
  }
  @include desktop {
    flex-direction: row-reverse;

    &__desc-text {
      margin-top: 100px;
      font-size: 40px;
      padding: 20px 0;
      line-height: 45px;
    }
  }
}
