@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.footer-container {
  padding: 64px 83px 85px 81px;
  width: 100%;

  background-color: $white;
}
.footer {
  width: 100%;

  background-color: $white;

  &__icon {
    @include desktop {
      width: 45px;
      height: 43px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
.footer-links-container {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }
  @include desktop {
    padding-top: 48px;
  }
}
.footer-links {
  display: flex;
  flex-direction: column;
  width: 158px;

  @include desktop {
    margin-right: 58px;
  }

  &__text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.003125rem;
    text-decoration: none;
    color: $black;
    font-family: $font-gt-walsheimregular;

    &:hover {
      text-decoration: underline;
    }
  }
}
.footer-contact {
  &__text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.003125rem;
    color: $black;
    font-family: $font-gt-walsheimregular;
  }
}
.subscribe {
  width: 318px;

  &__title {
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.25rem;
  }

  &__text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 29px;
    letter-spacing: 0.003125rem;
    font-family: $font-gt-walsheimregular;
  }

  &__button {
    margin-bottom: 27px;
    font-family: $font-gt-walsheimregular;
  }
}
.p-policy-link {
  color: #0077ff;
}
.hide-footer {
  display: none;
}
