@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.annex-location-map {
  display: flex;
  flex-direction: column;
  margin-bottom: 83px;

  &__map-container {
    width: 100%;
    // height: 493px;
  }

  &__map {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #myMap,
  #bookingTwoColumnLayoutMap {
    width: 100%;
    height: 493px;
  }

  &__info {
    position: relative;
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 40px;
    
    background-color: $wildSand;
  }

  &__icon {
    position: absolute;
    top: 30px;
    right: 36px;
  }

  &__location-text {
    margin-top: 0;
    margin-bottom: 33px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 30px;
    line-height: 70px;
    letter-spacing: 0.11px;
  }

  &__location-area-text {
    margin-top: 0;
    margin-bottom: 18px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }

  &__location-address-text {
    margin-top: 0;
    margin-bottom: 33px;
    
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.06px;
  }

  &__Temerature-text {
    margin-top: 65px;
    margin-bottom: 0;
    
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.06px;
  }

  &__Temerature-celsius-fahrenheit-text {
    margin-top: 0;
    margin-bottom: 0;
    
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 40px;
    line-height: 70px;
    letter-spacing: 0.15px;
  }

  @include desktop {
    flex-direction: row;
    margin-bottom: 101px;

    &__map-container {
      height: initial;
    }

    #myMap,
    #bookingTwoColumnLayoutMap {
      height: 100%;
    }

    &__info {
      width: 50%;
      padding-top: 108px;
      padding-bottom: 118px;
      
    }

    &__icon {
      display: none !important;
    }

    &__location-text {
      font-size: 40px;
    }
  }
}
