@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.events-container {
  position: relative;
  padding: 0 0;

  @include desktop {
    padding: 0 36px;
  }

  &__header {
    margin-top: 103px;
    margin-left: 26px;
    margin-bottom: 62px;
    
    font-family: $font-gt-walsheimregular;
    font-size: 30px;
    letter-spacing: 0.11px;
    line-height: 70px;

    @include tablet {
      margin-top: 170px;
    }

    @include desktop {
      margin-top: 158px;
      margin-bottom: 25px;
      margin-left: 0;

      font-size: 40px;
      letter-spacing: 0.15px;
    }
  }
}

.events-neighbourhood-container {
  position: relative;

  display: flex;
  justify-content: space-between;
  
  padding-left: 26px;
  padding-right: 26px;

  @include desktop {
    padding-left: 36px;
    padding-right: 36px;
    margin-bottom: 75px;
  }

  &__header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__header {
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 30px;
    letter-spacing: 0.11px;
    // line-height: 70px;
    height: 70px;

    @include desktop {
      font-size: 40px;
      letter-spacing: 0.15px;
      // height: 70px;
    }
  }

  &__filter-container {
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop {
      margin-top: 100px;
    }

    .button {
      width: 120px;
      height: 120px;
      padding: 20px;
      border-radius: 100px;
      border: solid 1px #000000;
      white-space: break-spaces;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.events-action-banner {
  margin-top: 69px;
  margin-bottom: 46px;

  @include desktop {
    margin-top: 76px;
    margin-bottom: 67px;
  }
}

.events-got-questions {
  padding: 0 24px;

  @include tablet {
    padding: 0;
  }
}

// events filter start
.events-filter {
  width: 100%;
  max-width: 352px;
  // max-height: 0;

  // opacity: 0;
  // overflow: hidden;
  // transition: opacity 0.1s ease;
  // transition: all 0.5s ease;

  @include desktop {
    // flex-direction: row;
    // width: fit-content;
    // max-width: 904px;
    // max-height: 1000px;
    // padding: 0;

    // overflow: visible;
    // opacity: 1;
    // transition: none;
  }

  // &--mobile-active {
  //   max-height: 1000px;
  //   opacity: 1;
  //   transition: opacity 0.5s ease;
  //   transition: all 0.5s ease;
  // }

  &__type {
    margin-right: 10px;
  }
}

.accordion {
  position: relative;
  display: flex;
  flex-direction: column;

  background-color: transparent;

  @include desktop {
    background-color: rgb(255, 255, 255, 0.9);
  }

  &--budget,
  &--type {
    min-width: 100%;
    border: 1px solid $black;

    @include desktop {
      min-width: 12.9375rem;
      border: 1px solid $black;
      border-bottom: none;
    }
  }
}

.accordion-header-check-availability {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;

  &__text {
    padding-right: 99px;
    margin: 0;

    font-family: $font-gt-sectra-displayregular;
    font-size: 1.375rem;
    line-height: 1.75rem;
    letter-spacing: -0.000625rem;
  }
}

.accordion-dropdown {
  display: flex;
  flex-direction: column;
  top: 73px;

  width: 100%;
  max-height: 0;

  background-color: transparent;

  transition: max-height 0.5s ease;
  overflow: hidden;

  @include desktop {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
  }

  &__text {
    padding: 12px 18px 16px 16.5px;

    font-family: $font-gt-walsheimregular;
    font-size: 1.375rem;
    color: $black;

    background-color: transparent;

    &:hover {
      color: $white;

      background-color: rgb(0, 76, 69, 0.9);
    }

    &:focus {
      outline: none;
    }
  }

  &--budget,
  &--type {
    width: 100%;
    border: 1px solid $black;

    @include desktop {
      min-width: 12.9375rem;
      border: 1px solid $black;
      border-top: none;
      margin-left: -1px;
    }
  }
}

.no-upcoming-events {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 89px 24px 87px;
  margin: 24px 24px 0px;

  background-color: $white;

  border: 1px solid $black;

  @include desktop {
    padding: 124px 36px 119px;
    margin: 36px 36px 0px;
  }

  &__title {
    margin-bottom: 30px;

    font-family: $font-gt-walsheimregular;
    font-size: 1.875rem;
    line-height: 2.5rem;
    color: $black;
    text-align: center;

    @include desktop {
      margin-bottom: 17px;
      font-size: 2.5rem;
      line-height: 4.375rem;
    }
  }

  &__text {
    margin: 0px 36px 55px;

    font-family: $font-gt-walsheimregular;
    font-size: 1rem;
    line-height: 1.375rem;
    color: $black;
    text-align: center;

    @include desktop {
      margin: 0px 0px 71px;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
// events filter end

.events {
  position: relative;
}
