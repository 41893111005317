@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.instagram-carousel-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 0 24px;
  margin-bottom: 69px;

  @include desktop {
    // padding: 0 36px;
    // margin-bottom: 130px;
    margin-bottom: 24px;
  }
}

.instagram-carousel {
  position: relative;
  width: 100%;

  @include desktop {
  }

  &__image {
    // position: relative;
    width: 100%;
    min-height: 613px;
    // height: 179.24vw;
    object-fit: cover;

    @include desktop {
    }
  }

  .annex-discover-card {
    margin-bottom: 0;
  }
}
.instagram-carousel-item-wrapper {
  padding: 0 24px;

  @include desktop {
    padding: 0;
  }
}
.carousel-inner {
  overflow: visible;
  overflow-x: clip;
}
.instagram-carousel-item {
  width: 100%;

  cursor: pointer;

  @include desktop {
    // position: relative;
    // display: flex;
  }
}
.instagram-discover-cards {
  display: flex;
  justify-content: center;
}

.carousel-control-next,
.carousel-control-prev {
  width: 25px !important;
}

.carousel-control-prev {
  left: calc(100% - 55px) !important;
}

.button-next-icon-instagram {
  position: relative;
  width: 100%;
}

.button-next-instagram,
.button-prev-instagram {
  position: absolute;
  //   top: 520px;
  bottom: 10px;
  right: 24px;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  @include desktop {
    top: -45px;
    right: 48px;
  }
}

.button-prev-icon-instagram {
  width: 100%;
}
