@use "../../../partials/mixins" as *;
@use "../../../partials/variables" as *;
@use "../../../partials/typography" as *;

.errors-forgot-password {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  &--spacing {
    justify-content: space-between;
  }
}
.forgot-password {
  justify-self: flex-end;
  margin: 10px 30px 0px 0px;

  color: $black;
  background-color: transparent;
  font-family: $font-gt-walsheimregular;
  font-size: 14px;
  letter-spacing: -0.01px;
  line-height: 16px;
  cursor: pointer;
  white-space: nowrap;
}
.login-button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.login-button {
  margin-right: 24px;
}
.sign-in-google-container {
  padding-top: 33px;
}
.sign-in-google {
  background-image: url("../../../assets/icons/google-sign-in-buttons/btn_google_signin_light_normal_web@3x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  //   height: 46px;
  //   width: 191px;
  height: 38px;
  width: 157.782609px;

  cursor: pointer;

  transition: background-image 0.3s ease;

  &:hover {
    background-image: url("../../../assets/icons/google-sign-in-buttons/btn_google_signin_light_pressed_web@2x.png");

    transition: background-image 0.3s ease;
  }

  &:focus {
    background-image: url("../../../assets/icons/google-sign-in-buttons/btn_google_signin_light_focus_web@2x.png");
  }

  @include tablet {
    height: 44px;
    width: 182.695652px;
  }

  @include desktop {
    height: 50px;
    width: 207.608696px;
  }
}
