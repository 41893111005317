@use "../../../partials/mixins" as *;
@use "../../../partials/variables" as *;
@use "../../../partials/typography" as *;

.button-arrow-link {
  text-decoration: none;
  display: inline-block;
}

.button-arrow {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  padding-bottom: 5px;
  background-color: transparent;
  font-family: $font-gt-walsheimregular;
  cursor: pointer;

  &--main {
    color: $black;
    border-bottom: 1px solid $black;
    display: inline-block;
    text-decoration: none;

    // &:hover {
    //   color: $white;
    //   border-bottom: 1px solid $white;
    // }
    &:hover .button-arrow__icon--main {
      // color: $white;
      margin-left: 20px;

      transition: all 0.5s ease-in-out;
    }
  }

  &--inverse {
    color: $white;
    border-bottom: 1px solid $white;

    // &:hover {
    //   color: $black;
    //   border-bottom: 1px solid $black;
    // }
    &:hover .button-arrow__icon--inverse {
      // color: $black;
      margin-left: 20px;

      transition: all 0.5s ease-in-out;
    }
  }

  &__icon {
    width: 20px;
    height: 15px;
    margin-left: 13.5px;

    transition: all 0.5s ease-in-out;

    &--main {
      color: $black;
    }

    &--inverse {
      color: $white;
    }
  }
}
