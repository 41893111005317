@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.annex-discover-card-carousel-container {
    position: relative;
    background-color: $white;
}
.annex-discover-card-carousel-title {
    font-family: $font-gt-walsheimregular;
    line-height: 4.375rem;
    padding: 46px 0 37px 24px;

    @include desktop {
        padding: 44px 0 47px 36px;
    }
}
.adc-carousel {
    width: 100%;
}

.annex-discover-card-list-desktop {
    display: flex;
    flex-direction: row;
    width: 100%; 
}
.annex-discover-card-desktop {
    width: 25%;
}
.button-next-adcc {
    position: absolute;
    bottom: 383px;
    right: 24px;
    height: 15px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;

    @include desktop {
        right: 36px;
        top: -52px;
    }
  }
  
  .button-next-icon-adcc {
      position: relative;
    width: 100%;
  }
  
  .button-prev-adcc {
    position: absolute;
    bottom: 383px;
    right: 24px;
    height: 15px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop {
        right: 36px;
        top: -52px;
    }

  }

  .button-prev-icon-adcc {
    width: 100%;
  }