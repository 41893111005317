@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.booking-room-info {
  &__image {
    display: flex;
    object-fit: cover;
    width: 100%;

    @include tablet {
      height: 464px;
    }

    @include desktop {
      height: 732px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 15px;
    background-color: $wildSand;

    @include tablet {
      padding: 30px 15px;
    }
  }

  &__info {
    margin-left: 15px;
    margin-right: 15px;

    @include tablet {
      align-items: space-between;

      &:last-child {
        padding-top: 9.5px;
      }
    }

    @include desktop {

      &:last-child {
        padding-top: 0px;
      }
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    width: 80%;

    color: $black;
    font-family:$font-gt-walsheimbold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.07px;
  }

  &__icon-text-container {
    display: flex;
    align-items: space-between;

    @include tablet {
      flex-wrap: wrap;
    }

    @include desktop {
      flex-wrap: nowrap;
    }

  }

  &__icon {
    width: auto;

    &--bed {
      height: 8px;
    }

    &--room {
      height: 5px;
    }

    &--guest {
      height: 12px;
    }

    &--container {
      height: 12px;
    }

    @include tablet {
      width: auto;

      &--bed {
        height: 11px;
      }
  
      &--room {
        height: 7px;
      }
  
      &--guest {
        height: 16.5px;
      }

      &--container {
        height: 16.5px;
      }
    }

    @include desktop {
      width: auto;

      &--bed {
        height: 14px;
      }
  
      &--room {
        height: 9px;
      }
  
      &--guest {
        height: 21px;
      }

      &--container {
        height: 21px;
      }
    }
  }

  &__text {
    margin-top: 9px;
    margin-bottom: 0;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.06px;

    @include desktop {
      margin-top: 17px;
      white-space: nowrap;

    }
  }
}
