@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.event-booking-modal-container {
    position: fixed;
    z-index: 21;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;

    padding: 75px 24px;

    background-color: rgba(255,255,255,0.88);

    @include desktop {
        // height: 1029px;
        padding: 93px 36px;
    }
}
.event-booking-modal-close {
    position: absolute;
    top: 27.38px;
    right: 24px;
    cursor: pointer;
}
.event-booking-modal-inner-container {
    width: 100%;
    
    //background-image: url('../../assets/images/event-booking-modal/bg-mobile.png');
    background-color:$black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @include tablet {
        display: flex;
        justify-content: center;
        
        max-width: 916px;
    }

    @include desktop {
        // width: 1368px;
        width: 1294px;
        max-width: 1294px;
    }
}
.event-booking-modal {
    position: relative;
    width: 100%;
    // height: 100vh;
    padding: 20px 15px;

    // background-image: url('../../assets/images/event-booking-modal/bg-mobile.png');
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;

    @include tablet {
        display: flex;
        justify-content: space-between;

        // height: 843px;
        // width: 916px;
        // max-width: 1576px;
        padding: 20px 36px 20px 15px;
    }

    @include desktop {
        display: flex;
        justify-content: space-between;

        // height: 843px;
        // width: 1576px;
        // max-width: 1576px;//remove or change to another value
        padding: 20px 105px 20px 36px;
    }
}
.event-booking-modal-left {
    width: 100%;
    max-width: 352px; //is this right?

    @include tablet {
        max-width: 481px;
        margin-right: 32px;
    }

    @include desktop {
        max-width: 610px;
        padding-top: 41px;
        margin-right: 56px;
    }

    &__title-container {
        width: 100%;
        // padding-left: 11px;
        margin-bottom: 10px;

        // @include tablet {
        //     width: 100%;
        // }

        @include desktop {
            // width: 583px;
            // padding-left: 5px;
            margin-bottom: 50px;
        }

    }

    &__title-top, 
    &__title-bottom {
        font-family: $font-gt-walsheimregular;
        font-size: 3.11125rem !important;
        line-height: 3.46875rem;
        letter-spacing: 0.01125rem;
        color: $white;

        @include tablet {
            // font-size: 4.5rem !important;
            font-size: 3.8rem !important;
            // line-height: 5.21875rem;
            line-height: 4.5rem;
            letter-spacing: 0.0184375rem;
        }

        @include desktop {
            // font-size: 6.875rem !important;
            //font-size: 4.6875rem !important;
            // line-height: 6.96875rem;
            line-height: 5.3125rem;
            letter-spacing: 0.025625rem;
        }
    }

    &__title-top {
        color: $white;
        text-align: left;    
    }

    &__title-bottom {
        text-align: right;
    }
}
.event-booking-modal-right {
    width: 100%;
    max-width: 352px;

    @include tablet {
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    @include desktop {
        // height: 843px;
        max-width: 769px;
        padding: 34px 0px;

        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;


        &::-webkit-scrollbar {
            display: none;
        }
            
        
    }
}
.learn-about {
    padding-left: 11px;
    margin-bottom: 10px;

    font-family: $font-gt-walsheimregular;
    font-size: 1.25rem;
    line-height: 2.8125rem;
    letter-spacing: 0.004375rem;
    color: $white;
}
.our-spaces-mobile {

    @include tablet {
        display: none !important;
    }

    &:focus-within {
        border: none !important;
    }
}
.our-spaces {
    display: none;

    @include tablet {
        display: flex;
        flex-direction: column;
    }


    &__title {

        @include tablet {
            padding: 12px 18px 0px 27px;
        
            font-family: $font-gt-walsheimregular;
            font-size: 30px;
            line-height: 33px;
            letter-spacing: -0.01px;
            color: $white;
        }
        @include desktop {
            padding: 0;
            margin-bottom: 15px;

            font-size: 2.5rem;
            line-height: 2.8125rem;
            letter-spacing: 0.009375rem;
            color: $white;
        }

    }

    &__body {

        @include tablet {
            padding: 12px 18px 16px 27px;
        
            font-family: $font-gt-walsheimregular;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: -0.01px;
            color: $white;
        }
        @include desktop {
            padding: 0;
            margin-bottom: 35px;

            font-family: $font-gt-walsheimregular;
            font-size: 1.25rem;
            line-height: 1.4375rem;
            letter-spacing: 0.004375rem;
            color: $white;
        }

    }
}
.our-spaces-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    height: 45px;
    // padding: 15px 27px;
    padding-left: 11px;
    padding-right: 11px;
    // padding-bottom: 5px;

    border: none;

    overflow: hidden;
    // visibility: hidden;
    // opacity: 0;
    transition: all 0.5s ease;

    @include desktop {
        // max-height: 1000px;
        padding: 12px 18px 16px 16.5px;
        
        // visibility: visible;
        overflow: visible;
        // opacity: 1;
    }

    &__text {
        font-family: $font-gt-walsheimregular;
        font-size: 20px;
        // line-height: 45px;
        // height: 45px;
        letter-spacing: 0.07px;
        color: $white;
        
    }
}
.our-spaces-header-dropdown {
    display: flex;
    flex-direction: column;
    top: 45px;

    width: 100%;
    max-height: 0;
    // padding-bottom: 5px;

    // border: 1px solid $white;
    // border-top: none;
    background-color: transparent;

    transition: max-height 0.5s ease;
    overflow: hidden;

    @include desktop {
        position: absolute;

        background-color: rgba(255, 255, 255, 0.9);
    }

    &__inner-container {
        padding-bottom: 5px;
    }

    &__heading-text {
        padding: 12px 18px 0px 27px;
        
        font-family: $font-gt-walsheimregular;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.01px;
        color: $white;
    }

    &__text {
        padding: 12px 18px 16px 27px;
        
        font-family: $font-gt-walsheimregular;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.01px;
        color: $white;

        background-color: transparent;
        
    }
}
.book-collaborate {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 43px;
    margin: 0 9px;

    // border: 1px solid $white;

    &:hover .book-collaborate__divider {
        display: none;
    }

    @include desktop {
        justify-content: space-between;

        width: 296px;
        // height: 50px;
        margin: 0;

        // border: none;
    }

    &__book,
    &__collaborate  {
        // width: calc(50% - 0.5px);
        width: 50%;
        height: 100%;

        font-family: $font-gt-walsheimregular;
        font-size: 16px;
        color: $white;

        border: 1px solid $white;

        background-color: transparent;
        transition: all 0.3s ease;

        &:hover {
            // width: 50%;
            font-weight: bold;
            
            // color: $black;
            // border: 5px solid $black;
            // background-color: $white;
            transition: all 0.3s ease;
        }
    }

    &__book {
        border-right: none;
        transition: all 0.3s ease;

        &:hover {
            border: 1px solid transparent;
            border-right: none;
            transition: all 0.3s ease;
        }
    }

    &__collaborate {
        border-left: none;
        transition: all 0.3s ease;

        &:hover {
            border: 1px solid transparent;
            border-left: none;
            transition: all 0.3s ease;
        }
    }

    &__divider {
        height: 16px;

        border-left: 1px solid $white;
    }

}
.button-active {
    font-weight: bold;
    color: $black;
    background-color: $white;

}
.form-title {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 15px;

    font-family: $font-gt-walsheimregular;
    font-size: 1.25rem;
    line-height: 1.4375rem;
    letter-spacing: 0.004375rem;
    color: $white;

    @include tablet {
        margin-top: 29px;
        margin-bottom: 17px;

        font-size: 1.875rem;
    }

    @include desktop {
        margin-top: 34px;
        margin-bottom: 19px;

        font-size: 2.5rem;
        line-height: 2.875rem;
        letter-spacing: 0.009375rem;
    }
}
.form-two-columns-inputs-container {
    width: 100%;

    @include desktop {
        display: flex;
        justify-content: space-between;
    }
    
}
.your-details-form-input {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 74px;
    padding: 15px 27px;
    margin-bottom: 10px;

    border: 1px solid $white;
    border-radius: 10px;

    @include desktop {
        margin-bottom: 20px;
    }

    &:focus-within {
        border: 2px solid $white;
    }

    // &--name {
    //     @include desktop {
    //         width: calc(50% - 10.5px);
    //     }
    // }

    &--text-area {
        height: 195px;
    }

    &--text-area-collaborate {
        height: 126px;
    }

    &__label {
        font-family: $font-gt-walsheimregular;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.01px;
        color: $white;

        &--error {
            color: red;
        }
    }

    &__input {
        height: 100%;

        font-family: $font-gt-walsheimregular;
        font-size: 22px;
        line-height: 25px;
        letter-spacing: -0.01px;
        color: $white;

        caret-color : $white;

        background-color: transparent;

        &--time::-webkit-calendar-picker-indicator {
            display: none;
            background: none;
            border-radius: 100%;
            height: 18px;
            width: 18px
        }

        &:focus {
            outline: none;
        }
    }

    &__input:-webkit-autofill,
    &__input:-webkit-autofill:hover,
    &__input:-webkit-autofill:focus,
    &__input:-webkit-autofill:active {
        -webkit-text-fill-color: $white;
        transition: background-color 5000s ease-in-out 0s;
    }
    &__input:-webkit-autofill::first-line {
        font-family: $font-gt-walsheimregular;
        font-size: 22px;
        line-height: 25px;
        letter-spacing: -0.01px;
    }

    &__text-area {
        height: 100%;

        font-family: $font-gt-walsheimregular;
        font-size: 22px;
        line-height: 25px;
        letter-spacing: -0.01px;
        color: $white;

        background-color: transparent;

        resize: none;

        &:focus {
            outline: none;
        }
    }
}
.form-input-half-width {

    @include desktop {
        // min-width: 282.25px;
        width: calc(50% - 10.5px);
    }
}
.date-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.date-input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.event-calendar-icon {
    width: 26px;
    height: 22px;
    color: $white;
}
.event-details-submit {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    // margin-bottom: 60px;

    // padding-bottom: 105px;

    width: 100%;

    &__button {

    }
}
.event-details-captcha {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 20px;
}
.form-error-message {
    margin-bottom: 20px;
    padding: 5px 0 5px 28px;
    font-size: 0.875rem;
    color: $form-red;
}
.form-success-message {
    margin: 20px 0;
    padding: 5px 10px;
    font-size: 0.875rem;
    color: $form-green;
    background-color: $white;
    border-radius: 30px;
}
.disabled-btn {
    background-color: rgba(255, 255, 255, 0.5);

    &:hover {
        color: $black;
        background-color: rgba(255, 255, 255, 0.5);
    }
}
.white-border {
    border: 1px solid $white;
    border-radius: 10px;
}
.focus-accordion {
    border: 2px solid $white;
}
.event-form-success {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 47px 28px 42px 35px;
    margin-top: 52px;
    border: 1px solid $white;

    &__title {
        margin-bottom: 15px;

        font-family: $font-gt-walsheimregular;

        font-size: 28px;
        line-height: 28px;
        letter-spacing: -0.01px;
        color: $white;

        @include tablet {
            font-size: 30px;
            line-height: 30px;
        }

        @include desktop {
            font-size: 2.5rem;
            line-height: 2.875rem;
            letter-spacing: 0.009375rem;
        }
    }

    &__text {
        max-width: 510px;
        margin-bottom: 37px;

        font-family: $font-gt-walsheimregular;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.01px;
        color: $white;

        @include tablet {
            font-size: 18px;
            line-height: 21px;
        }

        @include desktop {
            font-size: 1.25rem;
            line-height: 1.4375rem;
            letter-spacing: 0.004375rem;
        }
    }

    &__button-container {
        display: flex;
        justify-content: flex-end;
    }
}