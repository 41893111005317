@use "../../../partials/mixins" as *;
@use "../../../partials/variables" as *;
@use "../../../partials/typography" as *;

.text-link {
  font-size: 0.875rem;
  color: $black;
  text-decoration: underline;
  font-family: $font-gt-walsheimregular;
  letter-spacing: 0.034375rem;

  transition: all 0.3s ease;

  &:hover {
    // color: $white;
    // background-color: rgb(0, 0, 0, 0.35);
    font-weight: bold;
    color: $accent-dark;

    transition: all 0.3s ease;
  }
}
