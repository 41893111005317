@use '../../../partials/mixins' as *;
@use '../../../partials/variables' as *;
@use '../../../partials/typography' as *;

.annex-tabs {
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #D8D8D8;
  
  &--mobile-only {}

  &--desktop-only {
    display: none;
  }

  &__tab {
    position: relative;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 18px;
    line-height: 63px;
    letter-spacing: 0.07px;

    background-color: transparent;
    cursor: pointer;

    &:hover {
      &::after {
        opacity: 1 !important;
      }
    }

    // &:focus {
    //   outline: transparent !important;
    // }

    &--active {
      &::after {
        opacity: 1 !important;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      width: 100%;
      background-color: $black;
      opacity: 0;
      transition: all 300ms ease;
    }

    &:nth-child(2) {
      margin-left: 53px;
      margin-right: 53px;
    }
  }
  
  @include desktop() {
    padding-left: 0px;
    padding-right: 0px;

    &--mobile-only {
      display: none;
    }

    &--desktop-only {
      display: block;
    }
  }
}

.annex-tab-content {
  padding-left: 21px;
  padding-right: 21px;

  @include desktop() {
    padding-top: 23px;
    padding-left: 0px;
    padding-right: 0px;
  }
}