@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.join-mailing-list-modal-container {
    position: fixed;
    z-index: 21;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;

    width: 100%;
    // height: 100%;
    padding: 80.5px 24px 0px;

    // background-color: rgba(255,255,255,0.88);
    background-color: rgba(255,255,255,0.95);

    @include desktop {
        padding: 80px 36px 0px;
    }
}
.join-mailing-list-modal-close {
    position: absolute;
    top: 27.38px;
    right: 24px;
    cursor: pointer;
}
// .join-mailing-list {
//     display: flex;
//     flex-direction: column;
//     align-items: center;


//     &__title {
//         color: $black;
//         font-family: $font-gt-walsheimregular;
//         width: 80%;
//         max-width: 603px;
//         text-align: center;
//         margin-bottom: 30px;
//         line-height: 2.5rem;

//         @include desktop {
//             max-width: 800px;
//             line-height: 4.375rem;
//             margin-bottom: 17px;
//         }
//     }

//     &__body {
//         color: $black;
//         font-family: $font-gt-walsheimregular;
//         text-align: center;
//         max-width: 24.875rem;
//         margin: 0 36px 33px;
//         line-height: 1.375rem;

//     }
// }
// .input-button-spacing {
//     margin-bottom: 33px;
// }
// .join-mailing-list-enter-email {
//     width: 100%;
//     max-width: 670px;

//     &__button {
//         display: flex;
//         justify-content: flex-end;

//         &--disabled {
//             background-color: $accent-light;
//         }

//     }

// }
