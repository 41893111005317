@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.booking-room {
  &__image-container {
    position: relative;
    display: flex;
    // height: 100%;
    // height: 165px;
    height: 42.307692vw;
    max-height: 330px;
    width: 100%;

    @include desktop {
      height: 330px;

    }
  }

  // &__image {
  //   width: 100%;
  //   object-fit: cover;
  // }

  &__title {
    position: absolute;
    left: 21px;
    bottom: -15px;

    margin-top: 0;
    margin-bottom: 0;

    color: $black;
    font-family: $font-gt-sectra-displayregular;
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 0.13px;

    @include tablet {
      // left: 33px;
      bottom: -22px;

      padding-right: 22px;

      font-size: 52.5px;
      line-height: 52.5px;
      letter-spacing: 0.195px;
    }

    @include desktop {
      left: 45px;
      bottom: -25px;

      padding-right: 0px;

      font-size: 70px;
      line-height: 70px;
      letter-spacing: 0.26px;
    }
  }

  &__info {
    padding-top: 32px;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 22px;
    background-color: $wildSand;

    @include desktop {
      padding-top: 65px;
      padding-left: 45px;
      padding-right: 45px;
      padding-bottom: 40px;
    }
  }

  &__total-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__total {
    margin-top: 0;
    margin-bottom: 0;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    letter-spacing: 0.07px;
    line-height: 20px;

    @include tablet {
      font-size: 30px;
      letter-spacing: 0.11px;
      line-height: 30px;  
    }

    @include desktop {
      font-size: 40px;
      letter-spacing: 0.15px;
      line-height: 40px;  
    }
  }

  &__button {
    padding: 0px 20px;
    height: 30px;
    font-size: 8.8px;
    line-height: 10px;
    letter-spacing: 0.13px;
    border: 1px solid $black;

    @include tablet {
      padding: 0px 28px;
      height: 41px;
      font-size: 12.4px;
      line-height: 14px;
      letter-spacing: 0.18px;
    }

    @include desktop {
      padding: 0px 36px;
      height: 52px;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.23px;
    }
  }

  &__room-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 10px;
    margin-bottom: 50px;

    @include tablet {
      margin-top: 18.5px;
      // margin-bottom: 41.5px;
    }
    
    @include desktop {
      margin-top: 27px;
    }
  }

  &__room-info-column {
    &:first-child {
      margin-left: 0;
    }
    
    margin-left: 26px;

    @include desktop {
      margin-left: 55px;
    }
  }

  &__room-info-icon {
    width: auto;

    &--bed {
      height: 8px;
    }

    &--room {
      height: 5px;
    }

    &--guest {
      height: 12px;
    }

    @include tablet {
      width: auto;

      &--bed {
        height: 11px;
      }
  
      &--room {
        height: 7px;
      }
  
      &--guest {
        height: 16.5px;
      }
    }

    @include desktop {
      width: auto;

      &--bed {
        height: 14px;
      }
  
      &--room {
        height: 9px;
      }
  
      &--guest {
        height: 21px;
      }
    }
  }

  &__room-info-text {
    margin-top: 9px;
    margin-bottom: 0;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 10px;
    letter-spacing: 0.04px;
    line-height: 12.1px;

    @include tablet {
      // margin-top: 13px;
      font-size: 13px;
      letter-spacing: 0.05px;
      line-height: 17px;
    }

    @include desktop {
      margin-top: 17px;
      font-size: 16px;
      letter-spacing: 0.06px;
      line-height: 22px;
    }
  }
}
.button-next-room {
  position: absolute;
  // bottom: -24px;
  top: -33px;
  right: 0px;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  @include tablet {
    right: 12px;
  }

  @include desktop {
    right: 18px;
  }
}

.button-next-icon-room {
    position: relative;
  width: 100%;
}

.button-prev-room {
  position: absolute;
  // bottom: -24px;
  top: -33px;
  right: 0px;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include tablet {
    right: 12px;
  }

  @include desktop {
    right: 18px;
  }

}

.button-prev-icon-room {
  width: 100%;
}
.hero-carousel-room {
  position: relative;
  width: 100%;
  height: 100%;

  // @include desktop {
      
  // }

  // &__image {
  //     // position: relative;
  //     width: 100%;
  //     min-height: 613px;
  //     // height: 179.24vw;
  //     object-fit: cover;

  //     @include desktop {
          

  //     }
  // }
}
.carousel-item-room {
  width: 100%;
  height: 100%;

  @include desktop {
      // position: relative;
  }

  &__image {
    width: 100%;
    // height: 100%;
    // height: 165px;
    height: 42.307692vw;
    max-height: 330px;
    object-fit: cover;

    @include desktop {
      height: 330px;
    }
  }
}