@use "./partials/mixins" as *;
@use "./partials/variables" as *;
@use "./partials/typography" as *;
@use "sass:math";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mobile-only {
  @include desktop {
    display: none !important;
  }
}

.mobile-below-tablet {
  @include tablet {
    display: none !important;
  }
}

.tablet-and-above {
  display: none !important;

  @include tablet {
    display: flex !important;
  }
}

.desktop-only {
  display: none !important;

  @include desktop {
    display: flex !important;
  }
}

h1 {
  font-size: 4.375rem !important;

  @include tablet {
    font-size: 7.5rem !important;
  }

  @include desktop {
    font-size: 8.75rem !important;
  }
}
h2 {
  font-size: 2.5rem;

  @include desktop {
    font-size: 6.25rem;
  }
}

h4 {
  font-size: 1.875rem;

  @include desktop {
    font-size: 2.5rem;
  }
}

p {
  font-size: 1rem;
}

//carousel styles from bootstrap

// Notes on the classes:
//
// 1. .carousel.pointer-event should ideally be pan-y (to allow for users to scroll vertically)
//    even when their scroll action started on a carousel, but for compatibility (with Firefox)
//    we're preventing all actions instead
// 2. The .carousel-item-left and .carousel-item-right is used to indicate where
//    the active slide is heading.
// 3. .active.carousel-item is the current slide.
// 4. .active.carousel-item-left and .active.carousel-item-right is the current
//    slide in its in-transition state. Only one of these occurs at a time.
// 5. .carousel-item-next.carousel-item-left and .carousel-item-prev.carousel-item-right
//    is the upcoming slide in transition.

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: visible;
  @include clearfix();
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  @include transition($carousel-transition);
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

//
// Alternate transitions
//

.carousel-fade {
  .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
  }

  .carousel-item.active,
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
  }

  .active.carousel-item-left,
  .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    @include transition(opacity 0s $carousel-transition-duration);
  }
}

//
// Left/right controls for nav
//

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  // Use flex for alignment (1-3)
  display: flex; // 1. allow flex styles
  align-items: center; // 2. vertically center contents
  justify-content: center; // 3. horizontally center contents
  width: $carousel-control-width;
  color: $carousel-control-color;
  text-align: center;
  opacity: $carousel-control-opacity;
  @include transition($carousel-control-transition);

  // Hover/focus state
  @include hover-focus() {
    color: $carousel-control-color;
    text-decoration: none;
    outline: 0;
    opacity: $carousel-control-hover-opacity;
  }
}
.carousel-control-prev {
  left: 0;
  @if $enable-gradients {
    background-image: linear-gradient(
      90deg,
      rgba($black, 0.25),
      rgba($black, 0.001)
    );
  }
}
.carousel-control-next {
  right: 0;
  @if $enable-gradients {
    background-image: linear-gradient(
      270deg,
      rgba($black, 0.25),
      rgba($black, 0.001)
    );
  }
}

// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: $carousel-control-icon-width;
  height: $carousel-control-icon-width;
  background: 50% / 100% 100% no-repeat;
}
.carousel-control-prev-icon {
  background-image: escape-svg($carousel-control-prev-icon-bg);
}
.carousel-control-next-icon {
  background-image: escape-svg($carousel-control-next-icon-bg);
}

// Optional indicator pips
//
// Add an ordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0; // override <ol> default
  // Use the .carousel-control's width as margin so we don't overlay those
  margin-right: $carousel-control-width;
  margin-left: $carousel-control-width;
  list-style: none;

  li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    text-indent: -999px;
    cursor: pointer;
    background-color: $carousel-indicator-active-bg;
    background-clip: padding-box;
    // Use transparent borders to increase the hit area by 10px on top and bottom.
    border-top: $carousel-indicator-hit-area-height solid transparent;
    border-bottom: $carousel-indicator-hit-area-height solid transparent;
    opacity: 0.5;
    @include transition($carousel-indicator-transition);
  }

  .active {
    opacity: 1;
  }
}

// Optional captions
//
//

.carousel-caption {
  position: absolute;
  // right: (100% - $carousel-caption-width) / 2;
  right: math.div(100% - $carousel-caption-width, 2);
  bottom: 20px;
  // left: (100% - $carousel-caption-width) / 2;
  left: math.div(100% - $carousel-caption-width, 2);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $carousel-caption-color;
  text-align: center;
}

// Auth pages (Create-Login, Create and Login)
.auth-container {
  width: 100%;
  max-width: 684px;
  margin: 129px auto;
  padding-left: 24px;
  padding-right: 24px;

  @include desktop {
    padding-left: 36px;
    padding-right: 36px;
  }

  .button {
    margin-top: 30px;
  }
}

.annex-form-title {
  margin-bottom: 75px;

  color: $black;
  font-family: $font-gt-walsheimregular;
  font-size: 40px;
  letter-spacing: 0.38px;
  line-height: 70px;
  text-align: center;
}

.annex-form {
  &__label {
    margin-bottom: 4px;
    font-family: $font-gt-walsheimregular;
    font-size: 14px;
    letter-spacing: -0.01px;
    line-height: 16px;

    &--error {
      color: $form-red;
    }
  }

  &__input {
    caret-color: $form-green;
    background-color: transparent;
  }

  &__input:-webkit-autofill,
  &__input:-webkit-autofill:hover,
  &__input:-webkit-autofill:focus,
  &__input:-webkit-autofill:active {
    -webkit-text-fill-color: $black;
    transition: background-color 5000s ease-in-out 0s;
  }
  &__input:-webkit-autofill::first-line {
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 18px;
  }

  &__select {
    background-color: transparent;
    color: $black;
    // caret-color: $form-green;
  }

  &__two-column {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @include desktop {
      flex-direction: row;

      .annex-form-group-container {
        padding-right: 5px;

        &:last-child {
          padding-left: 5px;
          padding-right: 0;
        }
      }
    }
  }

  &__error-message {
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;

    color: $form-red;
    font-family: $font-gt-walsheimregular;
    font-size: 14px;
    letter-spacing: -0.01px;
    line-height: 16px;
  }

  &__success-message {
    margin-top: 10px;
    margin-left: 30px;

    color: $form-green;
    font-family: $font-gt-walsheimregular;
    font-size: 14px;
    letter-spacing: -0.01px;
    line-height: 16px;
  }

  &__checkbox {
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    letter-spacing: -0.01px;
    line-height: 23px;
  }

  &__checkbox-input {
    margin-right: 10px;
  }

  &__paragraph {
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    letter-spacing: -0.01px;
    line-height: 23px;

    a {
      color: $black;
    }
  }

  &__button-container {
    // Button {
    //   padding: 0px 36px;
    //   height: 38px;
    //   font-size: 12px;
    //   line-height: 14px;
    //   letter-spacing: 0.17px;
    //   // border: 1px solid $black;

    //   @include tablet {
    //     padding: 0px 42px;
    //     height: 44px;
    //     font-size: 14px;
    //     line-height: 16px;
    //     letter-spacing: 0.2px;
    //   }

    //   @include desktop {
    //     padding: 0px 48px;
    //     height: 50px;
    //     font-size: 16px;
    //     line-height: 18px;
    //     letter-spacing: 0.23px;
    //   }
    // }

    &--text-right {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.annex-form-group-container {
  width: 100%;
  margin-bottom: 24px;
}

.annex-form-group {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  height: 74px;
  padding: 17px 28px;

  border: 1px solid $black;

  input,
  select,
  textarea {
    outline: none;
  }

  &:focus-within {
    // outline: -webkit-focus-ring-color auto 1px;
    // outline-color: -webkit-focus-ring-color;
    // outline-style: auto;
    // outline-width: 1px;
    padding: 16px 27px;
    border: 2px solid $form-green;
    // outline: 1px solid $form-green;
  }
}
// .disabled-btn-black {
//   background-color: rgb(0, 0, 0, 0.25);

//   &:hover {
//       background-color: rgb(0, 0, 0, 0.25);
//       color: $white;
//       border: 1px solid transparent;

//   }
// }

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation: none !important;
    transition: none !important;
  }
}
