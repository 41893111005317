@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.event-accordion {
    position: relative;
    display: flex;
    flex-direction: column;

    // width: 100%; //remove?
    height: fit-content;
    margin-bottom: 10px;
    
    background-color: transparent;

    &:focus-within {
        border: 2px solid $white;
    }

    @include desktop {
        margin-bottom: 20px;
        // background-color: rgb(255, 255, 255, 0.9);
    }
}
.event-accordion-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
    
    height: 74px;
    // max-width: 350px; //is this right?
    padding: 15px 27px;

    // border: 1px solid $white;
    border-bottom: none;

    overflow: hidden;
    // visibility: hidden;
    // opacity: 0;
    transition: all 0.5s ease;

    @include desktop {
        // max-height: 1000px;
        // padding: 12px 18px 16px 16.5px;
        
        // visibility: visible;
        overflow: visible;
        // opacity: 1;
    }

    &--active {
        max-height: 1000px;
        // padding: 12px 18px 16px 16.5px;

        visibility: visible;
        opacity: 1;
        transition: all 0.5s ease;
    }

    &:focus {
        outline: none;
    }


}
.event-accordion-header-selector {
    // display: flex;
    // flex-direction: column;
    // justify-content:flex-start;
    // align-items: center;

    &__title {
        // margin-bottom: 4px;

        font-family: $font-gt-walsheimregular;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.01px;
        color: $white;
    }

    &__text {
        width: 100%;
        margin: 0;

        font-family: $font-gt-walsheimregular;
        font-size: 22px;
        line-height: 25px;
        letter-spacing: -0.01px;
        color: $white;

        background-color: transparent;

        &:focus {
            outline: none;
        }

    }
}
.event-accordion-header-icon {
    display: flex;
    align-items: center;

    height: 100%;

    &__icon {
        width: 15px;
        height: 9px;

        color: $white;

        transition: transform 0.5s ease;
    }
}
.rotate-icon {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
}
// .rotate-chevron {
//     transform: rotate(180deg);
//     transition: transform 0.5s ease;
// }
.event-accordion-dropdown {
    display: flex;
    flex-direction: column;
    top: 74px;

    width: 100%;
    max-height: 0;

    // border: 1px solid $white;
    border-top: none;
    background-color: transparent;

    transition: max-height 0.5s ease;
    overflow: hidden;

    @include desktop {
        // position: absolute;

        // background-color: rgba(255, 255, 255, 0.9);
    }

    &__text {
        padding: 12px 18px 16px 27px;
        
        font-family: $font-gt-walsheimregular;
        font-size: 22px;
        line-height: 25px;
        letter-spacing: -0.01px;
        color: $white;

        background-color: transparent;
        
        &:hover {
            color: $black;

            background-color: $white;

        }

        &:focus {
            outline: none;
        }
        
    }
}
// .event-accordion-dropdown {
//     // position: absolute;
//     bottom: 74px;
//     display: flex;
//     flex-direction: column;
    
//     width: 100%;
//     max-height: 0;

//     background-color: transparent;

//     transition: max-height 0.5s ease;
//     overflow: hidden;

//     @include desktop {
//         position: absolute;
//         bottom: 73px;

//         width: 100%;

//         background-color: rgba(255, 255, 255, 0.9);
//     }
// }

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }




