@use "../../../partials/mixins" as *;
@use "../../../partials/variables" as *;
@use "../../../partials/typography" as *;

.booking-summary-total {
  margin-top: 25px;
  margin-bottom: 8.5px;
  // border-bottom: 1px solid $black;
  height: 530px;

  @include tablet {
    height: 550px;
  }

  @include desktop {
    margin-bottom: 16.5px;
    height: 580px;
  }

  .booking-two-column-layout__title {
    // margin-bottom: 15px;
    margin-bottom: 35px;

    @include tablet {
      margin-bottom: 40px;
      font-size: 30px;
      // letter-spacing: 0.11px;
      line-height: 40px;
    }

    @include desktop {
      margin-bottom: 40px;
      font-size: 40px;
      // letter-spacing: 0.11px;
      line-height: 70px;
    }
  }

  // why i did do this?
  &__row,
  &__row-checkin,
  &__row-total-cost {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    padding-left: 22px;
    margin-bottom: 15px;

    @include tablet {
      // padding-left: 43px;
      margin-bottom: 26px;
    }

    @include desktop {
      padding-left: 43px;
      margin-bottom: 30px;
    }
  }

  &__row {
    width: fit-content;
    cursor: pointer;
  }

  &__column-checkin {
    &:last-child {
      margin-left: 50px;
    }

    @include desktop {
      &:last-child {
        margin-left: 65px;
      }
    }
  }

  &__column-total-cost {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .booking-summary-total__total-cost {
      &:last-child {
        margin-left: 100px;
      }

      @include desktop {
        &:last-child {
          margin-left: 200px;
        }
      }
    }
  }

  &__title {
    color: $black;
    font-size: 12px;
    font-family: $font-gt-walsheimbold;
    letter-spacing: normal;
    // line-height: 15px;
    line-height: 25px;

    @include tablet {
      font-size: 16px;
      // letter-spacing: 0.07px;
      // line-height: 22px;
      line-height: 32px;
    }

    @include desktop {
      font-size: 20px;
      letter-spacing: 0.07px;
      // line-height: 22px;
      line-height: 32px;
    }
  }

  &__text {
    color: $black;
    font-size: 12px;
    font-family: $font-gt-walsheimregular;
    letter-spacing: normal;
    line-height: 15px;

    @include tablet {
      font-size: 15px;
      // letter-spacing: 0.07px;
      line-height: 18.5px;
    }

    @include desktop {
      font-size: 18px;
      letter-spacing: 0.07px;
      line-height: 22px;
    }
  }

  &__total-cost {
    color: $black;
    font-size: 15px;
    font-family: $font-gt-walsheimbold;
    letter-spacing: 0.06px;
    // line-height: 20px;
    line-height: 65px;

    @include tablet {
      font-size: 22.5px;
      letter-spacing: 0.085px;
      // line-height: 35px;
      line-height: 82.5px;
    }

    @include desktop {
      font-size: 30px;
      letter-spacing: 0.11px;
      // line-height: 35px;
      line-height: 100px;
    }
  }
}

.booking-two-column-room {
  .booking-two-column-layout__alt-title {
    margin-bottom: 13px;

    @include desktop {
      margin-bottom: 26px;
    }
  }
}

.booking-selecting-room {
  // height: 1000px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  .booking-room {
    // margin-bottom: 10px;
    margin-top: 51px;

    @include desktop {
      // margin-bottom: 20px;
      margin-top: 51px;
    }
  }
}

.booking-two-column-button {
  margin-bottom: 75px;
  padding-top: 35px;
  border-top: 1px solid $black;

  &__button-container {
    text-align: right;
  }

  &__button {
    display: initial;
    padding: 9px 20px;
    height: 28px;
    font-size: 8.8px;
    line-height: 10px;
    letter-spacing: 0.13px;
  }

  @include tablet {
    margin-bottom: 95px;
    padding-right: 22px;

    &__button {
      padding: 0px 28px;
      height: 41px;
      font-size: 12.4px;
      line-height: 14px;
      letter-spacing: 0.18px;
    }
  }

  @include desktop {
    padding-right: 36px;

    &__button {
      padding: 19px 36px;
      height: 50px;
      font-size: 16px;
      line-height: 14px;
      letter-spacing: 0.23px;
    }
  }
}

.booking-tell-us {
  margin-top: 25px;
  margin-bottom: 60px;

  .booking-two-column-layout__title {
    margin-bottom: 35px;

    @include tablet {
      margin-bottom: 40px;
      font-size: 30px;
      // letter-spacing: 0.11px;
      line-height: 40px;
    }

    @include desktop {
      margin-bottom: 40px;
      font-size: 40px;
      // letter-spacing: 0.11px;
      line-height: 70px;
    }
  }

  &__icons {
    position: relative;
    display: flex;
    margin-top: 10px;
    margin-bottom: 35px;

    @include tablet {
      margin-bottom: 37px;
    }

    @include desktop {
      margin-bottom: 49px;
    }
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 12px;

    height: 40px;
    width: 40px;
    border-radius: 100%;

    background-color: transparent;

    transition: all 0.3s ease;

    cursor: pointer;

    @include desktop {
      margin-left: 28px;

      height: 60px;
      width: 60px;
    }

    &:hover {
      background-color: $accent-dark;

      transition: all 0.3s ease;
    }

    &:hover .booking-tell-us__icon {
      color: $white;

      transition: all 0.3s ease;
    }
  }

  &__icon {
    height: 20px;
    width: 20px;
    // margin-left: 20px;

    color: #004c45;

    @include desktop {
      height: 30px;
      width: 30px;
      // margin-left: 40px;
    }
  }

  &__total-cost {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__total-cost-text {
    color: $black;
    font-size: 15px;
    font-family: $font-gt-walsheimbold;
    letter-spacing: 0.06px;
    line-height: 20px;

    &:last-child {
      margin-left: 100px;
    }

    @include tablet {
      font-size: 22.5px;
      letter-spacing: 0.085px;
      line-height: 27.5px;

      &:last-child {
        margin-left: 150px;
      }
    }

    @include desktop {
      font-size: 30px;
      letter-spacing: 0.11px;
      line-height: 35px;

      &:last-child {
        margin-left: 200px;
      }
    }
  }
}

.booking-selecting-make-it-special {
  height: 300px;
  // max-height: 250px;
  overflow: scroll;

  @include tablet {
    // margin-left: 50px;
    // margin-right: 30px;
    margin-left: 22px;
    margin-right: 0px;
  }

  @include desktop {
    height: 390px;
    margin-right: 0px;
    // max-height: 500px;
  }

  .booking-special {
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}
