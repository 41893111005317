@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.booking-special {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 10px;
  margin-bottom: 30px;

  border-bottom: 1px solid $black;

  &__info {}

  &__title {
    margin-top: 0;
    margin-bottom: 20px;
    
    color: $black;
    font-size: 20px;
    font-family: $font-gt-walsheimbold;
    letter-spacing: 0.07px;
    line-height: 24px;

    @include tablet {
      padding: 25px 22px 25px 0px;
      margin-bottom: 0px;
      // line-height: 70px;
      line-height: 20px;
      
    }

    @include desktop {
      padding: 0px;
      margin-bottom: 0px;
      line-height: 70px;
    }
  }

  &__description {
    margin-bottom: 26px;
    color: $black;
    font-size: 14px;
    font-family: $font-gt-walsheimregular;
    letter-spacing: 0.06px;
    line-height: 20px;

    @include tablet {
      font-size: 16px;
      letter-spacing: 0.06px;
      line-height: 22px;
    }
  }

  &__total {
    margin-bottom: 50px;
    color: $black;
    font-size: 40px;
    font-family: $font-gt-walsheimregular;
    letter-spacing: 0.15px;
    line-height: 22px;
  }

  &__button {
    padding: 0px 20px;
    height: 30px;
    font-size: 8.8px;
    line-height: 10px;
    letter-spacing: 0.13px;
    border: 1px solid $black;

    @include tablet {
      padding: 0px 28px;
      height: 41px;
      font-size: 12.4px;
      line-height: 14px;
      letter-spacing: 0.18px;
    }

    @include desktop {
      padding: 0px 36px;
      height: 52px;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.23px;
    }
  }
}
