@import "../../partials/mixins";
@import "../../partials/variables";

.neighbourhood-new {
  padding-top: 33px;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 33px;
  transition: all 600ms ease;

  @include tablet {
    padding-top: 0;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 0;
  }

  &__container {

    transition: all 600ms ease;
    
    @include tablet {
      width: 100%;
      max-width: 1087px;
      margin: 0 auto;
    }
  }

  &__image-text-content {
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    transition: all 600ms ease;

    @include tablet {
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 0;
    }
  }

  &__image-container {
    display: flex;
    height: 100%;

    transition: all 600ms ease;
  }

  &__image {
    width: 103px;
    height: 101px;
    // object-fit: cover;
    object-fit: contain;
    // background-color: $black;

    transition: all 600ms ease;

    @include tablet {
      // width: 476px;
      // height: 376px;
      width: 357px;
      height: 282px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    padding-left: 10px;

    transition: all 600ms ease;
    

    @include tablet {
      // margin-top: 32px;
      justify-content: space-between;
      margin-top: 16px;
      padding-left: 49px;
      padding-bottom: 16px;
      height: 266px;
    }
  }

  &__date,
  &__budget,
  &__address {
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 14px;

    transition: all 600ms ease;

    @include tablet {
      font-size: 20px;
      letter-spacing: 0.07px;
      line-height: 22px;
    }
  }

  &__title-button-link {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    transition: all 600ms ease;

    @include tablet {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      // margin-top: 40px;
      margin-top: 0px;
    }
  }

  &__title {
    // width: 100%;
    width: 80%;
    // margin-bottom: 13px;
    margin-bottom: 35px;

    color: $black;
    font-family: $font-gt-sectra-displayregular;
    font-size: 35px;
    letter-spacing: 0.13px;
    // line-height: 40px;
    
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    transition: all 600ms ease;

    @include tablet {
      width: 80%;
      //keep this desktop styling below here?
      margin-bottom: 0px;
      // font-size: 70px;
      // letter-spacing: 0.26px;
      // line-height: 80px;
      font-size: 52.5px;
      letter-spacing: 0.195px;
      // line-height: 60px;
    }

    @include desktop {
      width: 100%;
      // margin-bottom: 0px;
      // font-size: 70px;
      // letter-spacing: 0.26px;
      // line-height: 80px;
      // font-size: 52.5px;
      // letter-spacing: 0.195px;
      // line-height: 60px;
    }
  }
  
  .button-arrow-link {
    // margin-top: 22px;
    margin-top: 0px;

    transition: all 600ms ease;

    .button-arrow {
      transition: all 600ms ease;

      &__icon {
        transition: all 600ms ease;
      }
    }

    @include tablet {
      // margin-top: 30px;
      margin-top: 0px;
    }
  }

  &:hover {
    background-color: $black;

    transition: all 600ms ease;
    
    .neighbourhood-new__container {
      transition: all 600ms ease;

      @include tablet {
        max-width: 1200px;
      }
    }

    .neighbourhood-new__image-text-content {
      transition: all 600ms ease;

      @include tablet {
        align-items: center; 
      }
    }

    .neighbourhood-new__content {
      transition: all 600ms ease;

      @include tablet {
        height: 360px;
      }
    }

    .neighbourhood-new__image {
      width: 173px;
      height: 169px;

      transition: all 600ms ease;

      @include tablet {
        // width: 534px;
        // height: 480px;
        width: 400.5px;
        height: 360px;
      }
    }

    .neighbourhood-new__date,
    .neighbourhood-new__budget,
    .neighbourhood-new__address,
    .neighbourhood-new__title {
      color: $white;

      transition: all 600ms ease;
    }

    .neighbourhood-new__title {
      font-size: 40px;
      // line-height: 45px;

      transition: all 600ms ease;
      
      @include tablet {
        // font-size: 100px;
        // letter-spacing: 0.37px;
        // line-height: 115px;
        font-size: 75px;
        letter-spacing: 0.2775px;
        // line-height: 86.25px;
      }
    }

    .button-arrow-link {
      transition: all 600ms ease;

      .button-arrow {
        color: $white;
        border-bottom: 1px solid $white;

        transition: all 600ms ease;

        &__icon {
          color: $white;

          transition: all 600ms ease;
        }
      }
    }

    @include tablet {
      // padding-top: 40px;
      // padding-bottom: 40px;
      padding-top: 40px;
      padding-bottom: 40px;

      // transition: all 600ms ease;
    }
  }
}
