@use "../../../partials/mixins" as *;
@use "../../../partials/variables" as *;
@use "../../../partials/typography" as *;

.button {
  // height: 50px;
  // padding: 0 42.5px;
  // width: fit-content;

  font-family: $font-gt-walsheimregular;
  // letter-spacing: 0.23px;
  // font-size: 16px;
  // line-height: 18px;

  white-space: nowrap;
  border-radius: 30px;
  border: none;
  transition: all 300ms ease;
  cursor: pointer;

  padding: 0px 36px;
  height: 38px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.17px;
  // border: 1px solid $black;

  @include tablet {
    padding: 0px 42px;
    height: 44px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
  }

  @include desktop {
    padding: 0px 48px;
    height: 50px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.23px;
  }
}

.button-primary {
  color: $black;
  background-color: $white;

  &:hover {
    color: $white;
    background-color: $black;
  }

  &--inverse {
    color: $white;
    background-color: $black;
    border: 1px solid transparent;

    &:hover {
      color: $black;
      background-color: $white;
      border: 1px solid $black;
    }
  }
}

.button-secondary {
  color: $white;
  background-color: $accent-dark;

  &:hover {
    background-color: $accent-light;
    color: $white;
  }

  &--inverse {
    color: $white;
    background-color: $accent-light;

    &:hover {
      color: $white;
      background-color: $accent-dark;
    }
  }
}

.button-outline {
  color: $black;
  border: 1px solid $black;
  background-color: transparent;

  &:hover {
    color: $white;
    border: 1px solid $black;
    background-color: $black;
  }

  &--inverse {
    color: $white;
    border: 1px solid $white;

    &:hover {
      color: $black;
      border: 1px solid $white;
      background-color: $white;
    }
  }
}
.button-link-wrapper {
  text-decoration: none;
}
.sms-text {
  height: 100%;
  width: 100%;
  color: $white;
  text-decoration: none;
  text-align: center;
}
