@import "../../../partials/mixins";
@import "../../../partials/variables";

.annex-page-banner {
  position: relative;
  display: flex;
  justify-content: center;

  @include desktop {
  }

  &--type-Careers {
    .annex-page-banner__title {
      bottom: -76px;
      width: 330px;

      @include desktop {
        bottom: -171px;
        display: block;
        width: 720px;
        height: initial;
      }
    }
  }

  &--type-Events {
    .annex-page-banner__title {
      bottom: -86px;
      width: 330px;

      @include tablet {
        bottom: -138px;
        width: 768px;
      }

      @include desktop {
        bottom: -60px;

        display: block;
        width: 1200px;
        height: initial;
      }
    }
  }

  &--type-Careers,
  &--type-Blog,
  &--type-Events,
  &--type-FoodAndDrinks {
    .annex-page-banner__title {
      font-size: 52.5px;
      font-weight: 300;
      letter-spacing: 0.19px;
      line-height: 55px;

      @include tablet {
        font-size: 96.25px;
        line-height: 100px;
      }

      @include desktop {
        font-size: 140px;
        font-weight: 300;
        letter-spacing: 0.52px;
        line-height: 125px;
      }
    }
  }

  &--type-FoodAndDrinks {
    margin-bottom: 83px;

    @include desktop {
      margin-left: 36px;
      margin-right: 36px;
      margin-bottom: 114px;
    }

    .annex-page-banner__title {
      bottom: -80px;
      width: 100%;
      max-width: 300px;

      @include tablet {
        bottom: -48px;
        max-width: 768px;
        font-size: 96.25px;
        line-height: 100px;
      }

      @include desktop {
        bottom: -60px;
        max-width: 100%;
        font-size: 140px;
        line-height: 125px;
      }
    }

    .annex-page-banner__image {
      width: 100%;

      &--left {
        display: none;
      }

      @include desktop {
        width: 100%;

        &--left {
          display: block;
        }
      }
    }
  }

  &--type-Neighbourhood {
    margin-bottom: 100px;

    @include desktop {
      margin-bottom: 300px;
      padding: 0 48px;
    }

    .annex-page-banner__title {
      bottom: -60px;
      width: 100%;
      max-width: 354px;

      @media screen and (min-width: 440px) {
        bottom: -80px;
        font-size: 52.5px;
        line-height: 55px;
      }

      @include tablet {
        bottom: -138px;
        max-width: 768px;
        font-size: 96.25px;
        line-height: 100px;
      }

      @include desktop {
        bottom: -185px;
        display: block;
        max-width: 943px;

        font-size: 140px;
        font-weight: 300;
        letter-spacing: 0.52px;
        line-height: 125px;
      }
    }
  }

  &__image-container,
  #neighbourhoodMap {
    height: 447px;
    width: 100%;

    @include desktop {
      height: 485px;
    }
  }

  &__image-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    height: 100%;
  }

  &__title {
    position: absolute;
    z-index: 1;
    bottom: 0;

    margin-top: 0;
    margin-bottom: 0;

    color: $black;
    text-align: center;
    font-family: $font-gt-walsheimregular;
    font-size: 38px;
    letter-spacing: 0.19px;
    line-height: 43px;

    @include tablet {
      font-size: 96.25px;
      line-height: 100px;
    }

    @include desktop {
      font-size: 140px;
      font-weight: 300;
      letter-spacing: 0.52px;
      line-height: 125px;
    }
  }
}
.events-hero-mask {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color: rgb(255, 255, 255, 0.15);
  background: linear-gradient(transparent, rgb(255, 255, 255, 0.15));
  // background-color: blue;
}
