.image-loader {
  position: relative;
  overflow: hidden;

  &__preview-green, &__preview-white {
    position: absolute;
    top: 0; left: 0;  
    bottom: 0; right: 0;  
    background-color: #004C45;
    // z-index: 4;
    z-index: 3;
  }
  &__preview-white {
    background-color: #fff;
    // z-index: 3;
    z-index: 4;
  }
  &__image {
    // &.finished {
    //   transition: all 0.7s;
    // }
    position: absolute;
    top: 0; left: 0;  
    bottom: 0; right: 0;  
    z-index: 2;
  }
  
}