@use '../../../partials/mixins' as *;
@use '../../../partials/variables' as *;
@use '../../../partials/typography' as *;

.neighbourhood-dropdown-container {
    position: relative;
    z-index: 1;
    border: 1px solid $black;
    border-bottom: none;
}

.neighbourhood-dropdown {
    position: absolute;
    border: 1px solid $black;
    border-top: none;
    min-width: calc(100% + 2px);
    max-width: 671px;
    margin-left: -1px;
    background-color: rgba(255, 255, 255, 0.9);
}

.neighbourhood-carousel-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 0 24px;
    margin-bottom: 130px;
    

    @include desktop {
        // padding: 0 36px;
    }
}

.neighbourhood-carousel {
    position: relative;
    width: 100%;

    @include desktop {
        
    }

    &__image {
        // position: relative;
        width: 100%;
        min-height: 613px;
        // height: 179.24vw;
        object-fit: cover;

        @include desktop {
            

        }
    }

    .annex-discover-card {
        margin-bottom: 0;
    }
}
.neighbourhood-carousel-item-wrapper {
    padding: 0 24px;

    @include desktop {
        padding: 0;
    }
}
.carousel-inner {
    overflow: visible;
    overflow-x: clip;
}
.neighbourhood-carousel-item {
    width: 100%;

    @include desktop {
        // position: relative;
        // display: flex;
    }
}
.neighbourhood-discover-cards {
    display: flex;
}

.carousel-control-next, .carousel-control-prev {
    width: 25px !important;
}

.carousel-control-prev {
    left: calc(100% - 55px) !important;
}


.button-next-icon-neighbourhood {
    position: relative;
    width: 100%;
}

.button-next-neighbourhood,
.button-prev-neighbourhood {
    position: absolute;
    top: 520px;
    right: 24px;
    height: 15px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;

    @include desktop {
        top: -45px;
        right: 48px;
    }
}

.button-prev-icon-neighbourhood {
    width: 100%;
}
