@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

// .test {
//     display: flex;
// }
.see-rooms-container {
  //   margin-bottom: 26px;
  margin-bottom: 69px;

  @include desktop {
    // margin-bottom: 64px;
    margin-bottom: 72px;
  }
}
.see-rooms-title {
  font-family: $font-gt-walsheimregular;
  line-height: 4.375rem;
  margin-bottom: 24px;
  padding-left: 24px;
}

.live-local-two-column-image {
  // padding-left: 26px;
  padding-left: 24px;
  margin-bottom: 55px;

  @include desktop {
    padding-left: 36px;
    padding-right: 36px;
    margin-bottom: 60px;
  }
}
