@import '../../partials/mixins';
@import '../../partials/variables';

.two-column-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &__left-column-image-container,
  &__right-column-image-container {
    width: 100%;
  }

  &__right-column-image,
  &__left-column-image {
    width: 100%;
    height: 563px;
    object-fit: cover;
  }

  &__left-content-bottom {
    // padding-top: 60px;
    padding-top: 48px;
    padding-left: 28.5px;
    padding-right: 28.5px;
    color: $black;
  }

  &__right-column-image-container {
    position: absolute;
    top: -67px;
    right: 0;
    width: 24px;
    height: 100%;
  }

  &__title {
    margin: 0;

    color: $black;
    font-size: 20px;
    letter-spacing: 0.52px;
    font-family: $font-gt-walsheimbold;
  }

  &__desc {
    margin-top: 19px;
    margin-bottom: 0;
    // max-width: 344px;
    max-width: 500px;

    color: $black;
    font-size: 16px;
    letter-spacing: 0.06px;
    line-height: 22px;
    font-family: $font-gt-walsheimregular;
  }

  @include desktop {
    flex-direction: row;
    //margin-bottom: 30px;

    &__left-content-bottom {
      // padding-top: 150px;
      padding-bottom: 60px;
      padding-left: 42.5px;
    }

    &__right-column-image-container {
      position: relative;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    &__left-column-image,
    &__right-column-image {
      height: 843px;
    }

    &__desc {
      // max-width: 344px;
      max-width: 500px;
      margin-top: 19px;
    }
  }
}
