@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.annex-image-group {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__left-column {
    display: none;

    @include desktop {
      width: 45%;
      display: block;
    }
  }

  &__left-column-image-container,
  &__right-column-top-image-container { width: 100%; }

  &__left-column-image-container,
  &__right-column-top-image-container,
  &__right-column-bottom-left-image-container,
  &__right-column-bottom-right-image-container {
    display: inline-block;
    height: 100%;
    line-height: 0;
  }

  &__left-column-image,
  &__right-column-top-image,
  &__right-column-bottom-left-image,
  &__right-column-bottom-right-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__right-column {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;

    @include desktop {
      width: 55%;
    }
  }

  &__right-column-bottom-two-images {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }
  
  &__right-column-bottom-left-image-container,
  &__right-column-bottom-right-image-container { width: 50%; }

  &__right-column-bottom-left-image,
  &__right-column-bottom-right-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}