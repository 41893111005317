@use "../../../partials/mixins" as *;
@use "../../../partials/variables" as *;
@use "../../../partials/typography" as *;

.calendar {
  display: block;
  min-width: 100%;
  background-color: transparent;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease;

  @include desktop {
    border-right: 1px solid $black;
    // min-width: 372px;
    min-width: calc(100% + 1px);
    // min-width: 282.25px; //this right?
    display: inline-block;
    padding: 0px 11px 0;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 73px;
  }

  &--active {
    max-height: 402px;
    // padding: 11px 11px 6px;
    transition: all 0.5s ease-out;

    @include desktop {
      padding: 11px 11px 6px;
      // max-height: 402px;
    }
  }

  &__tile,
  &__tile-event {
    // width: calc(100% / 7);
    max-width: 50px !important;
    // min-height: 40px;
    // height: calc((100vw - 50px) / 7);
    max-height: 50px;
    margin: 0 0 5px;
    background-color: transparent;
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-family: $font-gt-walsheimregular;
    letter-spacing: -0.000625rem;

    @include desktop {
      // min-height: 50px;
      // max-width: 50px !important;
    }

    &:hover {
      background-color: $accent-light;
      color: $white;
      border-radius: 100%;
      cursor: pointer;
    }
    &--closed {
      display: none;
      max-height: 0;
    }
  }

  &__tile {
    width: calc(100% / 7);
    height: calc((100vw - 50px) / 7);
  }

  &__tile-event {
    // width: calc((100% - 22px) / 7);
    width: calc(100% / 7);
    height: calc((100vw - 80px) / 7);
    // aspect-ratio: 1/1;

    // @include tablet {
    //     height: calc((100vw - 449.5px) / 7);
    // }

    @include desktop {
      // height: calc((100vw - 1183.75px) / 7); //change to correct px value
      // height: 40px;
      // width: calc((100vw - 1183.75px) / 7);
      // min-width: calc(17.65625vw / 7);
      // min-height: calc(17.65625vw / 7);
      // width: calc(17.65625vw / 7);
      // height: calc(17.65625vw / 7);
      width: 36.31px;
      height: 36.31px;
    }
  }
}

.calendar-book-room {
  display: block;
  min-width: 100%;
  background-color: transparent;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease;

  @include desktop {
    border-right: 1px solid $black;
    min-width: 372px;
    display: inline-block;
    padding: 0px 11px 0;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    // top: -173px;
    bottom: 73px;
  }

  &--active {
    max-height: 402px;
    // padding: 11px 11px 6px;
    transition: all 0.5s ease-out;

    @include desktop {
      padding: 11px 11px 6px;
      // max-height: 402px;
    }
  }
}
.calendar-book-this-room {
  display: block;
  min-width: 100%;
  background-color: $white;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease;

  @include desktop {
    // border-right: 1px solid $black;
    // min-width: 372px;
    // min-width: calc(100% + 1px);
    // min-width: 282.25px; //this right?
    display: inline-block;
    padding: 0px 11px 0;
    // background-color: rgba(255, 255, 255, 0.9);
    // position: absolute;
    // top: 73px;
  }

  &--active {
    max-height: 402px;
    // padding: 11px 11px 6px;
    transition: all 0.5s ease-out;

    @include desktop {
      padding: 11px 11px 6px;
      // max-height: 402px;
    }
  }
}
.calendar-book-event {
  display: block;
  min-width: 100%;
  background-color: transparent;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @include desktop {
    border-right: 1px solid $black;
    min-width: 100%;
    display: inline-block;
    padding: 0px 11px 0;
    background-color: rgba(255, 255, 255, 0.9);
    // position: absolute;
    // top: -173px;
    // bottom: 73px;
  }

  &--active {
    max-height: 402px;
    // padding: 11px 11px 6px;
    transition: all 0.5s ease-out;

    @include desktop {
      // padding: 11px 11px 6px;
      padding: 0 1.75px;
      // max-height: 402px;
    }
  }
}
.react-calendar {
  // position: absolute;

  &__navigation {
    display: flex;
    height: 60px;
    margin-bottom: 14px;
    background-color: transparent;
  }

  &__navigation__label {
    width: 100%;
    font-family: $font-gt-walsheimregular;
    font-size: 1.375rem;
    line-height: 1.5625rem;
    letter-spacing: -0.000625rem;
    color: $black;
    background-color: transparent;
  }

  &__navigation__arrow {
    color: $black;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  &__month-view__weekdays__weekday {
    text-align: center;
    font-family: $font-gt-walsheimregular;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 20px;
  }

  &__month-view__days {
    display: flex;
    width: 100%;
  }

  &__year-view__months {
    display: flex;
    justify-content: center;
  }

  &__decade-view__years {
    display: flex;
    justify-content: center;
  }

  &__tile {
    color: $black;

    &:disabled {
      color: #1010104d;
    }

    &--active {
      background-color: $accent-dark;
      color: $white;

      &::before {
        background-color: $accent-dark;
      }
    }

    &--now {
      color: $accent-dark;
    }

    &--range {
      background-color: $accent-dark;
      color: $white;
    }

    &--rangeStart {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    &--rangeEnd {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
}
.calendar-next {
  transform: rotate(-90deg);
  height: 9px;
  width: 16px;
  margin-right: 10px;
}
.calendar-prev {
  transform: rotate(90deg);
  height: 9px;
  width: 16px;
  margin-left: 9px;
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}
.white-text {
  border: 1px solid $white;
  border-top: none;
  border-bottom: none;
  background-color: rgba(255, 255, 255, 0.9);
}
