@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.privacy-policy-container {
  text-align: center;
  width: 100%;
  display: grid;
  justify-content: center;
  @include desktop {
    padding: 0 36px;
  }
}

.privacy {
  max-width: 1435px;

  &__title {
    color: $black;
    font-family: $font-gt-sectra-displayregular;
    font-size: 140px;
    padding-top: 50px;

    @include desktop {
      margin-bottom: 17px;
    }

  }

  &__subTitle {
    color: $black;
    font-size: 24px;
    text-transform: uppercase;
    font-family: $font-gt-walsheimregular;
  }

  .privacy_text_container {
    font-family: $font-gt-walsheimregular;
    margin-top: 200px;


    &--text,
    &--text--list {
      letter-spacing: 0.07px;
      font-size: 20px;
      line-height: 25px !important;
      text-align: left;
      padding: 20px;
    }

    &--text--list {
      padding: 0 50px;
      list-style-type: '- ';

      &:not(:not(.same)) {
        list-style-type: number;
        padding: 0 70px;
      }
    }

    .privacy_text_container_title {
      color: $black;
      font-family: $font-gt-walsheimregular;
      font-size: 40px;
      letter-spacing: 0.15px;
      padding: 20px;
      text-align: left;
    }

    &--text a {
      color: initial;
      cursor: pointer;
    }
  }
}

