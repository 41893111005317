@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.auth-menu-mobile {
    position: absolute;
    top: 88px;

    width: 100%;
}
.hide-auth-menu-mobile {
    display: none;
}