@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 87px;
  left: 0;
  max-height: 0;
  z-index: 100;
  background-color: $white;
  transition: all 0.5s ease;
  overflow: hidden;

  @include desktop {
    top: 90px;
  }
}
.nav-menu-active {
  transition: all 1s ease-out;
  max-height: 1000px;
  padding-bottom: 88px;

  @include desktop {
    padding-bottom: 91px;
    transition: all 0.5s ease-out;
  }
}

.menu-middle {
  display: flex;
  flex-direction: column;
  padding: 20px 24px 0;
  margin-bottom: 60px;

  @include tablet {
    padding: 40px 24px 0;
  }

  @include desktop {
    padding: 53.5px 134.5px 0;
    margin-bottom: 69px;
  }

  &__item {
    color: $black;
    font-family: $font-gt-walsheimregular;
    text-decoration: none;
    font-size: 1.875rem;
    line-height: 3.1875rem;

    @include tablet {
      font-size: 3.125rem;
      margin-bottom: 1.375rem;
    }

    @include desktop {
      font-size: 3.75rem;
      line-height: 4.375rem;
      letter-spacing: 0.01375rem;
      margin-bottom: 1.375rem;
    }

    &:hover {
      color: $accent-dark;
      text-decoration: underline;
    }
  }
}

.menu-bottom {
  display: flex;

  @include desktop {
    flex-direction: row;
    padding: 0 0 0 111px;
  }
}
.menu-location {
  display: flex;
  flex-direction: column;
  width: 10.4375rem;
  margin-right: 80px;
}
.menu-contact {
  display: flex;
  flex-direction: column;
  width: 9.6875rem;
  margin-right: 93px;
}
.menu-social {
  display: flex;
  flex-direction: column;
  margin-right: 93px;
}
.menu-social-links {
  display: flex;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop {
      margin-right: 16px;
    }

    &--instagram,
    &--facebook {
      width: 17px;
      height: 17px;
    }

    &--medium {
      width: 20px;
      height: 20px;
    }
  }
}
.menu-bottom-title {
  color: $black;
  font-size: 1.25rem;
  font-family: $font-gt-walsheimregular;
  line-height: 3rem;
  letter-spacing: 0.004375rem;
}
.menu-bottom-text {
  color: $black;
  font-family: $font-gt-walsheimregular;
  line-height: 1.625rem;
  letter-spacing: 0.00375rem;
  & a {
    color: initial;
    text-decoration: none;
    cursor: pointer;
  }
}
.menu-bottom-booking {
  margin-top: 20px;
  font-size: 0.875rem;
  color: $accent-dark;
  font-family: $font-gt-walsheimregular;
  line-height: 1.25rem;
  letter-spacing: 0.00375rem;
  text-decoration: underline;
  cursor: pointer;

  @include desktop {
    margin-top: 0;
    font-size: 1rem;
    line-height: 1.625rem;
  }
}
.menu-footer-mobile-container {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
}
.footer-mobile-links-container {
  display: flex;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $black;
  margin-bottom: 28px;
}
.footer-mobile-links {
  display: flex;
  flex-direction: column;

  &__text {
    text-decoration: none;
    font-family: $font-gt-walsheimregular;
    font-size: 0.875rem;
    line-height: 1.625rem;
    color: $black;
  }
}
.footer-mobile-links-left {
  margin-right: 65px;
}
.footer-mobile-subscribe {
  margin-bottom: 54px;

  &__title {
    font-family: $font-gt-walsheimregular;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.25rem;
  }

  &__body {
    font-family: $font-gt-walsheimregular;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 22px;
  }
}
.footer-mobile-contact {
  display: flex;
  justify-content: space-between;
  // width: 340px;
  width: 100%;
  // margin-bottom: 28px;
  margin-bottom: 35px;
}
.footer-mobile-contact-address {
  display: flex;
  flex-direction: column;

  &__text {
    font-family: $font-gt-walsheimregular;
    font-size: 0.875rem;
    line-height: 1.25rem;

    & a {
      color: initial;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
.footer-mobile-contact-social {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 3px;
  width: 76px;
  cursor: pointer;

  &__instagram {
    width: 17px;
    height: 17px;
  }

  &__facebook {
    width: 9px;
    height: 17px;
  }

  &__medium {
    width: 20px;
    height: 18px;
  }
}
.footer-mobile-copyright-text {
  font-family: $font-gt-walsheimregular;
  font-size: 0.875rem;
}
.footer-mobile-p-policy-link {
  color: #0077ff;
}
.menu-contact-link {
  font-family: $font-gt-walsheimregular;
  line-height: 1.625rem;
  letter-spacing: 0.00375rem;
  text-decoration: underline;
  cursor: pointer;

  &__link {
    color: $accent-dark;
  }
}


.footer-mobile-the-lobby-text {
  margin-bottom: 25px;
  font-family: $font-gt-walsheimregular;
  font-size: 0.875rem;
}

.footer-mobile-the-lobby-link {
  color: $accent-dark;
}