@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.food-and-drink {
  &__eats-drinks {
    // padding-left: 26px;
    // padding-right: 26px;

    @include desktop {
      padding-left: 36px;
      padding-right: 36px; 
    }
  }
  
  &__title {
    padding-left: 24px;
    margin-top: 70px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 30px;
    line-height: 70px;
    letter-spacing: 0.11px;

    @include desktop {
      padding-left: 0;
      margin-left: 45px;
      margin-bottom: 50px;
      font-size: 40px;
      letter-spacing: 0.15px; 
    }
  }
  
  &__eats-list {
    .annex-restaurant-info {
      margin-bottom: 50px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image-group {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 93px;
    margin-bottom: 70px;

    @include desktop {
      padding-left: 36px;
      padding-right: 36px; 
    }
  }

  &__info-image-group-carousel { 
    // margin-top: 90px; 
    margin-top: 85px;
  }
}
