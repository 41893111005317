@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.annex-discover-card {
  background-color: $white;
  margin-bottom: 113px;

  &__inner {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: column;
    height: 100%;
  }

  &__image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__instagram-icon {
    position: absolute;
    top: 6px;
    right: 6px;
    // width: 35px;
    // height: 35px;
    width: 53px;
    height: 53px;
  }

  &__image {
    width: 100%;
    height: 494px;
    object-fit: cover;
  }

  &__music-image {
    width: 100%;
    max-width: 500px;
    // height: 494px;
    object-fit: cover;
  }

  &__info {
    // height: 100%;
    // height: 250px;
    // height: 270px;
    height: 100%;
    width: 100%;
  }

  &__info-inner {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    padding-top: 48px;
    // padding-left: 80px;
    // padding-right: 80px;
    // padding-left: 53px;
    // padding-right: 53px;
    padding-left: 24px;
    padding-right: 24px;
    // padding-bottom: 48px;

    background-color: $white;
  }

  &__carousel-arrows {
    position: absolute;
    top: 20px;
    right: 24px;
  }

  &__carousel-arrow {
    &--previous {
      margin-right: 5px;
    }

    &--next {
      margin-left: 5px;
    }
  }

  &__info-title-paragraph {
  }

  &__title {
    margin: 0;
    color: $black;
    font-family: $font-gt-walsheimbold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }

  &__address {
    margin: 0;
    color: $black;
    font-family: $font-gt-walsheimregular;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: 0.06px;
  }

  &__paragraph {
    // margin-top: 20px;
    margin-top: 19px;
    margin-bottom: 0;
    max-width: 466px;
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.06px;
  }

  .button-arrow-link {
    // margin-top: 80px;
    margin-top: 30px;
  }

  @include desktop() {
    &__info-inner {
      // padding-left: 78.5px;
      // padding-right: 58.5px;
      padding-left: 39.25px;
      padding-right: 29.25px;
    }

    &__carousel-arrows {
      display: none;
    }

    .button-arrow-link {
      // margin-top: 50px;
      margin-top: 30px;
    }
  }
}
