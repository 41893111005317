@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.moments {
  position: relative;

  width: 100%;
  // height: 1354px;
  // height: 2708px;
  // height: 3385px; //this is the height of 20 images
  // height: 4062px; //this is the height of 24 images
  height: calc(
    677px * 6
  ); //677px is the height of 4 images, multipy by 6 for 24 images

  background-color: $black;

  overflow: hidden;

  @include desktop {
    // height: 2972px;
    // height: 5944px;
    // height: 7430px; //this is the height of 20 images
    // height: 8916px; //this is the height of 24 images
    height: calc(
      1486px * 6
    ); //1486px is the height of 4 images, multipy by 6 for 24 images
  }
}
.moments-title {
  position: fixed;
  z-index: 1;

  margin: 238px 0 0 24px;

  @include desktop {
    margin: 238px 0 0 36px;
  }

  &__text {
    font-family: $font-gt-walsheimregular;
    font-size: 3.4rem !important;
    line-height: 3.0625rem;
    letter-spacing: 0.0125rem;
    color: $white;
    transition: all 0.5s ease;

    @include tablet {
      font-size: 6.075rem !important;
      line-height: 5.4375rem;
      letter-spacing: 0.0225rem;
    }

    @include desktop {
      font-size: 8.75rem !important;
      line-height: 7.8125rem;
      letter-spacing: 0.0325rem;
    }

    &--pause {
      color: rgb(255, 255, 255, 0.3);
    }
  }
}
.moments-imgs-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  // overflow: hidden;
  white-space: nowrap;
  animation: imgVertCarousel 50s linear infinite;

  @include desktop {
    animation: imgVertCarousel 110s linear infinite;
  }

  &--pause {
    animation-play-state: paused;
    z-index: 2;
  }

  &--elevate {
    z-index: 11;

    transition: all 0.5s ease;
  }
}
.moments-img {
  &--text-align {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--1,
  &--2,
  &--3,
  &--4,
  &--5,
  &--6,
  &--7,
  &--8,
  &--9,
  &--10,
  &--11,
  &--12,
  &--13,
  &--14,
  &--15,
  &--16,
  &--17,
  &--18,
  &--19,
  &--20,
  &--21,
  &--22,
  &--23,
  &--24,
  &--25,
  &--26,
  &--27,
  &--28,
  &--29,
  &--30,
  &--31,
  &--32,
  &--33,
  &--34,
  &--35,
  &--36,
  &--37,
  &--38,
  &--39,
  &--40,
  &--41,
  &--42,
  &--43,
  &--44,
  &--45,
  &--46,
  &--47,
  &--48 {
    position: absolute;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    transition: all 0.5s ease;

    &:hover {
      z-index: 2;

      transform: scale(1.05);
      transition: all 0.5s ease;
    }

    &:hover .moments-img__text {
      display: inline-block;
      z-index: 2;

      opacity: 1;
      transition: all 0.5s ease;

      //   background-color: rgb(0, 0, 0, 0.2);
      //   box-shadow: 0px 0px 20px 20px rgb(0, 0, 0, 0.2);
    }
  }

  &--1,
  &--5,
  &--9,
  &--13,
  &--17,
  &--21,
  &--25,
  &--29,
  &--33,
  &--37,
  &--41,
  &--45 {
    left: 48.717949%;
    // left: 13px;
    // width: 187px;
    width: 47.948718%;
    height: 236px;

    @include desktop {
      // left: 411px;
      left: 28.541667%;
      // width: 537px;
      width: 37.291667%;
      height: 574px;
    }
  }

  &--2,
  &--6,
  &--10,
  &--14,
  &--18,
  &--22,
  &--26,
  &--30,
  &--34,
  &--38,
  &--42,
  &--46 {
    // right: 36px;
    right: 0;
    // width: 150px;
    width: 38.461538%;
    height: 219px;

    @include desktop {
      // right: 36px;
      right: 2.5%;
      // width: 329px;
      width: 22.847222%;
      height: 406px;
    }
  }

  &--3,
  &--7,
  &--11,
  &--15,
  &--19,
  &--23,
  &--27,
  &--31,
  &--35,
  &--39,
  &--43,
  &--47 {
    // left: 164px;
    left: 0;
    // width: 161px;
    width: 41.282051%;
    height: 211px;
    // height: 296px;

    @include desktop {
      // left: 164px;
      left: 11.3888888%;
      // width: 329px;
      width: 22.847222%;
      height: 406px;
      // height: 296px;
    }
  }

  &--4,
  &--8,
  &--12,
  &--16,
  &--20,
  &--24,
  &--28,
  &--32,
  &--36,
  &--40,
  &--44,
  &--48 {
    // right: 209px;
    right: 53.589744%;
    // width: 181px;
    width: 46.410256%;
    // height: 192px;
    height: 228px;

    @include desktop {
      // right: 114px;
      right: 7.91666666%;
      // width: 537px;
      width: 37.291667%;
      // height: 192px;
      height: 662px;
    }
  }

  &--1 {
    top: 15px;

    @include desktop {
      top: 0;
    }
  }

  &--2 {
    top: 311px;

    @include desktop {
      top: 289px;
    }
  }

  &--3 {
    top: 83px;

    @include desktop {
      top: 640px;
    }
  }

  &--4 {
    top: 378px;

    @include desktop {
      top: 744px;
    }
  }

  &--5 {
    top: calc((677px * 1) + 15px);

    @include desktop {
      top: calc((1486px * 1) + 0px);
    }
  }

  &--6 {
    top: calc((677px * 1) + 311px);

    @include desktop {
      top: calc((1486px * 1) + 289px);
    }
  }

  &--7 {
    top: calc((677px * 1) + 83px);

    @include desktop {
      top: calc((1486px * 1) + 640px);
    }
  }

  &--8 {
    top: calc((677px * 1) + 378px);

    @include desktop {
      top: calc((1486px * 1) + 744px);
    }
  }
  &--9 {
    top: calc((677px * 2) + 15px);

    @include desktop {
      top: calc((1486px * 2) + 0px);
    }
  }

  &--10 {
    top: calc((677px * 2) + 311px);

    @include desktop {
      top: calc((1486px * 2) + 289px);
    }
  }

  &--11 {
    top: calc((677px * 2) + 83px);

    @include desktop {
      top: calc((1486px * 2) + 640px);
    }
  }

  &--12 {
    top: calc((677px * 2) + 378px);

    @include desktop {
      top: calc((1486px * 2) + 744px);
    }
  }
  &--13 {
    top: calc((677px * 3) + 15px);

    @include desktop {
      top: calc((1486px * 3) + 0px);
    }
  }

  &--14 {
    top: calc((677px * 3) + 311px);

    @include desktop {
      top: calc((1486px * 3) + 289px);
    }
  }

  &--15 {
    top: calc((677px * 3) + 83px);

    @include desktop {
      top: calc((1486px * 3) + 640px);
    }
  }

  &--16 {
    top: calc((677px * 3) + 378px);

    @include desktop {
      top: calc((1486px * 3) + 744px);
    }
  }

  &--17 {
    top: calc((677px * 4) + 15px);

    @include desktop {
      top: calc((1486px * 4) + 0px);
    }
  }

  &--18 {
    top: calc((677px * 4) + 311px);

    @include desktop {
      top: calc((1486px * 4) + 289px);
    }
  }

  &--19 {
    top: calc((677px * 4) + 83px);

    @include desktop {
      top: calc((1486px * 4) + 640px);
    }
  }

  &--20 {
    top: calc((677px * 4) + 378px);

    @include desktop {
      top: calc((1486px * 4) + 744px);
    }
  }

  &--21 {
    top: calc((677px * 5) + 15px);

    @include desktop {
      top: calc((1486px * 5) + 0px);
    }
  }

  &--22 {
    top: calc((677px * 5) + 311px);

    @include desktop {
      top: calc((1486px * 5) + 289px);
    }
  }

  &--23 {
    top: calc((677px * 5) + 83px);

    @include desktop {
      top: calc((1486px * 5) + 640px);
    }
  }

  &--24 {
    top: calc((677px * 5) + 378px);

    @include desktop {
      top: calc((1486px * 5) + 744px);
    }
  }
  &--25 {
    top: calc((677px * 6) + 15px);

    @include desktop {
      top: calc((1486px * 6) + 0px);
    }
  }

  &--26 {
    top: calc((677px * 6) + 311px);

    @include desktop {
      top: calc((1486px * 6) + 289px);
    }
  }

  &--27 {
    top: calc((677px * 6) + 83px);

    @include desktop {
      top: calc((1486px * 6) + 640px);
    }
  }

  &--28 {
    top: calc((677px * 6) + 378px);

    @include desktop {
      top: calc((1486px * 6) + 744px);
    }
  }
  &--29 {
    top: calc((677px * 7) + 15px);

    @include desktop {
      top: calc((1486px * 7) + 0px);
    }
  }

  &--30 {
    top: calc((677px * 7) + 311px);

    @include desktop {
      top: calc((1486px * 7) + 289px);
    }
  }

  &--31 {
    top: calc((677px * 7) + 83px);

    @include desktop {
      top: calc((1486px * 7) + 640px);
    }
  }

  &--32 {
    top: calc((677px * 7) + 378px);

    @include desktop {
      top: calc((1486px * 7) + 744px);
    }
  }

  &--33 {
    top: calc((677px * 8) + 15px);

    @include desktop {
      top: calc((1486px * 8) + 0px);
    }
  }

  &--34 {
    top: calc((677px * 8) + 311px);

    @include desktop {
      top: calc((1486px * 8) + 289px);
    }
  }

  &--35 {
    top: calc((677px * 8) + 83px);

    @include desktop {
      top: calc((1486px * 8) + 640px);
    }
  }

  &--36 {
    top: calc((677px * 8) + 378px);

    @include desktop {
      top: calc((1486px * 8) + 744px);
    }
  }

  &--37 {
    top: calc((677px * 9) + 15px);

    @include desktop {
      top: calc((1486px * 9) + 0px);
    }
  }

  &--38 {
    top: calc((677px * 9) + 311px);

    @include desktop {
      top: calc((1486px * 9) + 289px);
    }
  }

  &--39 {
    top: calc((677px * 9) + 83px);

    @include desktop {
      top: calc((1486px * 9) + 640px);
    }
  }

  &--40 {
    top: calc((677px * 9) + 378px);

    @include desktop {
      top: calc((1486px * 9) + 744px);
    }
  }

  &--41 {
    top: calc((677px * 10) + 15px);

    @include desktop {
      top: calc((1486px * 10) + 0px);
    }
  }

  &--42 {
    top: calc((677px * 10) + 311px);

    @include desktop {
      top: calc((1486px * 10) + 289px);
    }
  }

  &--43 {
    top: calc((677px * 10) + 83px);

    @include desktop {
      top: calc((1486px * 10) + 640px);
    }
  }

  &--44 {
    top: calc((677px * 10) + 378px);

    @include desktop {
      top: calc((1486px * 10) + 744px);
    }
  }

  &--45 {
    top: calc((677px * 11) + 15px);

    @include desktop {
      top: calc((1486px * 11) + 0px);
    }
  }

  &--46 {
    top: calc((677px * 11) + 311px);

    @include desktop {
      top: calc((1486px * 11) + 289px);
    }
  }

  &--47 {
    top: calc((677px * 11) + 83px);

    @include desktop {
      top: calc((1486px * 11) + 640px);
    }
  }

  &--48 {
    top: calc((677px * 11) + 378px);

    @include desktop {
      top: calc((1486px * 11) + 744px);
    }
  }

  &__text {
    position: absolute;
    top: 100%;
    // margin-top: -27px;
    margin-top: -14px;

    width: 90%;
    height: fit-content;

    font-family: $font-gt-walsheimregular;
    // font-size: 30px;
    // line-height: 32px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.11px;
    color: $white;
    white-space: pre-wrap;

    opacity: 0;

    transition: all 0.5s ease;

    &:hover {
      z-index: 2;
      transition: all 0.5s ease;
    }

    @include tablet {
      // margin-top: -40.5px;
      margin-top: -21px;

      // font-size: 50px;
      // line-height: 46px;
      font-size: 25px;
      line-height: 27px;
      letter-spacing: 0.185px;
    }

    @include desktop {
      // margin-top: -54px;
      margin-top: -26px;

      // font-size: 70px;
      // line-height: 60px;
      font-size: 35px;
      line-height: 37px;
      letter-spacing: 0.26px;
    }
  }
}
.share-moment {
  position: fixed;
  z-index: 10;
  top: 471px;
  right: calc(50% - (50px));

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 1px solid rgb(255, 255, 255, 0.23);

  font-family: $font-gt-walsheimregular;
  text-align: center;
  color: $white;

  background-color: transparent;

  transition: all 0.3s ease;

  @include tablet {
    top: 571px;
    right: calc(50% - (56.25px));

    width: 112.5px;
    height: 112.5px;
  }

  @include desktop {
    top: 671px;
    right: calc(50% - (62.5px));

    width: 125px;
    height: 125px;
  }

  &:hover {
    // color: $white;
    color: $black;
    text-decoration: none;

    // background-color: rgb(0, 0, 0, 0.33);
    background-color: rgb(255, 255, 255, 0.9);

    border: none;

    transition: all 0.3s ease;

    @include desktop {
      border: none;
    }
  }
}

.camera-roll-images-mask {
  position: absolute;
  // background-color: rgb(255, 255, 255, 0.15);
  background: linear-gradient(transparent, rgb(0, 0, 0, 0.2));
  // width: calc(100% - 24px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@keyframes imgVertCarousel {
  0% {
    transform: translate(0, 0);
  }
  100% {
    // transform: translate(0, -128.5%);
    transform: translate(0, -100%);
  }
}
