@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.annex-booking-summary {
  padding-top: 15px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 28px;
  background-color: $wildSand;

  // @include tablet {
  //   padding: 45px 50px;
  // }

  @include desktop {
    padding: 60px 68px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;

    font-family: $font-gt-walsheimbold;
    font-size: 12px;
    line-height: 70px;
    letter-spacing: 0.04px;

    @include tablet {
      font-size: 16px;
      letter-spacing: 0.07px;
    }

    @include desktop {
      font-size: 20px;
      letter-spacing: 0.07px;
    }
  }

  &__img-text-container {
    margin-bottom: 46px;
    position: relative;

    @include desktop {
      margin-bottom: 92px;
    }
  }

  &__img {
    width: 100%;
  }

  &__room-name {
    position: absolute;
    left: 15px;
    bottom: -12px;

    margin-top: 0;
    margin-bottom: 0;

    font-family: $font-gt-sectra-displayregular;
    font-size: 35px;
    line-height: 35px;
    letter-spacing: 0.13px;

    @include tablet {
      bottom: -20px;
      font-size: 52.5px;
      line-height: 52.5px;
      letter-spacing: 0.195px;
    }

    @include desktop {
      bottom: -22px;
      font-size: 70px;
      line-height: 70px;
      letter-spacing: 0.26px;
    }
  }

  &__cost-container {
    padding-bottom: 5px;
    border-bottom: 1px solid $black;

    @include desktop {
      padding-bottom: 15px;
    }
  }

  &__cost-column {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    font-family: $font-gt-walsheimregular;
    font-size: 12px;
    line-height: 17.5px;
    letter-spacing: 0.04px;

    @include tablet {
      font-size: 18px;
      line-height: 26.25px;
      letter-spacing: 0.065px;
    }

    @include desktop {
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 0.09px;
    }
  }

  &__cost {
    font-family: $font-gt-walsheimregular;
    font-size: 12px;
    line-height: 17.5px;
    letter-spacing: 0.04px;

    @include tablet {
      font-size: 18px;
      line-height: 26.25px;
      letter-spacing: 0.065px;
    }

    @include desktop {
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 0.09px;
    }
  }

  &__booking-total-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include desktop {
      margin-top: 20px;
    }
  }

  &__booking-total,
  &__booking-total-cost {
    margin-top: 0px;
    margin-bottom: 0px;

    font-family: $font-gt-walsheimbold;
    font-size: 15px;
    line-height: 17.5px;
    letter-spacing: 0.06px;

    @include tablet {
      font-size: 22.5px;
      line-height: 26.25px;
      letter-spacing: 0.085px;
    }

    @include desktop {
      font-size: 30px;
      line-height: 35px;
      letter-spacing: 0.11px;
    }
  }
}
