@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.discover-card-carousel-title {
  //   font-family: $font-gt-walsheimregular;
  //   line-height: 4.375rem;
  //   margin-bottom: 6px;
  //   padding-left: 24px;

  //   @include desktop {
  //     padding-left: 78.5px;
  //     margin-bottom: 64px;
  //   }
  font-family: $font-gt-walsheimregular;
  line-height: 1.875rem;
  margin-top: 20px;
  margin-bottom: 69px;
  padding-left: 24px;

  @include desktop {
    padding-left: 78.5px;
    margin-bottom: 72px;
    margin-top: 0;
  }
}
.dc-carousel {
  width: 100%;
}

.discover-card-list-desktop {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content:center;
}
.discover-card-desktop {
  width: 25%;
}
.button-next-dcc {
  position: absolute;
  bottom: 359px;
  right: 24px;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  @include desktop {
    right: 36px;
    top: -52px;
  }
}

.button-next-icon-dcc {
  position: relative;
  width: 100%;
}

.button-prev-dcc {
  position: absolute;
  bottom: 359px;
  right: 24px;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include desktop {
    right: 36px;
    top: -52px;
  }
}

.button-prev-icon-dcc {
  width: 100%;
}
