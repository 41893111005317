@use '../../../partials/mixins' as *;
@use '../../../partials/variables' as *;
@use '../../../partials/typography' as *;

.loading-animation {
    display: flex;
    align-items: center;

    &__icon {
        height: 20px;
        margin-right: 10px;
        // color: $black;
        // animation: blinking 1.5s ease-in-out infinite;

        @include desktop {
            margin-right: 15px;
            height: 27.5px;
        }

        @include desktop {
            margin-right: 20px;
            height: 35px;
        }
    }

    &__text {
        font-family: $font-gt-walsheimregular;
        font-size: 16px;
        position: relative;
        color: lightgray;
        background-clip:content-box;
        -webkit-background-clip: content-box;

        @include tablet {
            font-size: 23px;
        }

        @include desktop {
            font-size: 30px;
        }
    }

    &__text::before {
        content: 'Loading...';
        background: $black;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // height: 0;
        animation: loadingText 3s ease-in-out infinite;
        // transition: all 1s ease;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
    }
}

@keyframes loadingText {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

@keyframes blinking {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}