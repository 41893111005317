@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.annex-auth-side-menu-desktop-list {
  display: none;

  &__item {
    position: relative;

    display: block;
    width: 100%;
    padding: 25px 0;

    text-decoration: none;
    text-align: left;

    background-color: transparent;
    border-top: 1px solid $black;

    &:last-child {
      border-bottom: 1px solid $black;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      width: 5px;
      height: 100%;

      background-color: $accent-dark;
      opacity: 0;
      transition: all 300ms ease;
    }
    
    &--active {
      &::after {
        opacity: 1;
      }
    }

    &:hover {
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        width: 5px;
        height: 100%;

        background-color: $accent-dark;
        opacity: 1;
      }
    }

    @include desktop {
      display: block;
    }
  }

  &__text {
    margin-left: 33px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.01px;
  }

  @include desktop() {
    display: block;
  }
}