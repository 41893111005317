@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.faqs {
  padding-left: 26px;
  padding-right: 26px;

  @include desktop {
    padding-left: 36px;
    padding-right: 36px;
  }


  &__room-details {
    .faqs-accordion {
      &:last-child {
        border-bottom: 1px solid $black;
      }
    }
  }
}

.faqs-header {
  margin-top: 62.5px;
  text-align: center;

  @include tablet {
    margin-top: 93.75px;
  }

  @include desktop {
    margin-top: 125px;
  }

  &__title {
    color: $black;
    font-family: $font-gt-sectra-displayregular;
    font-size: 70px;
    letter-spacing: 0.66px;
    line-height: 72.5px;

    @include tablet {
      // font-size: 140px;
      // letter-spacing: 1.32px;
      line-height: 125px;
    }

    @include desktop {
      font-size: 140px;
      letter-spacing: 1.32px;
      line-height: 145px;
    }
  }

  &__body {
    margin-top: 70px;
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.29px;
    text-transform: uppercase;

    @include desktop {
      margin-top: 78.5px;
      font-size: 20px;
      line-height: 23px;
    }
  }
}

.faqs-title {
  margin-top: 80px;
  margin-bottom: 40.5px;

  color: $black;
  font-family: $font-gt-sectra-finebook;
  font-size: 35px;
  line-height: 35px;
  letter-spacing: 0.13px;

  @include desktop {
    // margin-top: 57px;
    margin-bottom: 81px;

    font-size: 70px;
    line-height: 70px;
    letter-spacing: 0.26px;
  }
}

.faqs-got-questions {
  padding: 0 24px;
  margin-top: 46px;
  margin-bottom: 97px;

  @include tablet {
    padding: 0;
  }

  @include desktop {
    margin-top: 110px;
    margin-bottom: 0;
  }
}