@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.feature-bar {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 79px;
  margin-bottom: 34.66px;

  border-top: 1px solid $black;
  border-bottom: 1px solid $black;

  @include desktop {
    height: 133px;
  }
}
.feature-bar-section {
  display: flex;
  flex-direction: column;

  padding: 19.66px 0 17px 20px;

  width: 33%;
  height: 100%;

  @include desktop {
    padding: 50px 0 16.5px 38px;
  }

  &__icon {
    margin-bottom: 6px;
    height: 19px;

    @include desktop {
      margin-bottom: 9px;
      height: 31px;
    }

    &--1 {
    }

    &--2 {
      width: 19px;
      @include desktop {
        width: 31px;
      }
    }

    &--3 {
    }

    &--4 {
      width: 43px;
      // height: 19px;

      @include desktop {
        width: 72px;
        // height: 31px;
      }
    }

    &--200 {
    }

    &--240 {
    }

    &--290 {
    }

    &--330 {
      width: 44px;
      height: 19px;

      @include desktop {
        width: 74px;
        height: 31px;
      }
    }

    &--one {
    }

    &--two {
      width: 44px;
      height: 19px;

      @include desktop {
        width: 73px;
        height: 31px;
      }
    }
  }

  &__text {
    font-family: $font-gt-walsheimregular;
    font-size: 0.75rem;
    line-height: 0.99rem;
    letter-spacing: 0.0025rem;

    @include desktop {
      font-size: 1.5rem;
      line-height: 1.65rem;
      letter-spacing: 0.005625rem;
    }
  }

  &--border {
    border-right: 1px solid $black;
  }
}
