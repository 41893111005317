@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.join-mailing-list {
    display: flex;
    flex-direction: column;
    align-items: center;


    &__title {
        color: $black;
        font-family: $font-gt-walsheimregular;
        width: 80%;
        max-width: 603px;
        text-align: center;
        margin-bottom: 16px;
        line-height: 2.5rem;

        @include desktop {
            max-width: 800px;
            line-height: 4.375rem;
            margin-bottom: 17px;
        }
    }

    &__body {
        color: $black;
        font-family: $font-gt-walsheimregular;
        text-align: center;
        max-width: 24.875rem;
        margin: 0 16px 33px;
        line-height: 1.375rem;

    }
}

.input-button-spacing {
    margin-bottom: 33px;
}

.join-mailing-list-enter-email {
    width: 100%;
    max-width: 670px;

    &__button {
        display: flex;
        justify-content: flex-end;

        &--disabled {
            background-color: $accent-light;
        }
    }
}

.mailchimp-error {
    width: 100%;
    max-width: 412px;
}