@use '../../../partials/mixins' as *;
@use '../../../partials/variables' as *;
@use '../../../partials/typography' as *;

.booking-complete-scaffold {
  width: 100%;
  height: 548px;

  @include desktop {
    min-height: 834px;
    height: 834px;
  }
}

.booking-checkout-complete-animation-container {
  position: absolute;
  top: 88px;
  width: 100%;
  min-height: 548px;

  @include desktop {
    top: 91px;
    min-height: 834px;
  }
}

.booking-checkout-complete-header {
  margin-top: 25px;

  @include tablet {
    padding-right: 24px;
  }

  @include desktop {
    padding-right: 0px;
  }
  
  &__title {
    margin-bottom: 15px;
    color: $black;
    font-family: $font-gt-sectra-displayregular;

    font-size: 30px;
    line-height: 34px;
    letter-spacing: 0.11px;

    @include desktop {
      font-size: 40px;
      line-height: 40px;
      letter-spacing: 0.15px;
    }

  }

  &__paragraph {
    color: $black;
    font-family: $font-gt-walsheimregular;

    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.035px;

    @include desktop {
      font-size: 20px;
      line-height: 27px;
      letter-spacing: 0.07px;
      max-width: 514px; //keep this or no?
    }
  }
}

.booking-checkout-complete-summary {
  margin-top: 58px;

  &__title-bold {
    margin-bottom: 16px;

    color: $black;
    font-family: $font-gt-walsheimbold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }

  &__text {
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04px;

    @include desktop {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.06px;
    }
  }
}

.booking-checkout-complete-room-date {
  margin-top: 21px;
  margin-bottom: 25px;
  
  &__text {
    // margin-bottom: 2px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04px;

    @include desktop {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.06px;
    }

  }
}