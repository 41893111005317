@use '../../../partials/mixins' as *;
@use '../../../partials/variables' as *;
@use '../../../partials/typography' as *;


.info-image-card {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  background-color: $wildSand;
  
  &--has-title {
    .info-image-card__content { 
      padding-top: 145px;
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  &__image {
    width: 100%;
    height: 563px;
    object-fit: cover;
    
    &.desktop-only {
      display: none !important;
    }
  }

  .text-link-icon {
    position: absolute;
    left: 30px;
    bottom: 60px;
  }

  &__content {
    position: relative;
    padding-top: 44px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__info {}

  &__title {
    position: absolute;
    top: -51px;
    left: 0;
    right: 0;

    margin: 0 auto;
    width: 100%;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 90px;
    line-height: 80px;
    letter-spacing: 0.33px;
    text-align: center;   
  }

  &__name {
    margin: 0px;

    color: $black;
    font-family: $font-gt-walsheimbold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.07px;
  }
  
  &__sub-name {
    margin-top: 6px;
    margin-bottom: 0px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.04px;
  }
  
  &__paragraph {
    margin-top: 32px;
    margin-bottom: 0px;
    max-width: 486px;
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.06px;
  }
  
  .button {
    margin-top: 60px;
  }

  @include tablet() {
    &--has-title {
      .info-image-card__content { 
        padding-top: 95px;
      }
    }
  }

  @include desktop() {
    flex-direction: row-reverse;
    height: 416px;

    &--has-title {
      .info-image-card__content { 
        padding-top: 44px;
      }
    }
    
    &__image-container {
      // width: 45%;
      width: 49%;
    }

    &__image {
      // width: 40%;
      // width: 50%;
      height: 416px;

      &.mobile-only {
        display: none !important;
      }

      &.desktop-only {
        display: block !important;
      }
    }

    .text-link-icon {
      display: none;
    }

    &__content {
      width: 55%;
    }

    &__title {
      display: none;
    }

    .button {
      margin-top: 0px;
    }
  }
}