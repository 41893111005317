@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.room-hero-section {
}

.room-hero-container {
  position: relative;

  width: 100%;
  height: 114.615385vw;
  max-height: 530px;

  &__image-gradient-mask {
    position: absolute;
    background: linear-gradient(transparent, rgb(255, 255, 255, 0.15));
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__image {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: 45% 0%;

    @include desktop {
      object-position: 50% 50%;
    }
  }

  &__title {
    position: absolute;
    bottom: -1.25rem;

    width: 100vw;

    font-size: 3.28125rem !important;
    line-height: 1;
    letter-spacing: 0.011875rem;
    font-family: $font-gt-walsheimregular;

    text-align: center;

    @include tablet {
      bottom: -2.25rem;

      font-size: 6.015625rem !important;
      letter-spacing: 0.0221875rem;
    }

    @include desktop {
      bottom: -3.3125rem;
      font-size: 8.75rem !important;
      letter-spacing: 0.0325rem;
    }

    &--change-position {
      position: fixed;
      left: 0;
      right: 0;
      width: 100vw;
      text-align: center;
      top: 26%;
      font-size: 3.28125rem !important;
      line-height: 1;
      letter-spacing: 0.011875rem;
      font-family: $font-gt-walsheimregular;

      @include tablet {
        top: 29%;
        font-size: 6.015625rem !important;
        line-height: 1;
        letter-spacing: 0.0221875rem;
      }

      @include desktop {
        top: 19%;
        font-size: 8.75rem !important;
        line-height: 1;
        letter-spacing: 0.0325rem;
      }
    }
  }
  &__description {
    width: 100%;
    font-size: 1rem;
    letter-spacing: 0.011875rem;
    font-family: $font-gt-walsheimregular;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.75rem 1.5rem 1.5rem;

    @include tablet {
      font-size: 1.25rem;
      padding: 3.75rem 5.4375rem 1.5rem;
    }
    @include desktop {
      font-size: 1.5rem;
      letter-spacing: 0.011875rem;
      font-family: $font-gt-walsheimregular;
      text-align: center;
      padding: 7.0625rem 9.375rem 3.75rem;
    }
  }
}
