@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.annex-image-text-column-info {
  display: flex;
  flex-wrap: wrap;

  &__info {
    padding-top: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image-container {
    width: 100%;
  }

  &__paragraph {
    margin-top: 0;
    margin-bottom: 30px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    letter-spacing: 0.07px;
    line-height: 22px;

    @include desktop {
      font-size: 20px;
      letter-spacing: 0.07px;
      line-height: 25px;
    }
  }

  &__name {
    margin-top: 0;
    margin-bottom: 9px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 30px;
    line-height: 70px;
    letter-spacing: 0.15px;
  }

  &__image {
    height: 447px;
    width: 100%;
    object-fit: cover;
  }

  @include desktop {
    flex-direction: row;
    flex-wrap: nowrap;

    &--image-position-left {
      flex-direction: row-reverse;

      .annex-image-text-column-info__info {
        padding-right: 0;
      }
    }

    &--image-position-right {
      .annex-image-text-column-info__info {
        padding-left: 0;
      }
    }

    &__image-container,
    &__info {
      width: 50%;
    }

    &__image {
      height: 100%;
    }

    &__info {
      padding: 125px 64px;
    }

    &__name {
      margin-bottom: 20px;
      font-size: 40px;
    }
  }
}
