@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.careers-container {
  padding: 0 24px 32px;
  margin: auto;

  @include desktop {
    padding-left: 36px;
    padding-right: 36px;
  }

  &__people {
    margin-top: 100px;
    margin-bottom: 70px;

    @include desktop {
      margin-top: 247px;
    }
  }

  &__culture {
  }

  &__vacancies-container {
    width: 100%;

    .annex-current-vacancies {
      &:last-child {
        border-bottom: 1px solid $black;
      }
    }
  }

  &__vacancies-Title {
    padding-bottom: 53px;
    display: flex;
    justify-content: center;

    font-family: $font-gt-walsheimregular;
    font-size: 40px;
    letter-spacing: 0.15px;
    line-height: 46px;

    border-bottom: 1px solid $black;
  }

  &__TwoColumnImage {
    margin-top: 117px;
    margin-bottom: 117px;

    @include desktop {
      margin-top: 70px;
      padding-bottom: 86px;
    }
  }
}

.careers-got-questions {
  padding: 0 24px;
  margin-top: 46px;
  margin-bottom: 97px;

  @include tablet {
    padding: 0;
  }

  @include desktop {
    margin-top: 101px;
    margin-bottom: 0;
  }
}
