@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.booking-item {
  &__image-container {}

  &__image {
    width: 100%;
    height: 100%;
  }

  &__info {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  &__name {
    margin-top: 0;
    margin-bottom: 0;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 40px;
    line-height: 45px;
    letter-spacing: 0.15px;
  }

  &__date {
    margin-top: 17px;
    margin-bottom: 0;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.06px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
      align-items: baseline;
    }

    &--details {
      margin-bottom: 20px;

      @include tablet {
        margin-bottom: 0px;
        margin-right: 20px;
      }

    }
  }

  //.text-link-icon {
  //  margin-top: 40px;
  //}
  .button-arrow-link {
    margin-top: 40px;

    .button-arrow {
      transition: all 600ms ease;

      &__icon {
        transition: all 600ms ease;
      }
    }
  }


  @include desktop() {
    &__info {
      padding-top: 45px;
      padding-left: 44.5px;
      padding-right: 45.5px;
      padding-bottom: 45px;
    }
  }
}