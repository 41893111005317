@use "../../../partials/mixins" as *;
@use "../../../partials/variables" as *;
@use "../../../partials/typography" as *;

.info-image-group-carousel-container {
  margin-bottom: 83px;

  @include desktop {
    margin-bottom: 147px;
  }

  &--experience {
    margin-bottom: 69px;
    @include desktop {
      margin-bottom: 72px;
    }
  }
}
.info-image-group-carousel-title {
  font-family: $font-gt-walsheimregular;
  // line-height: 4.375rem;
  line-height: 1.875rem;
  margin-top: 20px;

  // margin-bottom: 49px;
  margin-bottom: 69px;
  // padding-left: 52.5px;
  padding-left: 24px;

  @include desktop {
    padding-left: 78.5px;
    margin-bottom: 72px;
    margin-top: 0;
  }
}
.button-next-iigc {
  position: absolute;
  top: -34px;
  right: 24px;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  @include desktop {
    right: 36px;
    top: -52px;
  }
}

.button-next-icon-iigc {
  position: relative;
  width: 100%;
}

.button-prev-iigc {
  position: absolute;
  top: -34px;
  right: 24px;
  height: 15px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include desktop {
    right: 36px;
    top: -52px;
  }
}

.button-prev-icon-iigc {
  width: 100%;
}
