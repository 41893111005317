@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.faqs-accordion {
  padding-top: 21px;
  // padding-left: 52px;
  // padding-right: 52px;
  padding-bottom: 21px;
  border-top: 1px solid $black;

  @include tablet {
    padding: 21px 24px;
  }

  @include desktop {
    padding: 21px 52px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    background-color: transparent;
    cursor: pointer;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    width: 90%;
    text-align: left;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;

    @include desktop {
      width: 100%;

      font-size: 40px;
      line-height: 70px;
      letter-spacing: 0.15px;
    }
  }

  &__icon {
    color: $black;
  }

  &__content {
    overflow: hidden;

    transition: max-height 0.5s ease;

    &--active {
      margin-top: 21px;
      margin-bottom: 17px;
    }
  }

  &__paragraph {
    width: 95%;
    margin-top: 21px;
    margin-bottom: 17px;

    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.07px;

    @include desktop {
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 0.07px;
    }
  }

  &__link {
    color: $accent-dark;
    text-decoration: underline;
    cursor: pointer;
  }
}
