@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

// .test {
//     display: flex;
// }
.experience-container {
  margin-bottom: 26px;

  @include desktop {
    margin-bottom: 129px;
  }
}
.experience-section-title {
  //   font-family: $font-gt-walsheimregular;
  //   line-height: 4.375rem;
  //   margin-bottom: 24px;
  //   padding-left: 24px;

  //   @include desktop {
  //     padding-left: 78.5px;
  //   }
  font-family: $font-gt-walsheimregular;
  line-height: 1.875rem;
  margin-top: 20px;
  margin-bottom: 69px;
  padding-left: 24px;

  @include desktop {
    padding-left: 78.5px;
    margin-bottom: 72px;
    margin-top: 0;
  }
}

.live-local-two-column-image {
  padding-left: 24px;
  //   margin-bottom: 55px;
  margin-bottom: 69px;

  @include desktop {
    padding-left: 36px;
    padding-right: 36px;
    // margin-bottom: 60px;
    margin-bottom: 12px;
  }
}
