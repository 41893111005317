@use './variables' as *;

@mixin tablet {
    @media (min-width: $tablet-breakpoint){
        @content;
    }
}
@mixin desktop {
    @media (min-width: $desktop-breakpoint){
        @content;
    }
}

//copied from bootstrap files
@mixin clearfix() {
    &::after {
      display: block;
      clear: both;
      content: "";
    }
  }
  // stylelint-disable property-disallowed-list
@mixin transition($transition...) {
    @if length($transition) == 0 {
      $transition: $transition-base;
    }
  
    @if length($transition) > 1 {
      @each $value in $transition {
        @if $value == null or $value == none {
          @warn "The keyword 'none' or 'null' must be used as a single argument.";
        }
      }
    }
  
    @if $enable-transitions {
      @if nth($transition, 1) != null {
        transition: $transition;
      }
  
      @if $enable-prefers-reduced-motion-media-query and nth($transition, 1) != null and nth($transition, 1) != none {
        @media (prefers-reduced-motion: reduce) {
          transition: none;
        }
      }
    }
  }
  @mixin hover() {
    &:hover { @content; }
  }
  
  @mixin hover-focus() {
    &:hover,
    &:focus {
      @content;
    }
  }
  
  @mixin plain-hover-focus() {
    &,
    &:hover,
    &:focus {
      @content;
    }
  }
  
  @mixin hover-focus-active() {
    &:hover,
    &:focus,
    &:active {
      @content;
    }
  }
  
