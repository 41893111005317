@use '../../partials/mixins' as *;
@use '../../partials/variables' as *;
@use '../../partials/typography' as *;

.annex-auth-side-menu-mobile-list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;

  border: 1px solid $black;
  border-bottom: none;

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 27px 38px;
    width: 100%;
    
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.01px;
    text-align: left;

    border-bottom: 1px solid $black;
    background-color: rgba(255, 255, 255, 0.9);
    cursor: pointer;

    transition: all 300ms ease;

    &:focus {
      outline: none !important;
    }

    .annex-icon {
      transform: rotate(0deg);
      transition: all 300ms ease;
    }

    &--active {
      .annex-icon {
        transform: rotate(180deg);
      }
    }

    &:hover {
      color: $white;
      background-color: rgba($form-green, 0.9);
    }
  }

  &__list {
    // height: 0px;
    max-height: 0;
    overflow: hidden;
    transition: all 300ms ease;

    &--acitve {
      // height: 397px;
      max-height: 397px;
      transition: all 300ms ease;
    }
  }

  @include desktop() {
    display: none;
  }
}
