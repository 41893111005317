@import "../../partials/mixins";
@import "../../partials/variables";

.event-new {
  position: relative;
  width: 100%;

  &__image-container {
    position: relative;
  }

  &__image {
    width: 100%;
    height: 493px;
    object-fit: cover;

    @include desktop {
      height: 778px;
    }
  }

  &__content-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: -28px;
    padding-left: 26px;
    padding-right: 26px;
    padding-bottom: 37px;

    min-height: 260px;

    @include tablet {
      min-height: 230px;
    }

    @include desktop {
      min-height: 300px;
      margin-top: -35px;
    }
  }

  &__title {
    z-index: 1;
    margin-top: 0;
    margin-bottom: 20px;

    color: $black;
    font-family: $font-gt-sectra-displayregular;
    font-size: 42.7px;
    letter-spacing: 0.16px;
    line-height: 42.7px;

    @include desktop {
      font-size: 70px;
      letter-spacing: 0.26px;
      line-height: 70px;
    }
  }

  &__date-container {
    margin-top: 0;
    margin-bottom: 18px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  }

  &__date-time-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    margin-right: 50px;
  }

  &__date,
  &__time,
  &__budget {
    color: $accent-dark;
    font-family: $font-gt-walsheimregular;
    font-size: 12.2px;
    letter-spacing: 0.05px;
    line-height: 13.42px;

    @include desktop {
      font-size: 20px;
      letter-spacing: 0.07px;
      line-height: 22px;
    }
  }

  &__desc {
    color: $black;
    font-family: $font-gt-walsheimregular;
    font-size: 12.2px;
    letter-spacing: 0.05px;
    line-height: 13.42px;

    @include desktop {
      font-size: 20px;
      letter-spacing: 0.07px;
      line-height: 22px;
    }
  }
}
.events-carousel-mask {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(transparent, rgb(255, 255, 255, 0.15));
  // background-color:  rgb(255, 255, 255, 0.15);
}

.events-text-us-container {
  padding: 37px 0px 0px;
}
