@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/typography" as *;

.book-calendar-guests {
  position: absolute;
  z-index: 2;
  left: 20px;

  background-color: $white;
  box-shadow: 0 2px 11px rgba(210, 210, 210, 0.5);

  @include desktop {
    left: 38px;
    top: -20px;
  }
}
.book-calendar-guests-icon-container {
  display: flex;
  align-items: center;
}
.book-calendar-icon {
  width: 25px;
  height: 21px;

  margin-right: 15px;

  cursor: pointer;

  @include tablet {
    margin-right: 31px;
  }
}
.book-guests-icon {
  display: flex;
  // width: 25px;
  height: 21px;

  cursor: pointer;

  &__icon {
    width: auto;
    height: 21px;
    margin-right: 3px;
  }
}
.book-close-icon {
  width: 17.3px;
  height: 17.3px;
}
.go-button {
  padding: 0px;
  font-family: $font-gt-walsheimregular;
  font-size: 18px;
  border-color: $black;
  color: $black;
  height: 45px;
  width: 45px;

  @include tablet {
    height: 54px;
    width: 54px;
  }

  &:hover {
    background-color: $form-green;
    border-color: $form-green;
  }
}
.loader-container {
  padding-top: 3px;
}
.book-calendar-guests-accordion {
  position: relative;
  display: flex;
  flex-direction: column;

  min-width: 272px;
  max-width: 372px;
  height: fit-content;
  // margin-bottom: 10px;

  background-color: transparent;

  &:focus-within {
    border: 2px solid $white;
  }
}
.book-calendar-guests-accordion-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 88px;
  // max-width: 350px; //is this right?
  padding: 15px 5.5%;

  // border: 1px solid $white;
  border-bottom: none;

  overflow: hidden;
  // visibility: hidden;
  // opacity: 0;
  transition: all 0.5s ease;

  @include tablet {
    padding: 15px 27px;
  }

  @include desktop {
    // max-height: 1000px;
    // padding: 12px 18px 16px 16.5px;

    // visibility: visible;
    overflow: visible;
    // opacity: 1;
  }

  &--active {
    max-height: 1000px;
    // padding: 12px 18px 16px 16.5px;

    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease;
  }

  &:focus {
    outline: none;
  }
}
.event-accordion-header-icon {
  display: flex;
  align-items: center;

  height: 100%;

  &__icon {
    width: 15px;
    height: 9px;

    color: $white;

    transition: transform 0.5s ease;
  }
}
